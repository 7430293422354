/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { withRouter } from 'react-router-dom';
import { Table } from 'react-bootstrap'
import NavigationConfirmation from '../NavigationConfirmation';
import $ from 'jquery';
import backArrowIcon from '../../images/icon-back-arrow.png';
import downloadIcon from '../../images/icon-download.png';
import downloadBrownIcon from '../../images/icon-download-brown.png';
import downloadGreenIcon from '../../images/icon-download-green.png';
import MetricImpactedDetail from './MetricImpactedDetail';
import MaturityElementDetail from './MaturityElementDetail';
import ReferenceDocumentsDetail from './ReferenceDocumentsDetail';
import request from '../../services/Service';
import i18n from 'i18next';
import langKeys from '../../Utils/LangKeys'
import { withTranslation } from 'react-i18next';
import { AgGridLocale } from '../../Utils/AgGridLocale';
import { setDropdownValues } from '../../redux/actions/action';
import { inputValidator } from '../../Utils/InputElementValidation';
var editActive = false;

function CreateCheckbox() {
    try {
        this.eGui = document.createElement("div");
        this.eGui.width = "100%";
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

CreateCheckbox.prototype.init = function (params) {
    try {
        var checkboxElement = document.createElement("input");
        checkboxElement.type = "checkbox";
        checkboxElement.className = "findColumns";

        checkboxElement.checked = params.data.IsSelected;
        checkboxElement.addEventListener('change', function (event) {
            editActive = true;
            params.api.gridOptionsWrapper.gridOptions.rowData.map((eachRow, index) => {
                if (index == params.rowIndex) {
                    eachRow["IsSelected"] = event.currentTarget.checked;
                    if (event.currentTarget.checked == false) {
                        eachRow["AsIsPMMScore"] = 0;
                        eachRow["TobePMMScore"] = 0;
                    }
                    else {
                        eachRow["TobePMMScore"] = 4;
                    }
                }
            })
            params.api.gridOptionsWrapper.gridOptions.api.setRowData(params.api.gridOptionsWrapper.gridOptions.rowData);
        });

        this.eGui.appendChild(checkboxElement);
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

CreateCheckbox.prototype.getGui = function getGui() {
    return this.eGui;
}

CreateCheckbox.prototype.refresh = function (params) {
    this.eGui.textContent = '';
    this.init(params)
}

CreateCheckbox.prototype.onChange = function (params) {

};

function createCircelText() {
    try {
        this.eGui = document.createElement('div');
        this.eGui.className = "row";
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

createCircelText.prototype.init = function (params) {
    try {
        let enterpriseId = params.colDef.cellRendererParams.enterpriseId
        let circle = document.createElement('div')
        circle.className = "circleDiv";
        circle.title = i18n.t("Pleaseclicktoeditcellvalues");
        switch (Number(params.value)) {
            case 1:
                circle.style.backgroundColor = "#d01a1a"
                break
            case 2:
                circle.style.backgroundColor = "#cd7825"
                break
            case 3:
                circle.style.backgroundColor = "#cd7825"
                break
            case 4:
                circle.style.backgroundColor = "#80bc59"
                break
        }
        let spanValue = document.createElement('span');
        spanValue.className = "AsIsToBeLabel";
        params.colDef.headerName == i18n.t('toBePMMScore') && params.value == 0 && params.data.IsSelected == 1 ? spanValue.textContent = "4" : spanValue.textContent = params.value;
        if(params.colDef.headerName == i18n.t('toBePMMScore') && params.value == 0 && params.data.IsSelected == 1){
            circle.style.backgroundColor = "#80bc59";
        }                
        //If as is or to be score is -1 , then display NA in the grid    
        if(params.value === -1){
            spanValue.textContent = "NA";
        }
        else{
            spanValue.textContent = params.value;
        }
        let leverTypeSum = 0;
        let leverTypeCount = 0;
        let standardSum = 0;
        let standardCount = 0;
        let rwData = params.agGridReact.gridOptions.rowData;
        rwData.map((eachdata, index) => {
            if (eachdata.LeverName == params.data.LeverName) {
                if (eachdata.IsSelected == 1) {
                    leverTypeSum += Number(eachdata[params.colDef.field]), leverTypeCount += 1
                }
            }
            if (eachdata.IsStandardization == 1) {
                standardSum += Number(eachdata[params.colDef.field] === -1 || eachdata[params.colDef.field] === "NA" ? 0 : eachdata[params.colDef.field]),
                    standardCount += Number(eachdata[params.colDef.field] === -1 || eachdata[params.colDef.field] === "NA" ? 0 : 1)
            }
        })


        let standardAvg = (standardSum / standardCount).toFixed(2);
        params.data.loggedInUserId = enterpriseId;
        if (params.colDef.headerName == i18n.t('asIsPMMScore')) {

            /*if (params.data.LeverName.trim() == i18n.t('policy')) {
            }
            else if (params.data.LeverName.trim() == i18n.t('practice')) {
            }
            else {
            } */
            document.getElementById("idStandardAsIsAvg").textContent = isNaN(standardAvg) == true ? i18n.t('na') : standardAvg;
        }
        else {

            /*if (params.data.LeverName.trim() == i18n.t('policy')) {

            }
            else if (params.data.LeverName.trim() == i18n.t('practice')) {
            }
            else {
            } */
            document.getElementById("idStandardToBeAvg").textContent = isNaN(standardAvg) == true ? i18n.t('na') : standardAvg;
        }
        this.eGui.appendChild(circle);
        this.eGui.appendChild(spanValue);
    }
    catch (error) {
        console.log("Error Encountered");
    }
}
createCircelText.prototype.getGui = function (params) {
    try {
        return this.eGui;
    }
    catch (error) {
        console.log("Error Encountered");
    }
}
function CreateTextbox() {
    try {
        this.eGui = document.createElement("div");
        this.eGui.style.height = "100%";
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

CreateTextbox.prototype.init = function (params) {
    try {
        var textboxElement = document.createElement("input");
        textboxElement.type = "textbox";
        textboxElement.style.width = "100%"
        textboxElement.style.height = "100%"
        if(params.colDef.headerName == i18n.t('displayName')){
            textboxElement.value = params.data.DisplayName;
        }
        else{
            textboxElement.value = "";
        }
        if(params.colDef.headerName == i18n.t('As-Is')){
            textboxElement.value = params.data.AsIs;
        }
        else{
            textboxElement.value = "";
        }
        if(params.colDef.headerName == i18n.t('To-Be')){
            textboxElement.value = params.data.ToBe;
        }
        else{
            textboxElement.value = "";
        }
        if(params.colDef.headerName == i18n.t('boiImpacted')){
            textboxElement.value = params.data.BOIImpacted;
        }
        else{
            textboxElement.value = "";
        }

        this.eGui.appendChild(textboxElement);
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

CreateTextbox.prototype.getGui = function getGui() {
    try {
        return this.eGui;
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

CreateTextbox.prototype.refresh = function (params) {
    try {
        this.eGui.textContent = '';
        this.init(params);
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

function CreateDropdown() {

}

CreateDropdown.prototype.init = function (params) {
    try {

        var circleDiv = document.createElement("div");
        var dropDownElement = document.createElement("select");
        dropDownElement.id = params.rowIndex;
        circleDiv.id = "circle" + params.colDef.headerName + params.rowIndex;
        dropDownElement.className = "col-md-2";
        if (params.colDef.headerName == i18n.t('asIsPMMScore') || params.colDef.headerName == i18n.t('toBePMMScore')) {

            circleDiv.className = "col-md-1 circleDiv"
            let asIsValue = [1, 2, 3, 4, "NA"]
            if (params.data.Base == "" || params.data.Base == null) {
                asIsValue.splice(0, 1);
            }
            if (params.data.Enhanced == "" || params.data.Enhanced == null) {
                asIsValue.splice(1, 1);
            }
            if (params.data.AboveAverage == "" || params.data.AboveAverage == null) {
                asIsValue.splice(2, 1);
            }
            if (params.data.HighPerformance == "" || params.data.HighPerformance == null) {
                asIsValue.splice(3, 1);
            }

            return { values: asIsValue }

        }
        else {

            if (params.colDef.headerName == i18n.t('lever')) {
                params.data.LeverTypes.map((ddValues, index) => { return dropDownElement.options[index] = new Option(ddValues.LeverName, ddValues.LeverID) });
            }
            else if (params.colDef.headerName == i18n.t('kpa')) {
                params.data.KPATypes.map((ddValues, index) => { return dropDownElement.options[index] = new Option(ddValues.KPA, ddValues.KPAID) });
            }
            else {
                params.data.MetricsTypes.map((ddValues, index) => { return dropDownElement.options[index] = new Option(ddValues.MetricName, ddValues.MetricID) });
            }
        }

        dropDownElement.addEventListener('change', function (event) {
            switch (event.currentTarget.selectedOptions[0].text) {
                case "1":
                    document.getElementById("circle" + params.colDef.headerName + params.rowIndex).style.backgroundColor = "#d01a1a"
                    break;
                case "2":
                    document.getElementById("circle" + params.colDef.headerName + params.rowIndex).style.backgroundColor = "#cd7825"
                    break;
                case "3":
                    document.getElementById("circle" + params.colDef.headerName + params.rowIndex).style.backgroundColor = "#cd7825"
                    break;
                case '4':
                    document.getElementById("circle" + params.colDef.headerName + params.rowIndex).style.backgroundColor = "#80bc59"
                    break;
            }
            params.agGridReact.gridOptions.rowData.map((eachRow, index) => { if (index == params.rowIndex) { eachRow[params.colDef.field] = event.currentTarget.selectedOptions[0].text } })
            let leverTypeSum = 0;
            let leverTypeCount = 0;
            let standardSum = 0;
            let standardCount = 0;

            params.agGridReact.gridOptions.rowData.map((eachdata, index) => {
                if (eachdata.LeverName == params.data.LeverName) {
                    leverTypeSum += Number(eachdata[params.colDef.field]), leverTypeCount += 1
                }
                if (eachdata.IsStandardization == 1) {
                    standardSum += Number(eachdata[params.colDef.field]), standardCount += 1
                }
            })

        });
        this.eGui.appendChild(dropDownElement);
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

CreateDropdown.prototype.getGui = function getGui() {
    try {
        return this.eGui;
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

CreateDropdown.prototype.refresh = function (params) {
    try {
        this.eGui.textContent = '';
        this.init(params);
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

class PMMAssessment extends React.Component {
    constructor(props) {

        super(props);
        this.state = {
            isEditAcitve: false,
            popUpDisplay: false,
            selectedMetric: "",
            popUpMetricImpacted: false,
            popUpMaturityElement: false,
            toURL: '',
            navigationPage: "",
            dynamicLeverData: [],
            PMMAssessmentData: [], quickFilterText: null,
            lstLever: [], LeverValue: "", PMMAssessmentGridData: [], ToBePMM: 4.00, AsIsPMM: 4.00
            , leverType: "all", policyAvg: 0, practiceAvg: 0, platformAvg: 0,
            SCID: this.props.configDetails.ServiceComponent.value,
            popUpReferenceDocuments: false
        };
        this.SavePMMAssessment = this.SavePMMAssessment.bind(this);
        this.onOverallEdit = this.onOverallEdit.bind(this);
        this.onNavigationClick = this.onNavigationClick.bind(this);
        this.closeNavigationPopUp = this.closeNavigationPopUp.bind(this);
        this.reSizeGrid = this.reSizeGrid.bind(this);
        this.DownloadExcel = this.DownloadExcel.bind(this);
        this.DownloadReferenceContentExcel = this.DownloadReferenceContentExcel.bind(this);
        this.unloadEvent = this.unloadEvent.bind(this);
        this.AutoScoreCalculation = this.AutoScoreCalculation.bind(this);
        this.closeMetricImpactedPopUp = this.closeMetricImpactedPopUp.bind(this);
        this.closeMaturityElementPopUp = this.closeMaturityElementPopUp.bind(this);
        this.closeReferenceDocumentsPopUp = this.closeReferenceDocumentsPopUp.bind(this);
        this.showDownloadReferencePopup = this.showDownloadReferencePopup.bind(this);
        this.dynamicLeverDataCalculation = this.dynamicLeverDataCalculation.bind(this);
    }

    dynamicLeverDataCalculation = (response) => {
        try {
            var objThis = this;
            var leverWithValue = [];
            var sumForOverAllAsIs = 0;
            var sumForOverAllToBe = 0;
            var asisAvgcount=0;
            var tobeAvgcount=0;
            let standardizationSum = 0, standardizationCount = 0;
            let standardizationToBeSum = 0, standardizationToBeCount = 0;
            var pmmData = response;
            var distinctLevers = [...new Set(pmmData.map(x => x.LeverName))]
            
            for (var i = 0; i < distinctLevers.length; i++) {
                var data = pmmData.filter(x => x.LeverName == distinctLevers[i])
                var asisSum = 0;
                var tobeSum = 0;
                var asisCount = 0;
                var tobeCount = 0;

                data.map((eachdata) => {

                    asisSum += Number(eachdata.AsIsPMMScore === -1 ? 0 : eachdata.AsIsPMMScore),
                        asisCount += Number(eachdata.AsIsPMMScore === -1 ? 0 : 1),
                        tobeSum += Number(eachdata.TobePMMScore === -1 ? 0 : eachdata.TobePMMScore),
                        tobeCount += Number(eachdata.TobePMMScore === -1 ? 0 : 1)

                    if (eachdata.IsStandardization == true) {

                        standardizationSum += Number(eachdata.AsIsPMMScore === -1 ? 0 : eachdata.AsIsPMMScore),
                            standardizationCount += Number(eachdata.AsIsPMMScore === -1 ? 0 : 1)
                        standardizationToBeSum += Number(eachdata.TobePMMScore === -1 ? 0 : eachdata.TobePMMScore),
                            standardizationToBeCount += Number(eachdata.TobePMMScore === -1 ? 0 : 1)
                    }
                })
                leverWithValue.push(
                    {
                        'LeverName': distinctLevers[i],
                        'AsIsSum': asisSum,
                        'ToBeSum': tobeSum,
                        'AsIsAvg': (asisSum / asisCount).toFixed(2),
                        'ToBeAvg': (tobeSum / tobeCount).toFixed(2)
                    }
                )
                asisAvgcount += (asisCount === 0) ? 0 : (asisCount);
                tobeAvgcount += (tobeCount === 0) ? 0 : (tobeCount);
                sumForOverAllAsIs +=  parseFloat(asisSum)
                sumForOverAllToBe +=  parseFloat(tobeSum)

            }
            let standardAvg = (standardizationSum / standardizationCount).toFixed(2);
            let standardToBeAvg = (standardizationToBeSum / standardizationToBeCount).toFixed(2);

            // to find distinct levers which do not have AsIsAvg as Nan(do not have all values as NA)
            // let distinctCount = leverWithValue.filter(function (item) {
            //     return item.AsIsAvg !== "NaN"
            // }).length



            document.getElementById("AsIsPMMScore").textContent = Number(sumForOverAllAsIs / asisAvgcount).toFixed(2);
            document.getElementById("idStandardAsIsAvg").textContent = isNaN(standardAvg) == true ? i18n.t('na') : standardAvg;

            document.getElementById("ToBePMMScore").textContent = Number(sumForOverAllToBe / tobeAvgcount).toFixed(2);
            document.getElementById("idStandardToBeAvg").textContent = isNaN(standardToBeAvg) == true ? i18n.t('na') : standardToBeAvg;
            objThis.setState({ dynamicLeverData: leverWithValue })

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {
        try {            
            // if(!this.props.configDetails.DealName.label.toLowerCase().includes("test")){
            // window.tfo_dataevent("PMM-TPA-4", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1, this.props.configDetails.DealName.label)  
            // }
            var objThis = this;
            $("#loader-div").show();
            window.addEventListener("beforeunload", this.unloadEvent);
            window.addEventListener('resize', this.reSizeGrid, true);
            var SearchedString = "";
            let PMMDetailsData = {
                PMMProcessDraftID: this.props.configDetails.PMMProcessDraftID,
                IndustryID: this.props.configDetails.OGIndustry.IndustryId,
                SCID: this.props.configDetails.ServiceComponent.value,
                LeverID: 0,
                SearchedString: SearchedString,
                UserName: this.props.enterpriseId,
                GroupID: this.props.groupId,
                LanguageID: this.props.languageId
            }
            let referenceDocumentData = {
                SCID: this.state.SCID,
                MaturityElement: "ALL",
                GroupID: this.props.groupId,
                LanguageID: this.props.languageId
            }
            let dropdownData = {
                "ID": 0,
                "DealId": 0,
                "UserName": this.props.enterpriseId,
                "GroupID": this.props.groupId,
                "LanguageID": this.props.languageId
            }

            Promise.all([request("/Common/DropDownsValue", 'POST', dropdownData)
            ])

                .then((response) => {
                    response[0].lever.unshift({ value: 0, label: "ALL" })
                    this.props.setDropdownValues(response[0].lever)

                    objThis.setState({ lstLever: response[0].lever, LeverValue: { value: 0, label: i18n.t('all') }, });

                    $("#loader-div").hide();
                })
                .catch((error) => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillUnmount() {
        try {
            window.removeEventListener("beforeunload", this.unloadEvent);
            window.removeEventListener('resize', this.reSizeGrid, true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }


    closeMaturityElementPopUp() {
        try {
            this.setState({
                popUpMaturityElement: false
            });
            $("#loader-div").hide();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    unloadEvent(e) {
        try {
            let confirmationMessage = i18n.t("leavingthispagewillresultinlossunsaveddata");
            (e || window.event).returnValue = confirmationMessage; //Gecko + IE
            return confirmationMessage; //Webkit, Safari, Chrome etc.
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    reSizeGrid() {
        try {
            this.refs.grd_PMMAssessment.gridOptions.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectLever(val) {
        try {
            this.setState({ LeverValue: val, leverType: val.label.trim() });
            let grdData = this.state.PMMAssessmentData;
            let filteredData = grdData.filter(row => (row.LeverName == val.label));
            if(val.label == i18n.t('all')){
                filteredData = this.state.PMMAssessmentData;
            } else{
                return filteredData;
            }
            this.setState({ PMMAssessmentGridData: filteredData });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    AutoScoreCalculation() {
        try {
            let grdData = this.refs.grd_PMMAssessment.gridOptions.rowData;
            grdData.map((eachdata, index) => {
                if (eachdata.IsAutoCalculatePMMScore == true) {
                    let strBase = eachdata.Base;
                    let strEnhanced = eachdata.Enhanced == null ? "" : eachdata.Enhanced;
                    let strAboveAvg = eachdata.AboveAverage == null ? "" : eachdata.AboveAverage;
                    let strHighPerf = eachdata.HighPerformance;

                    let arrBase = strBase.match(/[^\s>=<()&&*/%+-]+/g);
                    let arrEnhanced = strEnhanced.match(/[^\s>=<()&&*/%+-]+/g);
                    let arrAboveAvg = strAboveAvg.match(/[^\s>=<()&&*/%+-]+/g);
                    let arrHighPerf = strHighPerf.match(/[^\s>=<()&&*/%+-]+/g);

                    let tempValBase = (strBase.replace(arrBase[0], eachdata.CurrentMetricValue)).replace(arrBase[2], eachdata.CurrentMetricValue);
                    let tempValEnhanced = "";
                    let tempValAboveAvg = "";
                    let tempValHighPerf = (strHighPerf.replace(arrHighPerf[0], eachdata.CurrentMetricValue)).replace(arrHighPerf[2], eachdata.CurrentMetricValue);

                    if (eval(tempValBase)) {
                        eachdata.AsIsPMMScore = 1;
                    }
                    if (strEnhanced != "") {
                        tempValEnhanced = (strEnhanced.replace(arrEnhanced[0], eachdata.CurrentMetricValue)).replace(arrEnhanced[2], eachdata.CurrentMetricValue);
                        if (eval(tempValEnhanced)) {
                            eachdata.AsIsPMMScore = 2;
                        }
                    }
                    if (strAboveAvg != "") {
                        tempValAboveAvg = (strAboveAvg.replace(arrAboveAvg[0], eachdata.CurrentMetricValue)).replace(arrAboveAvg[2], eachdata.CurrentMetricValue);
                        if (eval(tempValAboveAvg)) {
                            eachdata.AsIsPMMScore = 3;
                        }
                    }
                    if (eval(tempValHighPerf)) {
                        eachdata.AsIsPMMScore = 4;
                    }

                    tempValBase = (strBase.replace(arrBase[0], eachdata.FutureStateMetricValue)).replace(arrBase[2], eachdata.FutureStateMetricValue);
                    tempValHighPerf = (strHighPerf.replace(arrHighPerf[0], eachdata.FutureStateMetricValue)).replace(arrHighPerf[2], eachdata.FutureStateMetricValue);

                    if (eval(tempValBase)) {
                        eachdata.TobePMMScore = 1;
                    }
                    if (strEnhanced != "") {
                        tempValEnhanced = (strEnhanced.replace(arrEnhanced[0], eachdata.FutureStateMetricValue)).replace(arrEnhanced[2], eachdata.FutureStateMetricValue);
                        if (eval(tempValEnhanced)) {
                            eachdata.TobePMMScore = 2;
                        }
                    }
                    if (strAboveAvg != "") {
                        tempValAboveAvg = (strAboveAvg.replace(arrAboveAvg[0], eachdata.FutureStateMetricValue)).replace(arrAboveAvg[2], eachdata.FutureStateMetricValue);
                        if (eval(tempValAboveAvg)) {
                            eachdata.TobePMMScore = 3;
                        }
                    }
                    if (eval(tempValHighPerf)) {
                        eachdata.TobePMMScore = 4;
                    }

                }
            })
            this.setState({ PMMAssessmentGridData: grdData });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SavePMMAssessment(e) {
        try {
            var validRemark = true;
            var objThis = this;
            let AsIsToBeZeroStatus = false;
            let evtSrc = e.target.text.trim();
            this.refs.grd_PMMAssessment.gridOptions.api.stopEditing();
            let grdData = this.refs.grd_PMMAssessment.gridOptions.rowData;

            var referenceDocumentsArr = grdData.filter(function (itm) {
                return (itm.ContainsReferenceDocuments == true);
            });
            let asIsLessthanTobe = false;



            var deselectedItems = grdData.filter(function (itm) {
                return (itm.IsSelected == false);
            });

            var blankRemarkArr = deselectedItems.filter(function (itm) {
                return (itm.Remarks == "" || itm.Remarks == null);
            });
            if (blankRemarkArr.length > 0 && evtSrc != i18n.t('save')) {
                alert(i18n.t("PleaseenterRemarksunselectedlineitems"));
                return false;
            }

            grdData.filter(item => item.hasOwnProperty('AsIsPMMScore'))
                .map((item, i) => {
                    if (item.IsSelected == 1) {
                        var AsIsPMMScore = item.AsIsPMMScore;
                        var ToBePMMScore = item.TobePMMScore;
                        var Remarks = item.Remarks;
                        let RemarksValidation = inputValidator(Remarks);
                        if (RemarksValidation) {
                            validRemark = false;
                        }
                        if(ToBePMMScore < AsIsPMMScore){
                            asIsLessthanTobe = true;
                        }
                        if (item.AsIsPMMScore == 0 || ToBePMMScore == 0) {
                            AsIsToBeZeroStatus = true;
                        }
                    }
                    else {
                        item.AsIsPMMScore = 0;
                        item.TobePMMScore = 0;
                    }
                });
            if (asIsLessthanTobe == true) {
                alert(i18n.t("ToBePMMscoreAsISPMM"));
                return false;
            }


            if (AsIsToBeZeroStatus == true && evtSrc != i18n.t('save')) {
                alert(i18n.t("PleaseprovideAsIsandToBevaluesforalllineitems"));
                return false;
            }

            $("#loader-div").show();
            let ToBeOverallScore = document.getElementById("ToBePMMScore").innerHTML;
            let AsIsOverallScore = document.getElementById("AsIsPMMScore").innerHTML;
            if (ToBeOverallScore >= AsIsOverallScore) {
                var PMMDetails = {
                    "pmmDetails": grdData
                };

                this.setState({
                    isEditAcitve: false
                })

                var ValidStatusFormula = true;
                if (evtSrc != i18n.t('save')) {
                    grdData.filter(item => item.hasOwnProperty('IsAutoCalculatePMMScore'))
                        .map((item, i) => {
                            if (item.IsAutoCalculatePMMScore == true && (item.AutoScoreStatusAsIs == false || item.AutoScoreStatusToBe == false)) {
                                ValidStatusFormula = false;
                            }
                        });
                }

                if (ValidStatusFormula == false) {
                    alert(i18n.t("Pleasecorrectallhighlightedfieldsinredcolor"));
                    $("#loader-div").hide();
                    return false;
                }

                if (grdData.filter(obj => obj.AsIsPMMScore === -1 || obj.AsIsPMMScore === "NA").length === grdData.length) {
                    alert(i18n.t("pleaseSelectValueOtherThanNA"));
                    $("#loader-div").hide();
                    return false;
                }

                var data = JSON.stringify(PMMDetails);
                var SearchedString = "";
                let PMMDetailsdata = {
                    PMMProcessDraftID: this.props.configDetails.PMMProcessDraftID,
                    IndustryID: this.props.configDetails.OGIndustry.IndustryId,
                    SCID: this.props.configDetails.ServiceComponent.value,
                    LeverID: 0,
                    SearchedString: SearchedString,
                    UserName: this.props.enterpriseId,
                    GroupID: this.props.groupId,
                    LanguageID: this.props.languageId
                }

                //If AsIs or ToBe is NA then store -1 in the backend
                PMMDetails.pmmDetails.map(x => {
                    if (x.AsIsPMMScore === 'NA' || x.TobePMMScore === 'NA') {
                        x.AsIsPMMScore = -1,
                            x.TobePMMScore = -1
                    }
                });
                if (validRemark) {
                    request("/PMM/SavePMMDraftDetails?LanguageID=" + this.props.languageId, 'POST', PMMDetails)
                        .then(res => {
                            if (res.outputmessage == i18n.t('success')) {
                                alert(i18n.t("PMMAssessmentSavedSuccessfully"));
                                request("/PMM/GetPMMDetails", 'POST', PMMDetailsdata)
                                    .then(response => {

                                        var pmmData = response;
                                        pmmData.map((eachdata, index) => {
                                            var matchedReferenceDocumentPMM = referenceDocumentsArr.filter(item => item.PMMDraftID == eachdata.PMMDraftID && item.PMMProcessDraftID == eachdata.PMMProcessDraftID)
                                            if (matchedReferenceDocumentPMM !== undefined && matchedReferenceDocumentPMM.length > 0) {
                                                eachdata["ContainsReferenceDocuments"] = true;
                                            }
                                            else {
                                                eachdata["ContainsReferenceDocuments"] = false;
                                            }
                                        })

                                        objThis.setState({ PMMAssessmentGridData: response, PMMAssessmentData: response });
                                        $("#loader-div").hide();
                                    })
                                    .catch(error => {
                                        console.log("Error Encountered");
                                        $("#loader-div").hide();
                                    });
                                if (evtSrc != i18n.t('save')) {


                                    let graphStd = this.state.PMMAssessmentGridData.map((eachdata, index) => {
                                        return eachdata.IsStandardization
                                    });
                                    var yAsIsPMMScore, xAsIsStdScore, yToBePMMScore, xToBeStdScore;
                                    yAsIsPMMScore = document.getElementById("AsIsPMMScore").innerText;
                                    xAsIsStdScore = document.getElementById("idStandardAsIsAvg").innerText;
                                    yToBePMMScore = document.getElementById("ToBePMMScore").innerText;
                                    xToBeStdScore = document.getElementById("idStandardToBeAvg").innerText;
                                    let asTovalArr = [xAsIsStdScore, yAsIsPMMScore, xToBeStdScore, yToBePMMScore];
                                    if (yAsIsPMMScore != undefined && xAsIsStdScore != undefined && yToBePMMScore != undefined && xToBeStdScore != undefined) {
                                        sessionStorage.setItem('asToVal', asTovalArr);
                                        sessionStorage.setItem('asToValGraph', JSON.stringify(graphStd));
                                        sessionStorage.setItem('PMMScore', yAsIsPMMScore);
                                        this.props.history.push({
                                            pathname: '/StoryBoard',
                                            AsIsProps: yAsIsPMMScore,
                                            state: { asToVal: [xAsIsStdScore, yAsIsPMMScore, xToBeStdScore, yToBePMMScore, graphStd] }
                                        });
                                    }
                                    $("#loader-div").hide();
                                }
                            }
                            else {
                                alert(i18n.t("SaveFailed"));
                                $("#loader-div").hide();
                            }

                        })
                        .catch(error => {
                            console.log("Error Encountered");
                            $("#loader-div").hide();
                        });
                }
                else {
                    alert(i18n.t("PleaseEntervalidValuesintheFields"));
                    $("#loader-div").hide();
                }
            }
            else {
                alert(i18n.t("AsIsPMMScoreshouldbelessthanorequalToBePmmscore"));
                $("#loader-div").hide();
            }

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onQuickFilterText(event) {
        try {
            this.setState({ quickFilterText: event.target.value });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onOverallEdit() {
        try {
            this.setState({
                isEditAcitve: true,
                popUpDisplay: false
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onNavigationClick(eve) {
        try {
            let navigationPage = eve.target.getAttribute('data-content');
            let url = eve.target.getAttribute('data-url');
            if (this.state.isEditAcitve == true) {
                this.setState({
                    popUpDisplay: true,
                    toURL: url,
                    navigationPage: navigationPage
                })
            }
            else {
                if (navigationPage == "HOME") {
                    this.setState({
                        popUpDisplay: true,
                        toURL: url,
                        navigationPage: navigationPage
                    })
                }
                else if (navigationPage == "Take New Assessment") {
                    var data = {
                        LoggedInUserId: this.props.enterpriseId,
                        PMMProcessDraftID: this.props.configDetails.PMMProcessDraftID,
                        FlagId: 2
                    };
                    var data = {
                        LoggedInUserId: this.props.enterpriseId,
                        PMMProcessDraftID: this.props.configDetails.PMMProcessDraftID,
                        FlagId: 2
                    };
                    var data = {
                        LoggedInUserId: this.props.enterpriseId,
                        PMMProcessDraftID: this.props.configDetails.PMMProcessDraftID,
                        FlagId: 2
                    };
                    request("/Common/UpsertLockAndUnlockPMMProcessDraft?LanguageID=" + this.props.languageId, 'POST', data)
                        .then(res => {
                            $("#loader-div").hide();
                        })
                        .catch(error => {
                            alert(error.statusText);
                            $("#loader-div").hide();
                        });
                    this.props.history.push(url);
                }
                else {
                    this.props.history.push(url);
                }
            }

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closeNavigationPopUp() {
        try {
            this.setState({
                popUpDisplay: false
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    gridReadyFun = (eve) => {
        try {
            let objThis = this;
            var SearchedString = "";
            let PMMDetailsData = {
                PMMProcessDraftID: this.props.configDetails.PMMProcessDraftID,
                IndustryID: this.props.configDetails.OGIndustry.IndustryId,
                SCID: this.props.configDetails.ServiceComponent.value,
                LeverID: 0,
                SearchedString: SearchedString,
                UserName: this.props.enterpriseId,
                GroupID: this.props.groupId,
                LanguageID: this.props.languageId
            }
            let referenceDocumentData = {
                SCID: this.state.SCID,
                MaturityElement: "ALL",
                GroupID: this.props.groupId,
                LanguageID: this.props.languageId
            }

            $("#loader-div").show();

            eve.api.showLoadingOverlay();

            Promise.all([request("/PMM/GetPMMDetails", 'POST', PMMDetailsData),
            request("/PMM/GetReferenceDocumentNames", 'POST', referenceDocumentData)])

                .then((response) => {
                    if (response.length !== 0) {

                        eve.api.hideOverlay();

                        $("#loader-div").hide();

                        var pmmData = response[0];
                        pmmData.map((eachdata, index) => {

                            eachdata["AutoScoreStatusAsIs"] = false;
                            eachdata["AutoScoreStatusToBe"] = false;
                            if (response[1]) {
                                var emptyMaturity = response[1].filter((item) => item.MaturityElement.split('^').includes(eachdata.MaturityElement.trim()));
                                if (emptyMaturity !== undefined && emptyMaturity.length > 0) {
                                    eachdata["ContainsReferenceDocuments"] = true;
                                }
                                else {
                                    eachdata["ContainsReferenceDocuments"] = false;
                                }
                            }
                            else {
                                eachdata["ContainsReferenceDocuments"] = false;
                            }

                            if (eachdata.IsAutoCalculatePMMScore == true) {
                                let strBase = eachdata.Base;
                                let strEnhanced = eachdata.Enhanced == null ? "" : eachdata.Enhanced;
                                let strAboveAvg = eachdata.AboveAverage == null ? "" : eachdata.AboveAverage;
                                let strHighPerf = eachdata.HighPerformance;

                                let arrBase = strBase.match(/[^\s>=<()&&*/%+-]+/g);
                                let arrEnhanced = strEnhanced.match(/[^\s>=<()&&*/%+-]+/g);
                                let arrAboveAvg = strAboveAvg.match(/[^\s>=<()&&*/%+-]+/g);
                                let arrHighPerf = strHighPerf.match(/[^\s>=<()&&*/%+-]+/g);

                                let tempValBase = (strBase.replace(arrBase[0], eachdata.CurrentMetricValue)).replace(arrBase[2], eachdata.CurrentMetricValue);
                                let tempValEnhanced = "";
                                let tempValAboveAvg = "";
                                let tempValHighPerf = (strHighPerf.replace(arrHighPerf[0], eachdata.CurrentMetricValue)).replace(arrHighPerf[2], eachdata.CurrentMetricValue);

                                if (eval(tempValBase)) {
                                    eachdata.AsIsPMMScore = 1;
                                    eachdata["AutoScoreStatusAsIs"] = true;
                                }
                                if (strEnhanced != "") {
                                    tempValEnhanced = (strEnhanced.replace(arrEnhanced[0], eachdata.CurrentMetricValue)).replace(arrEnhanced[2], eachdata.CurrentMetricValue);
                                    if (eval(tempValEnhanced)) {
                                        eachdata.AsIsPMMScore = 2;
                                        eachdata["AutoScoreStatusAsIs"] = true;
                                    }
                                }
                                if (strAboveAvg != "") {
                                    tempValAboveAvg = (strAboveAvg.replace(arrAboveAvg[0], eachdata.CurrentMetricValue)).replace(arrAboveAvg[2], eachdata.CurrentMetricValue);
                                    if (eval(tempValAboveAvg)) {
                                        eachdata.AsIsPMMScore = 3;
                                        eachdata["AutoScoreStatusAsIs"] = true;
                                    }
                                }
                                if (eval(tempValHighPerf)) {
                                    eachdata.AsIsPMMScore = 4;
                                    eachdata["AutoScoreStatusAsIs"] = true;
                                }

                                tempValBase = (strBase.replace(arrBase[0], eachdata.FutureStateMetricValue)).replace(arrBase[2], eachdata.FutureStateMetricValue);
                                tempValHighPerf = (strHighPerf.replace(arrHighPerf[0], eachdata.FutureStateMetricValue)).replace(arrHighPerf[2], eachdata.FutureStateMetricValue);

                                if (eval(tempValBase)) {
                                    eachdata.TobePMMScore = 1;
                                    eachdata["AutoScoreStatusToBe"] = true;
                                }
                                if (strEnhanced != "") {
                                    tempValEnhanced = (strEnhanced.replace(arrEnhanced[0], eachdata.FutureStateMetricValue)).replace(arrEnhanced[2], eachdata.FutureStateMetricValue);
                                    if (eval(tempValEnhanced)) {
                                        eachdata.TobePMMScore = 2;
                                        eachdata["AutoScoreStatusToBe"] = true;
                                    }
                                }
                                if (strAboveAvg != "") {
                                    tempValAboveAvg = (strAboveAvg.replace(arrAboveAvg[0], eachdata.FutureStateMetricValue)).replace(arrAboveAvg[2], eachdata.FutureStateMetricValue);
                                    if (eval(tempValAboveAvg)) {
                                        eachdata.TobePMMScore = 3;
                                        eachdata["AutoScoreStatusToBe"] = true;
                                    }
                                }
                                if (eval(tempValHighPerf)) {
                                    eachdata.TobePMMScore = 4;
                                    eachdata["AutoScoreStatusToBe"] = true;
                                }

                            }
                        })
                        this.dynamicLeverDataCalculation(pmmData);

                        objThis.setState({ PMMAssessmentGridData: pmmData, PMMAssessmentData: response[0] });
                    }

                    else {
                        eve.api.showNoRowsOverlay();
                    }
                })
                .catch((error) => {
                    console.log("Error Encountered");
                });

            eve.api.sizeColumnsToFit();
            $("#loader-div").show();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    DownloadExcel() {
        try {            
            // if(!this.props.configDetails.DealName.label.toLowerCase().includes("test")){
            // window.tfo_dataevent("PMM-D-2", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1, this.props.configDetails.DealName.label)
            // }
            // to remove 'leave this page?' popup on download button
            var objThis = this;
            window.removeEventListener("beforeunload", this.unloadEvent);

            let excelData = {
                "PMMProcessDraftID": this.props.configDetails.PMMProcessDraftID,
                "DealName": this.props.configDetails.DealName.label,
                "SCName": this.props.configDetails.ServiceComponent.label,
                "UserName": this.props.enterpriseId,
                "GroupID": this.props.groupId,
                "LanguageID": this.props.languageId
            }
            request("/Common/DownloadtoExcelDraft", 'POST', excelData)
                .then(response => {
                    var filePath = response.Table1[0].Value;
                    window.location.href = filePath;

                })
                .catch(error => {
                    window.addEventListener("beforeunload", objThis.unloadEvent);
                    console.log("Error Encountered");
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onCellClicked(params) {
        try {
            if (params.colDef.field == "MetricImpacted") {
                this.setState({
                    popUpMetricImpacted: true,
                    selectedMetric: params.data
                })
            }
            if (params.colDef.field == "MaturityElement" || params.colDef.headerName == i18n.t('isStandardization') || params.colDef.field == "AsIsPMMScore" || params.colDef.field == "TobePMMScore" || params.colDef.field == "Remarks") {
                let selectedMaturityelement = 0;
                selectedMaturityelement = params.data.PMMDraftID;

                this.setState({
                    popUpMaturityElement: true,
                    PMMDraftID: selectedMaturityelement,
                    selectedMetric: params.data
                });

            }
            if (params.colDef.field == "ReferenceDocuments") {
                if (params.data.ContainsReferenceDocuments == true) {
                    this.setState({
                        popUpReferenceDocuments: true,
                        selectedMaturity: params.data.MaturityElement
                    })
                }
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    CreateDownload(params) {
        try {
            let eDiv = document.createElement("div");
            eDiv.innerHTML = '<span class="glyphicon glyphicon-download-alt"></span>';
            eDiv.className = "col-md-12";
            eDiv.width = "100%";
            if (params.data.ContainsReferenceDocuments == false) {
                eDiv.style = "color:lightgray";
            } else {
                eDiv.style = "color: deepskyblue";
            }
            return eDiv;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closeReferenceDocumentsPopUp() {
        try {
            this.setState({
                popUpReferenceDocuments: false
            });
            $("#loader-div").hide();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    showDownloadReferencePopup(maturityType) {
        try {
            this.setState({
                popUpReferenceDocuments: true,
                selectedMaturity: maturityType
            })
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closeMetricImpactedPopUp() {
        try {
            this.setState({
                popUpMetricImpacted: false
            });
            $("#loader-div").hide();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    DownloadReferenceContentExcel() {
        try {            
            // if(!this.props.configDetails.DealName.label.toLowerCase().includes("test")){
            // window.tfo_dataevent("PMM-D-3", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1, this.props.configDetails.DealName.label) 
            // }
            window.removeEventListener("beforeunload", this.unloadEvent);
            let excelData = {
                "PMMProcessDraftID": this.props.configDetails.PMMProcessDraftID,
                "DealName": this.props.configDetails.DealName.label,
                "SCName": this.props.configDetails.ServiceComponent.label,
                "UserName": this.props.enterpriseId,
                "GroupID": this.props.groupId,
                "LanguageID": this.props.languageId
            }
            request("/Common/DownloadtoExcelConfig", 'POST', excelData)
                .then(response => {
                    var filePath = response.Table1[0].Value;
                    window.location.href = filePath;;
                })
                .catch(error => {
                    window.addEventListener("beforeunload", this.unloadEvent);
                    console.log("Error Encountered");
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    OnMaturityElementClick() {
        try {
            var objThis = this;
            $("#loader-div").show();
            window.addEventListener("beforeunload", this.unloadEvent);
            window.addEventListener('resize', this.reSizeGrid, true);

            var SearchedString = "";
            let data = {
                PMMProcessDraftID: this.props.configDetails.PMMProcessDraftID,
                IndustryID: this.props.configDetails.OGIndustry.IndustryID,
                SCID: this.props.configDetails.ServiceComponent.value,
                LeverID: 0,
                SearchedString: SearchedString,
                UserName: this.props.enterpriseId,
                GroupID: this.props.groupId,
                LanguageID: this.props.languageId
            }
            let dropdownData = {
                "ID": 0,
                "DealId": 0,
                "UserName": this.props.enterpriseId,
                "GroupID": this.props.groupId,
                "LanguageID": this.props.languageId
            }

            Promise.all([request("/Common/DropDownsValue", 'POST', dropdownData),
            request("/PMM/GetPMMDetails", 'POST', data)])
                .then((response) => {

                    response.map(output => output.data.lever)
                    objThis.dynamicLeverDataCalculation();
                    response[0].lever.unshift({ value: 0, label: i18n.t('all') })
                    var pmmData = response[1];
                    pmmData.map((eachdata, index) => {

                        eachdata["AutoScoreStatusAsIs"] = false;
                        eachdata["AutoScoreStatusToBe"] = false;

                        if (eachdata.IsAutoCalculatePMMScore == true) {
                            let strBase = eachdata.Base;
                            let strEnhanced = eachdata.Enhanced == null ? "" : eachdata.Enhanced;
                            let strAboveAvg = eachdata.AboveAverage == null ? "" : eachdata.AboveAverage;
                            let strHighPerf = eachdata.HighPerformance;

                            let arrBase = strBase.match(/[^\s>=<()&&*/%+-]+/g);
                            let arrEnhanced = strEnhanced.match(/[^\s>=<()&&*/%+-]+/g);
                            let arrAboveAvg = strAboveAvg.match(/[^\s>=<()&&*/%+-]+/g);
                            let arrHighPerf = strHighPerf.match(/[^\s>=<()&&*/%+-]+/g);

                            let tempValBase = (strBase.replace(arrBase[0], eachdata.CurrentMetricValue)).replace(arrBase[2], eachdata.CurrentMetricValue);
                            let tempValEnhanced = "";
                            let tempValAboveAvg = "";
                            let tempValHighPerf = (strHighPerf.replace(arrHighPerf[0], eachdata.CurrentMetricValue)).replace(arrHighPerf[2], eachdata.CurrentMetricValue);

                            if (eval(tempValBase)) {
                                eachdata.AsIsPMMScore = 1;
                                eachdata["AutoScoreStatusAsIs"] = true;
                            }
                            if (strEnhanced != "") {
                                tempValEnhanced = (strEnhanced.replace(arrEnhanced[0], eachdata.CurrentMetricValue)).replace(arrEnhanced[2], eachdata.CurrentMetricValue);
                                if (eval(tempValEnhanced)) {
                                    eachdata.AsIsPMMScore = 2;
                                    eachdata["AutoScoreStatusAsIs"] = true;
                                }
                            }
                            if (strAboveAvg != "") {
                                tempValAboveAvg = (strAboveAvg.replace(arrAboveAvg[0], eachdata.CurrentMetricValue)).replace(arrAboveAvg[2], eachdata.CurrentMetricValue);
                                if (eval(tempValAboveAvg)) {
                                    eachdata.AsIsPMMScore = 3;
                                    eachdata["AutoScoreStatusAsIs"] = true;
                                }
                            }
                            if (eval(tempValHighPerf)) {
                                eachdata.AsIsPMMScore = 4;
                                eachdata["AutoScoreStatusAsIs"] = true;
                            }

                            tempValBase = (strBase.replace(arrBase[0], eachdata.FutureStateMetricValue)).replace(arrBase[2], eachdata.FutureStateMetricValue);
                            tempValHighPerf = (strHighPerf.replace(arrHighPerf[0], eachdata.FutureStateMetricValue)).replace(arrHighPerf[2], eachdata.FutureStateMetricValue);

                            if (eval(tempValBase)) {
                                eachdata.TobePMMScore = 1;
                                eachdata["AutoScoreStatusToBe"] = true;
                            }
                            if (strEnhanced != "") {
                                tempValEnhanced = (strEnhanced.replace(arrEnhanced[0], eachdata.FutureStateMetricValue)).replace(arrEnhanced[2], eachdata.FutureStateMetricValue);
                                if (eval(tempValEnhanced)) {
                                    eachdata.TobePMMScore = 2;
                                    eachdata["AutoScoreStatusToBe"] = true;
                                }
                            }
                            if (strAboveAvg != "") {
                                tempValAboveAvg = (strAboveAvg.replace(arrAboveAvg[0], eachdata.FutureStateMetricValue)).replace(arrAboveAvg[2], eachdata.FutureStateMetricValue);
                                if (eval(tempValAboveAvg)) {
                                    eachdata.TobePMMScore = 3;
                                    eachdata["AutoScoreStatusToBe"] = true;
                                }
                            }
                            if (eval(tempValHighPerf)) {
                                eachdata.TobePMMScore = 4;
                                eachdata["AutoScoreStatusToBe"] = true;
                            }

                        }
                    })

                    objThis.setState({ lstLever: response[0].lever, PMMAssessmentGridData: pmmData, PMMAssessmentData: response[1], LeverValue: { value: 0, label: i18n.t('all') }, });


                    $("#loader-div").hide();
                })
                .catch((error) => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    handleMaturityValueChange(params) {
        this.OnMaturityElementClick();
    }


    CalculateAsIsToBeScore(ValAsIs, ValToBe, remarks, PMMDraftID, PMMConfigID) {
        try {
            var objThis = this;
            var leverWithValue = [];
            var sumForOverAllAsIs = 0;
            var sumForOverAllToBe = 0;
            var asisAvgcount=0;
            var tobeAvgcount=0;  
            let standardizationSum = 0, standardizationCount = 0;
            let standardizationToBeSum = 0, standardizationToBeCount = 0;
            var pmmData = this.refs.grd_PMMAssessment.gridOptions.rowData;
            var distincLeverData = [...new Set(pmmData.map(x => x.LeverName))];

            for (var i = 0; i < distincLeverData.length; i++) {

                var data = pmmData.filter(x => x.LeverName == distincLeverData[i]).filter(obj => obj.AsIsPMMScore !== 'NA' || obj.TobePMMScore !== 'NA')
                var asisSum = 0;
                var tobeSum = 0;
                var asisCount = 0;
                var tobeCount = 0;  
                data.map((eachdata) => {
                    if (eachdata.PMMConfigID == PMMConfigID && eachdata.PMMDraftID == PMMDraftID && !eachdata.IsAutoCalculatePMMScore) {
                        eachdata.AsIsPMMScore = ValAsIs;
                        eachdata.TobePMMScore = ValToBe;
                        eachdata.Remarks = remarks;
                    }
                    asisSum += Number(eachdata.AsIsPMMScore === 'NA' || eachdata.AsIsPMMScore === -1 ? 0 : eachdata.AsIsPMMScore),
                        // to ignore NA values while calculating average as is score
                        asisCount += Number(eachdata.AsIsPMMScore === 'NA' || eachdata.AsIsPMMScore === -1 ? 0 : 1),
                        tobeSum += Number(eachdata.TobePMMScore === 'NA' || eachdata.TobePMMScore === -1 ? 0 : eachdata.TobePMMScore),
                        // to ignore NA values while calculating average to be score
                        tobeCount += Number(eachdata.TobePMMScore === 'NA' || eachdata.TobePMMScore === -1 ? 0 : 1)
                    if (eachdata.IsStandardization == true) {

                        standardizationSum += Number(eachdata.AsIsPMMScore === 'NA' || eachdata.AsIsPMMScore === -1 ? 0 : eachdata.AsIsPMMScore),
                            standardizationCount += Number(eachdata.AsIsPMMScore === 'NA' || eachdata.AsIsPMMScore === -1 ? 0 : 1)
                        standardizationToBeSum += Number(eachdata.TobePMMScore === 'NA' ? eachdata.TobePMMScore === -1 || 0 : eachdata.TobePMMScore),
                            standardizationToBeCount += Number(eachdata.TobePMMScore === 'NA' || eachdata.TobePMMScore === -1 ? 0 : 1)

                    }
                })

                leverWithValue.push(
                    {
                        'LeverName': distincLeverData[i],
                        'AsIsSum': asisSum,
                        'ToBeSum': tobeSum,
                        'AsIsAvg': (asisSum / asisCount).toFixed(2),
                        'ToBeAvg': (tobeSum / tobeCount).toFixed(2),

                    }
                )

                asisAvgcount += (asisCount === 0) ? 0 : (asisCount);
                tobeAvgcount += (tobeCount === 0) ? 0 : (tobeCount);
                sumForOverAllAsIs +=  parseFloat(asisSum)
                sumForOverAllToBe +=  parseFloat(tobeSum)


            }

            let standardAvg = (standardizationSum / standardizationCount).toFixed(2);
            let standardToBeAvg = (standardizationToBeSum / standardizationToBeCount).toFixed(2);

            // to find distinct levers which do not have AsIsAvg as Nan(do not have all values as NA)
            // let distinctCount = leverWithValue.filter(function (item) {
            //     return item.AsIsAvg !== "NaN"
            // }).length

            document.getElementById("AsIsPMMScore").textContent = Number(sumForOverAllAsIs / asisAvgcount).toFixed(2);
            document.getElementById("idStandardAsIsAvg").textContent = isNaN(standardAvg) == true ? i18n.t('na') : standardAvg;

            document.getElementById("ToBePMMScore").textContent = Number(sumForOverAllToBe / tobeAvgcount).toFixed(2);
            document.getElementById("idStandardToBeAvg").textContent = isNaN(standardToBeAvg) == true ? i18n.t('na') : standardToBeAvg;
            objThis.setState({ dynamicLeverData: leverWithValue })


            var pmmData = this.refs.grd_PMMAssessment.gridOptions.rowData;
            pmmData.map((eachdata, index) => {
                if (eachdata.PMMConfigID == PMMConfigID && eachdata.PMMDraftID == PMMDraftID) {
                    eachdata.AsIsPMMScore = ValAsIs;
                    eachdata.TobePMMScore = ValToBe;
                    eachdata.Remarks = remarks;
                }


                eachdata["AutoScoreStatusAsIs"] = false;
                eachdata["AutoScoreStatusToBe"] = false;

                if (eachdata.IsAutoCalculatePMMScore == true) {
                    let strBase = eachdata.Base;
                    let strEnhanced = eachdata.Enhanced == null ? "" : eachdata.Enhanced;
                    let strAboveAvg = eachdata.AboveAverage == null ? "" : eachdata.AboveAverage;
                    let strHighPerf = eachdata.HighPerformance;

                    let arrBase = strBase.match(/[^\s>=<()&&*/%+-]+/g);
                    let arrEnhanced = strEnhanced.match(/[^\s>=<()&&*/%+-]+/g);
                    let arrAboveAvg = strAboveAvg.match(/[^\s>=<()&&*/%+-]+/g);
                    let arrHighPerf = strHighPerf.match(/[^\s>=<()&&*/%+-]+/g);

                    let tempValBase = (strBase.replace(arrBase[0], eachdata.CurrentMetricValue)).replace(arrBase[2], eachdata.CurrentMetricValue);
                    let tempValEnhanced = "";
                    let tempValAboveAvg = "";
                    let tempValHighPerf = (strHighPerf.replace(arrHighPerf[0], eachdata.CurrentMetricValue)).replace(arrHighPerf[2], eachdata.CurrentMetricValue);

                    if (eval(tempValBase)) {
                        eachdata.AsIsPMMScore = 1;
                        eachdata["AutoScoreStatusAsIs"] = true;
                    }
                    if (strEnhanced != "") {
                        tempValEnhanced = (strEnhanced.replace(arrEnhanced[0], eachdata.CurrentMetricValue)).replace(arrEnhanced[2], eachdata.CurrentMetricValue);
                        if (eval(tempValEnhanced)) {
                            eachdata.AsIsPMMScore = 2;
                            eachdata["AutoScoreStatusAsIs"] = true;
                        }
                    }
                    if (strAboveAvg != "") {
                        tempValAboveAvg = (strAboveAvg.replace(arrAboveAvg[0], eachdata.CurrentMetricValue)).replace(arrAboveAvg[2], eachdata.CurrentMetricValue);
                        if (eval(tempValAboveAvg)) {
                            eachdata.AsIsPMMScore = 3;
                            eachdata["AutoScoreStatusAsIs"] = true;
                        }
                    }
                    if (eval(tempValHighPerf)) {
                        eachdata.AsIsPMMScore = 4;
                        eachdata["AutoScoreStatusAsIs"] = true;
                    }

                    tempValBase = (strBase.replace(arrBase[0], eachdata.FutureStateMetricValue)).replace(arrBase[2], eachdata.FutureStateMetricValue);
                    tempValHighPerf = (strHighPerf.replace(arrHighPerf[0], eachdata.FutureStateMetricValue)).replace(arrHighPerf[2], eachdata.FutureStateMetricValue);

                    if (eval(tempValBase)) {
                        eachdata.TobePMMScore = 1;
                        eachdata["AutoScoreStatusToBe"] = true;
                    }
                    if (strEnhanced != "") {
                        tempValEnhanced = (strEnhanced.replace(arrEnhanced[0], eachdata.FutureStateMetricValue)).replace(arrEnhanced[2], eachdata.FutureStateMetricValue);
                        if (eval(tempValEnhanced)) {
                            eachdata.TobePMMScore = 2;
                            eachdata["AutoScoreStatusToBe"] = true;
                        }
                    }
                    if (strAboveAvg != "") {
                        tempValAboveAvg = (strAboveAvg.replace(arrAboveAvg[0], eachdata.FutureStateMetricValue)).replace(arrAboveAvg[2], eachdata.FutureStateMetricValue);
                        if (eval(tempValAboveAvg)) {
                            eachdata.TobePMMScore = 3;
                            eachdata["AutoScoreStatusToBe"] = true;
                        }
                    }
                    if (eval(tempValHighPerf)) {
                        eachdata.TobePMMScore = 4;
                        eachdata["AutoScoreStatusToBe"] = true;
                    }

                }
            })


            this.refs.grd_PMMAssessment.gridOptions.api.setRowData(pmmData);
            this.setState({ PMMAssessmentGridData: pmmData, isEditAcitve: true });
            let params = {
                force: true
            }
            this.refs.grd_PMMAssessment.gridOptions.api.refreshCells(params);

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    render() {
        var columnDefs = [];
        columnDefs = [
            { headerName: i18n.t('lever'), field: 'LeverName', tooltipField: 'LeverName', suppressMovable: true, suppressResize: true, autoHeight: true, width: 80 },
            { headerName: i18n.t('KPA'), field: 'KPA', tooltipField: 'KPA', hide: false, suppressMovable: true, suppressResize: true, autoHeight: true, width: 100 },
            { headerName: i18n.t('metricImpacted'), field: 'MetricImpacted', tooltipField: 'MetricImpacted', suppressMovable: true, suppressResize: true, autoHeight: true, width: 150 },
            {
                headerName: i18n.t('maturityElement'), field: 'MaturityElement', tooltip: function (params) { return i18n.t('clickForDetailed') },
                cellStyle: function (params) {

                    var background = "";
                    var textColor = "";
                    if (params.node.data.IsStandardization == 1) {

                        textColor = "#000000"
                    }
                    return {
                        background: background,
                        color: textColor
                    };
                }, suppressMovable: true, suppressResize: true, autoHeight: true
            },

            {
                headerName: i18n.t('CurrentMetricValue'), hide: true, field: 'CurrentMetricValue', suppressMovable: true, suppressResize: true, width: 80, cellStyle: function (params) {
                    var background = "";
                    if (params.node.data.IsAutoCalculatePMMScore == true) {
                        if (params.node.data.AutoScoreStatusAsIs == false) {
                            background = "#E57373";
                        }
                    }
                    return {
                        background: background
                    };
                },
                tooltip: function (params) {
                    if (params.node.data.IsAutoCalculatePMMScore == true) {
                        if (params.node.data.AutoScoreStatusAsIs == false) {
                            return i18n.t("MetricValueisnotwithintheexpectedrange");
                        }
                    }
                    else {
                        return params.data.CurrentMetricValue;
                    }
                }
            },
            {
                headerName: i18n.t('asIsPMMScore'), field: 'AsIsPMMScore', editable:
                    false,

                suppressMovable: true, suppressResize: true, width: 110

                , cellRenderer: createCircelText,
                cellRendererParams: { enterpriseId: this.props.enterpriseId },
                cellStyle: function (params) {

                    var background = "";
                    if (params.node.data.IsAutoCalculatePMMScore == true) {
                        background = "#ffc200b8";
                    }
                    return {
                        background: background
                    };
                }, autoHeight: true
            },
            {
                headerName: i18n.t('FutureStateMetricValue'), hide: true, field: "FutureStateMetricValue", suppressMovable: true, suppressResize: true, width: 120, cellStyle: function (params) {

                    var background = "";
                    if (params.node.data.IsAutoCalculatePMMScore == true) {
                        if (params.node.data.AutoScoreStatusToBe == false) {
                            background = "#E57373";
                        }
                    }
                    return {
                        background: background
                    };
                }, tooltip: function (params) {
                    if (params.node.data.IsAutoCalculatePMMScore == true) {
                        if (params.node.data.AutoScoreStatusToBe == false) {
                            return i18n.t("MetricValueisnotwithintheexpectedrange");
                        }
                    }
                    else {
                        return params.data.FutureStateMetricValue;
                    }
                }
            },
            {
                headerName: i18n.t('toBePMMScore'), field: 'TobePMMScore', editable:
                    false,

                suppressMovable: true, suppressResize: true, width: 100

                , cellRenderer: createCircelText,
                cellRendererParams: { enterpriseId: this.props.enterpriseId },
                cellStyle: function (params) {

                    var background = "";
                    if (params.node.data.IsAutoCalculatePMMScore == true) {
                        background = "#ffc200b8";
                    }
                    return {
                        background: background
                    };
                }
            },
            {
                headerName: i18n.t('Remarks'), field: 'Remarks', tooltipField: 'Remarks', suppressMovable: true, suppressResize: true,
                cellEditor: 'agLargeTextCellEditor',
                cellEditorParams: function (params) {
                    return {
                        maxLength: 1000,
                        rows: 5,
                        cols: 40
                    }
                },
                editable: function (params) {
                    if (params.node.data.IsSelected == true) {
                        return false;
                    }
                    else {
                        return true;
                    }
                }, autoHeight: true
            },
            {
                headerName: i18n.t('ReferenceDocuments'), field: 'ReferenceDocuments', width: 50, suppressMovable: true, suppressResize: true, autoHeight: true, cellRenderer: this.CreateDownload,
                cellStyle: function (params) {
                    return {
                        textDecoration: "underline",
                        cursor: "pointer"
                    };
                }
                , tooltip: function (params) {
                    return i18n.t("viewReferenceDocs");
                }
            },

        ];

        var PMMAssessmentDataGrid = (
            <div style={{ height: '410px' }} className="ag-theme-material padding5">
                <AgGridReact ref="grd_PMMAssessment" id="grd_PMMAssessmentData"
                    paginationPageSize="10"
                    enableColResize={false}
                    columnDefs={columnDefs}
                    rowData={this.state.PMMAssessmentGridData}
                    headerHeight="45"
                    gridAutoHeight={true}
                    onGridReady={this.gridReadyFun}
                    singleClickEdit={true}
                    enableSorting={false}
                    quickFilterText={this.state.quickFilterText}
                    onCellEditingStarted={this.onOverallEdit}
                    rowmodeltype="pagination"
                    suppressScrollOnNewData={true}
                    localeText={AgGridLocale()}
                    onCellClicked={this.onCellClicked.bind(this)}
                    overlayLoadingTemplate={`<span class="ag-overlay-loading-center">${i18n.t('dataLoading')}</span>`}
                />
            </div>
        )

        return (
            <section className="content">
                <div className="innerpage-hd"><a type="button" className="btn btn-info btn-back" data-content="HOME" data-url="/Home" onClick={this.onNavigationClick}><img src={backArrowIcon} data-content="HOME" data-url="/Home" /> {i18n.t('bACKTOHOME')}</a>
                    <ul className="pagecounter"  >
                        <li onClick={this.onNavigationClick} data-url="/CreateNewAssessment"><span title={i18n.t("takeNewAssessment")} className="linkSpan" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Take New Assessment" data-url="/CreateNewAssessment"></span></li>
                        <li onClick={this.onNavigationClick} data-url="/BOI"><span title={i18n.t("BOIKeyBusinessMetric")} className="current linkSpan" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="BOI" data-url="/BOI"></span></li>
                        <li onClick={this.onNavigationClick} data-url="/LeadingIndicator"><span title={i18n.t("lEADINGINDICATOR")} className="current linkSpan" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Leading Indicator" data-url="/LeadingIndicator">...</span></li>
                        <li><span title={i18n.t("pMMASSESSMENT")} className="current" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="PMM" data-url="/PMMAssessment">...</span></li>
                        <li><span title={i18n.t("sTORYBOARDGAPASSESSMENT")} className="" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Storyboard" data-url="/Storyboard">4</span></li>
                        <li><span title={i18n.t("pOTENTIALPROJECTS")} className="" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Potential Projects">5</span></li>
                        <li><span title={i18n.t("pROJECTPRIORITIZATION")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Project Prioritization">6</span></li>

                    </ul>
                    {this.state.popUpDisplay && <NavigationConfirmation show={this.state.popUpDisplay} navigationPage={this.state.navigationPage} isEditAcitve={this.state.isEditAcitve} url={this.state.toURL} closeCallback={this.closeNavigationPopUp} propPMMProcessDraftID={this.props.configDetails.PMMProcessDraftID} />}
                </div>
                <section className="bodydata">
                    <div className="bodydata-full">
                        <h4><div className="pull-left">{i18n.t('pMMASSESSMENT')}</div>

                            <div className="pull-right">
                                <span class='statustxt-orng-italic' title={i18n.t('deal') + ': ' + this.props.configDetails.DealName.label}>{this.props.configDetails.DealName.label}
                                </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('region') + ': ' + this.props.configDetails.Region.label}>{this.props.configDetails.Region.label}
                                </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('country') + ': ' + this.props.configDetails.Country.label}>{this.props.configDetails.Country.label}
                                {/* </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('oG') + ': ' + this.props.configDetails.OGIndustry.OGName}>{this.props.configDetails.OGIndustry.OGName} */}
                                </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('Industry') + ': ' + this.props.configDetails.OGIndustry.IndustryName}>{this.props.configDetails.OGIndustry.IndustryName}
                                </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('capability') + ': ' + this.props.configDetails.Capability.label}>{this.props.configDetails.Capability.label}
                                </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('tower') + ': ' + this.props.configDetails.Tower.label}>{this.props.configDetails.Tower.label}
                                </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('serviceComponent') + ': ' + this.props.configDetails.ServiceComponent.label}>{this.props.configDetails.ServiceComponent.label}</span>
                            </div>
                        </h4>

                        <h4>
                            <span className="col-md-3 infoblockfilter ">
                                <label className="col-md-3" id="lbl_searchPMM" style={{ marginTop: "10px" }}>{i18n.t('search')}:</label>
                                <input type="text" id="txt_filterText" className="col-md-9 searchText" onChange={this.onQuickFilterText.bind(this)} placeholder={i18n.t("Typetexttofilter")} />
                            </span>
                            <div className="col-md-7">
                                <ul className="grid-legend ulStyle">
                                    <li className="liStyle">
                                        <span className="formula-span">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        <span className="formula-span-gap"> {i18n.t('PMMscoreautocalculatedbytool')} </span>
                                        <span className="error-span">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        <span className="formula-span-gap"> {i18n.t('ValidationErrorValueoutofrange')} </span>

                                    </li>
                                </ul>

                            </div>

                            <div className="col-md-2 btn-group pull-right">
                                <button type="button" id="btn_downloadReferenceDocsForPMM" title={i18n.t("DownloadReferenceDocumentsforthisAssessment")} className="btn btn-default downloadCommon pull-right" onClick={this.showDownloadReferencePopup.bind(this, "All")}>
                                    <img src={downloadGreenIcon} />
                                </button>
                                <button type="button" id="btn_referencePMMTemplateForPMM" title={i18n.t("ReferencePMMTemplate")} className="btn btn-default downloadCommon pull-right" onClick={this.DownloadReferenceContentExcel}>
                                    <img src={downloadBrownIcon} />
                                </button>
                                <button type="button" id="btn_downloadSavedScreensPMM" title={i18n.t("DownloadSavedScreensforthisAssessment")} className="btn btn-default downloadCommon pull-right" onClick={this.DownloadExcel}>
                                    <img src={downloadIcon} />
                                </button>
                            </div>


                        </h4>
                        <div className="box-plain pmm-asmt-tbl">

                            <div className="box PMM-assessment-div">
                                {PMMAssessmentDataGrid}
                            </div>
                        </div>

                        <div className="btn-group-md row">
                            <div className="col-md-4">
                                <a type="button" className="btn btn-danger" onClick={this.SavePMMAssessment}>{i18n.t('save')}</a>
                                <a type="button" className="btn btn-success" onClick={this.SavePMMAssessment}>{i18n.t('createGapAssessment')} </a>
                            </div>
                            <div className="row" style={{ backgroundColor: "#fff" }}>
                                <div className="col-md-4"> <Table responsive>
                                    <thead>
                                        <tr>
                                            <th></th>
                                            {
                                                this.state.dynamicLeverData.map((l) => {
                                                    return <th>{l.LeverName}</th>
                                                })
                                            }

                                            <th>{i18n.t('overall')}</th>
                                            <th>{i18n.t('standardization')}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                {i18n.t('As-Is')}
                                            </td>

                                            {
                                                this.state.dynamicLeverData.map((l) => {
                                                    return <td>
                                                        <span className="greentext">{l.AsIsAvg === "NaN" ? "NA" : l.AsIsAvg}</span>
                                                    </td>
                                                })
                                            }
                                            <td>
                                                <span id="AsIsPMMScore" className="greentext first">{this.state.AsIsPMM}</span>
                                            </td>
                                            <td>
                                                <span id="idStandardAsIsAvg" className="greentext first">{this.state.ToBePMM}</span>
                                            </td>

                                        </tr>

                                    </tbody>
                                </Table>
                                </div>
                                <div className="col-md-4">
                                    <Table responsive>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                {
                                                    this.state.dynamicLeverData.map((l) => {
                                                        return <th>{l.LeverName}</th>
                                                    })
                                                }

                                                <th>{i18n.t('overall')}</th>
                                                <th>{i18n.t('standardization')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td>{i18n.t('To-Be')}</td>

                                                {
                                                    this.state.dynamicLeverData.map((l) => {
                                                        return <td>
                                                            <span className="greentext">{l.ToBeAvg === "NaN" ? "NA" : l.ToBeAvg}</span>
                                                        </td>
                                                    })
                                                }
                                                <td>
                                                    <span id="ToBePMMScore" className="greentext first">{this.state.ToBePMM}</span>
                                                </td>
                                                <td>
                                                    <span id="idStandardToBeAvg" className="greentext first">{this.state.ToBePMM}</span>
                                                </td>

                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </div>
                        {this.state.popUpMetricImpacted && <MetricImpactedDetail show={this.state.popUpMetricImpacted} closeCallback={this.closeMetricImpactedPopUp} selectedMetric={this.state.selectedMetric} />}
                        {this.state.popUpMaturityElement && <MaturityElementDetail show={this.state.popUpMaturityElement} closeCallback={this.closeMaturityElementPopUp} selectedMetric={this.state.selectedMetric} PMMProcessID={0} PMMTransactionID={0} PMMProcessDraftID={this.props.configDetails.PMMProcessDraftID} PMMDraftID={this.state.PMMDraftID} autoScoreCalculation={this.AutoScoreCalculation} onMaturityValueChange={this.handleMaturityValueChange} onScoreChange={this.CalculateAsIsToBeScore.bind(this)} AllpmmdataArray={this.state.PMMAssessmentGridData} />}
                        {this.state.popUpReferenceDocuments && <ReferenceDocumentsDetail show={this.state.popUpReferenceDocuments} closeCallback={this.closeReferenceDocumentsPopUp} selectedMaturity={this.state.selectedMaturity} SCID={this.state.SCID} />}
                    </div>
                </section>
            </section >
        );
    }
}
function mapStateToProps(state) {
    return {
        languageId: state.languageId,
        enterpriseId: state.enterpriseId,
        groupId: state.groupId,
        roleName: state.roleName,
        pageName: state.pageName,
        groupName: state.groupName,
    }
}


function mapDispatchToProps(dispatch) {
    return ({
        setDropdownValues: (data) => dispatch(setDropdownValues(data))
    })
}

PMMAssessment = connect(mapStateToProps, mapDispatchToProps)(withRouter(PMMAssessment))
export default PMMAssessment;
