/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { Link, withRouter } from 'react-router-dom';
import backArrowIcon from '../../images/icon-back-arrow.png';
import downloadIcon from '../../images/icon-download.png';
import request from '../../services/Service';
import i18n from 'i18next';
import { AgGridLocale } from '../../Utils/AgGridLocale';

class ComparativeReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            comparativeReportData: [],
            columnDefs: []
        }
        this.reSizeGrid = this.reSizeGrid.bind(this);
    }

    componentDidMount() {  
        // if(!this.props.Assessmentdetails.Deal[0].label.toLowerCase().includes("test"))      {
        // window.tfo_dataevent("PMM-VSA-17", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1,this.props.Assessmentdetails.Deal[0].label)
        // }
    }

    componentWillUnmount() {

    }

    reSizeGrid() {

    }

    gridReadyFun = (eve) => {
        try {
            eve.api.showLoadingOverlay();

            let objThis = this;
            var comparData = [];
            var DealId = objThis.props.Assessmentdetails.Deal[0].value;
            var SCID = objThis.props.Assessmentdetails.ServiceComponent[0].value;
            let userData = {
                "DealId": DealId,
                "SCId": SCID,
                "UserName": this.props.enterpriseId,
			    "GroupID": this.props.groupId,
			    "LanguageID":this.props.languageId
            }
            request("/Common/ComparativeReport", 'POST', userData)
                .then(response => {
                    var colDef = [];
                    if ((response !== undefined || response !== null || response !== "" ) && Object.keys(response).length !==0 ) {

                        eve.api.hideOverlay();

                        comparData = response.Table;
                        if (comparData.length > 0) {
                            var columnsIn = comparData[0];
                            for (var colName in columnsIn) {
                                if (colName != "PMMProcessDraftID") {
                                    colDef.push({
                                        headerName: colName,
                                        field: colName,
                                        tooltipField: colName,
                                        suppressMovable: true,
                                        suppressResize: true,
                                        autoHeight: true
                                    })
                                }
                            }
                        }
                        objThis.setState({ columnDefs: colDef, comparativeReportData: response.Table });
                    }

                    else {
                        eve.api.showNoRowsOverlay();
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }

        catch (error) {
            console.log("Error Encountered")
        }
    }

    DownloadExcel() {
        var DealId = this.props.Assessmentdetails.Deal[0].value;
        var SCID = this.props.Assessmentdetails.ServiceComponent[0].value;
        let scData = {
            "DealId": DealId,
            "SCId": SCID,
            "UserName": this.props.enterpriseId,
			"GroupID": this.props.groupId,
			"LanguageID":this.props.languageId
        }
        request("/Common/DownloadtoExcelComparativeReport", 'POST', scData)
            .then(response => {
                var filePath = response.Table1[0].Value;
                window.location.href = filePath;
            })
            .catch(error => {
                console.log(error);
            });
    }

    render() {
        var comparData = this.state.comparativeReportData;
        var ColDefs = this.state.columnDefs;
        var ComparativeReportGrid = (
            <div style={{ height: '450px' }} className="ag-theme-material padding5">
                <AgGridReact ref="Grd_Comparision" id="grd_Comparision"
                    enableColResize={false}
                    paginationPageSize="10"
                    columnDefs={ColDefs}
                    rowData={comparData}
                    headerHeight="45"
                    rowmodeltype="pagination"
                    onGridReady={this.gridReadyFun}
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate={`<span class="ag-overlay-loading-center">${i18n.t('dataLoading')}</span>`} />
            </div>
        )

        return (
            <section className="content">
                <div className="innerpage-hd">
                    <Link className="menu2" to="/Home">
                        <a type="button" className="btn btn-info btn-back">
                            <img src={backArrowIcon} /> {i18n.t('bACKTOHOME')}</a>
                    </Link>
                    <Link className="menu2 pull-left" to="/CompareRegionGrid">
                        <a type="button" className="btn btn-info btn-back">
                            <img src={backArrowIcon} /> {i18n.t('BackToCompare')}</a>
                    </Link>
                </div>
                <section className="bodydata">
                    <div className="bodydata-full">
                        <div className="box-plain">
                            <h4><div className="pull-left">{i18n.t('ComparativeReport')}</div>
                                <div className="btn-group downloadComparative">
                                    <button id="btn_downloadComparativeReportAcrossClientBusinessRegionForGivenDeal_ServiceComponentCombination" type="button" title={i18n.t("DownloadComparativereport")} className="btn btn-default btn-downld" onClick={this.DownloadExcel.bind(this)}>
                                        <img src={downloadIcon} />
                                    </button>
                                </div>
                                <div className="row compareHeader pull-right">
                                    <span class='statustxt-orng-italic' title={i18n.t('deal') + ': ' + this.props.Assessmentdetails.Deal[0].label}>{this.props.Assessmentdetails.Deal[0].label}
                                    </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('region') + ':' + this.props.Assessmentdetails.Region[0].label}> {this.props.Assessmentdetails.Region[0].label}
                                    </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('country') + ': ' + this.props.Assessmentdetails.Country.label}>{this.props.Assessmentdetails.Country.label}
                                    </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('delivery_Location') + ': ' + this.props.Assessmentdetails.DeliveryLocation.label}>{this.props.Assessmentdetails.DeliveryLocation.label}
                                    </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('capability') + ': ' + this.props.Assessmentdetails.Capability[0].label}>{this.props.Assessmentdetails.Capability[0].label}
                                    </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('tower') + ': ' + this.props.Assessmentdetails.Tower[0].label}>{this.props.Assessmentdetails.Tower[0].label}
                                    </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('serviceComponent') + ': ' + this.props.Assessmentdetails.ServiceComponent[0].label}>{this.props.Assessmentdetails.ServiceComponent[0].label} </span>
                                </div>
                            </h4>

                            <div className="AsmtData viewprevassmt">
                                {ComparativeReportGrid}
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        );
    }
}

function mapStateToProps(state){
    return{
		dropDownsValue:state.dropDownsValue,
        languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
        roleName:state.roleName,
        pageName:state.pageName,
        groupName:state.groupName,  
    }
}

ComparativeReport = connect(mapStateToProps)(withRouter(ComparativeReport))
export default ComparativeReport;