/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Chart } from 'chart.js';
import html2canvas from 'html2canvas'
import $ from 'jquery';
import backArrowIcon from '../../images/icon-back-arrow.png';
import powerpointIcon from '../../images/powerpoint-icon.png';
import PptxGenJS from 'pptxgenjs';
import request from '../../services/Service';
import { Typeahead } from 'react-bootstrap-typeahead';
import i18n from 'i18next';

var recordCount = 0;

function createCircelText() {
    try {
        this.eGui = document.createElement('div');
        this.eGui.className = "row"
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

createCircelText.prototype.init = function (params) {
    try {
        let spanValue = document.createElement('span');
        spanValue.className = "AsIsToBeLabel";
        let circle = document.createElement('div');
        circle.className = "circleDivCompare";
        spanValue.textContent = params.value;
        if (params.data.DealName.trim() != i18n.t('benchmark_CompareScreen')) {
            if (params.value != "" && params.value >= 0 && params.value < 2) {
                circle.style.backgroundColor = "#E57373"
            }
            else if (params.value != "" && params.value >= 2 && params.value < 3) {
                circle.style.backgroundColor = "#FFB74D"
            }
            else if (params.value != "" && params.value >= 3 && params.value < 3.5) {
                circle.style.backgroundColor = "#FFEB3B"
            }
            else if (params.value != "" && params.value >= 3.5 && params.value <= 4) {
                circle.style.backgroundColor = "#81C784"
            }
            this.eGui.appendChild(circle);
            this.eGui.appendChild(spanValue);
        }
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

createCircelText.prototype.getGui = function (params) {
    return this.eGui;
}

class BOILITrendReport extends React.Component {
    constructor(props) {
        super(props);
        try {
            recordCount = 0;
            this.state = {
                headerValue: "",
                lstDeal: [],
                lstRegion: [],
                lstServiceComp: [],
                lstDlvryLoc: [],
                lstBOILI: [],
                DealValue: [],
                RegionValue: [],
                OGIndustry: "",
                OG: "",
                TowerValue: [],
                ServiceCompValue: [],
                DlvryLocValue: [],
                BOILIValue: [],
                eblNxtBTn: false,
                Confirm: false,
                message: "",
                DraftID: 0,
                datasetNames: [],
                chartData: [],
                chartType: ""
            };
            this.filterCallbackTypeahead = this.filterCallbackTypeahead.bind(this);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {
        try {
            $("#loader-div").show();
            window.addEventListener('resize', this.reSizeGrid, true);


            var DealID = 0;
            var SCID = 0;
            var DeliveryLocationId = 0;
            let dealData = {
                "DealId": 0,
                "PermissionLevel": "3,2,1",
                "UserName": this.props.enterpriseId,
                "GroupID": this.props.groupId,
                "LanguageID":this.props.languageId 
            }
            request("/Common/GetDealBasedOnUserAccess", 'POST', dealData)
                .then(response => {
                    response.length > 0 ? this.setState({ lstDeal: response }) : this.setState({ lstDeal: [] });
                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log(error);
                    $("#loader-div").hide();
                });

            request("/MasterScreens/GetSCBasedOnTower?SCId=0&TowerId=0&GroupID=" + this.props.groupId + "&LanguageID=" + this.props.languageId, 'GET')
            .then(response => {
                    response.length > 0 ? this.setState({ lstServiceComp: response }) : this.setState({ lstServiceComp: [] });
                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log(error);
                    $("#loader-div").hide();
                });

            var SearchedString = "";
            let BOILIMasteData ={
                TowerId:0,
                BOILIFlag:3,
                SearchedString:SearchedString,
                UserName:this.props.enterpriseId,				
			    GroupID: this.props.groupId,
			    LanguageID:this.props.languageId
            }
            request("/BOI/GetBOILIMasterForDropDown", 'POST', BOILIMasteData)
                .then(response => {
                    response.length > 0 ? this.setState({ lstBOILI: response }) : this.setState({ lstBOILI: [] });
                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log(error);
                    $("#loader-div").hide();
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectDeal(val) {
        try {
            $("#loader-div").show();
            if (val.length != 0) {
                this.setState({ DealValue: val });
            }
            let ogData = {
                "DealId": val[0].value,
                "UserName": this.props.enterpriseId,
			    "GroupID": this.props.groupId,
			    "LanguageID":this.props.languageId
            }
            request("/Common/GetOGBasedOnDeal", 'POST', ogData)
                .then(response => {
                    this.setState({ OGIndustry: response[0] })
                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log(error);
                    $("#loader-div").hide();
                });

            this.setState({
                lstRegion: [], RegionValue: []
            });
            request("/MasterScreens/GetRegionBasedOnDeal?DealId=" + val[0].value + "&Flag=1&GroupID=" +this.props.groupId + "&LanguageID=" + this.props.languageId, 'GET')
           
            .then(response => {
                    if (response.length > 0) {
                        this.setState({ RegionValue: [{ label: i18n.t('all'), value: 0 }] });
                    }
                    this.setState({ lstRegion: response });
                })
                .catch(error => {
                    console.log(error);
                });

            let dealData = {
                "DeliveryLocationId": 0,
                "DealId": val[0].value,
                "PermissionLevel": "3,2,1",
                "UserName": this.props.enterpriseId,
			    "GroupID": this.props.groupId,
			    "LanguageID":this.props.languageId
            }
            request("/Common/DLDropDownBasedOnUserAccess", 'POST', dealData)
                .then(response => {
                    var resultArr = response;
                    if (response.length > 0) {
                        resultArr.unshift({ "label": i18n.t('all'), "value": "0" });
                        this.setState({ DlvryLocValue: [{ label: i18n.t('all'), value: 0 }] });
                    }
                    response.length > 0 ? this.setState({ lstDlvryLoc: resultArr }) : this.setState({ lstDlvryLoc: [] });
                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log(error);
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectRegion(val) {
        try {
            this.setState({ RegionValue: val });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectServiceComponent(val) {
        try {
            this.setState({ ServiceCompValue: val });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectDlvryLoc(val) {
        try {
            this.setState({ DlvryLocValue: val });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectBOILI(val) {
        try {
            this.setState({ BOILIValue: val });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    ClearFilter() {
        try {
            $("#bar").css("display", "none");
            $("#line").css("display", "none");
            this.setState({
                lstRegion: [], lstDlvryLoc: [], DealValue: [], RegionValue: []
                , DlvryLocValue: [], ServiceCompValue: [], BOILIValue: []
            });

            recordCount = 0;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    async DownloadExcel() {
        try {
            var DealID = this.state.DealValue.value == undefined ? 0 : this.state.DealValue.value;
            var RegionID = this.state.RegionValue.value == undefined ? 0 : this.state.RegionValue.value;
            var CountryID = 0;
            var CapabilityID = 0;
            var TowerID = this.state.TowerValue.value == undefined ? 0 : this.state.TowerValue.value;
            var IndustryID = 0;
            var SCID = this.state.ServiceCompValue.value == undefined ? 0 : this.state.ServiceCompValue.value;
            var SearchedString = "";
            let excelData = {
                "PMMProcessID": 0,
                "DealId": DealID,
                "RegionID": RegionID,
                "CountryID": CountryID,
                "CapabilityId": CapabilityID,
                "TowerID": TowerID,
                "IndustryID": IndustryID,
                "SCId": SCID,
                "SearchedString": SearchedString,
                "PermissionLevel": "3,2,1",
                "UserName": this.props.enterpriseId,
			    "GroupID": this.props.groupId,
			    "LanguageID":this.props.languageId
            }
            await request("/Common/DownloadtoExcelSubmittedAssessment", 'POST', excelData)
                .then(response => {
                    var filePath = response.Table1[0].Value;
                    window.location.href = filePath;
                })
                .catch(error => {
                    console.log(error);
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    BarChart() {
        try {
            $("#bar").css("display", "block");
            $("#line").css("display", "none");
            if (window.bar_chart && window.bar_chart !== null) {
                window.bar_chart.destroy();
            }
            Chart.defaults.global.plugins.datalabels.display = false;
            var bar_ctx = document.getElementById('bar').getContext('2d');

            window.bar_chart = new Chart(bar_ctx, {
                type: 'bar',
                data: {
                    labels: this.state.datasetNames,
                    datasets: this.state.chartData
                },
                options: {
                    legend: {
                        display: false,
                        labels: {
                            fontFamily: "Comic Sans MS"
                        }
                    },
                    title: {
                        display: true,
                        text: i18n.t('acrossDealComparision'),
                        fontSize: 15,
                        fontFamily: "Comic Sans MS",
                        fontStyle: "bold",
                        fontColor: "#f7701e"
                    },
                    scales: {
                        xAxes: [{
                            barPercentage: 0.5,
                            maxBarThickness: 10,
                            minBarLength: 2,
                            gridLines: {
                                offsetGridLines: true
                            },
                            ticks: {
                                autoSkip: false,
                                maxRotation: 80,
                                minRotation: 80
                            }
                        }],
                        yAxes: [{
                            ticks: {
                                beginAtZero: true
                            }
                        }]
                    }
                }
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    AcrossDealComparision() {
        try {
            if (this.state.DealValue == [] || this.state.DealValue.length == [0]) {
                alert(i18n.t("pleaseselectDeal"));
                return false;
            }
            if (this.state.RegionValue == [] || this.state.RegionValue.length == [0]) {
                alert(i18n.t("pleaseSelectRegion"));
                return false;
            }
            if (this.state.DlvryLocValue == [] || this.state.DlvryLocValue.length == [0]) {
                alert(i18n.t("pleaseSelectDeliveryLocation"));
                return false;
            }
            if (this.state.ServiceCompValue == [] || this.state.ServiceCompValue.length == [0]) {
                alert(i18n.t("pleaseSelectServiceComponent"));
                return false;
            }
            if (this.state.BOILIValue == [] || this.state.BOILIValue.length == [0]) {
                alert(i18n.t("pleaseSelectBOILI"));
                return false;
            }
            this.setState({ datasetNames: [], chartData: [] });
            var DealID = this.state.DealValue[0].value;
            var RegionID = this.state.RegionValue[0].value;
            var SCID = this.state.ServiceCompValue[0].value;
            var DeliveryLocationID = this.state.DlvryLocValue[0].value;
            var BOILIId = this.state.BOILIValue[0].value;

            let metricData = {
                "DealId": DealID,
                "RegionID": RegionID,
                "SCId": SCID,
                "DeliveryLocationId": DeliveryLocationID,
                "BOILIId": BOILIId,
                "Type": 1,
                "UserName": this.props.enterpriseId,
			    "GroupID": this.props.groupId,
			    "LanguageID":this.props.languageId
            }
            // if(!this.state.DealValue[0].label.toLowerCase().includes("test")){
            // window.tfo_dataevent("PMM-VSA-25", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1,this.state.DealValue[0].label)
            // }
            request("/Common/MetricComparisionforBOILI", 'POST', metricData)
                .then(response => {
                    var comparisionData = response.Table;
                    var graphData = [];

                    var color = ["#3366cc", "#dc3912", "#ff9900", "#109618", "#990099", "#0099c6", "#dd4477",
                        "#b91383", "#f4359e", "#9c5935", "#a9c413", "#2a778d", "#668d1c", "#bea413", "#0c5922",
                        "#66aa00", "#b82e2e", "#316395", "#3366cc", "#994499", "#22aa99", "#aaaa11", "#6633cc",
                        "#e67300", "#8b0707", "#651067", "#329262", "#5574a6", "#3b3eac", "#b77322", "#16d620", "#743411",
                        "#3366cc", "#dc3912", "#ff9900", "#109618", "#990099", "#0099c6", "#dd4477",
                        "#b91383", "#f4359e", "#9c5935", "#a9c413", "#2a778d", "#668d1c", "#bea413", "#0c5922",
                        "#66aa00", "#b82e2e", "#316395", "#3366cc", "#994499", "#22aa99", "#aaaa11", "#6633cc",
                        "#e67300", "#8b0707", "#651067", "#329262", "#5574a6", "#3b3eac", "#b77322", "#16d620", "#743411",
                        "#3366cc", "#dc3912", "#ff9900", "#109618", "#990099", "#0099c6", "#dd4477",
                        "#b91383", "#f4359e", "#9c5935", "#a9c413", "#2a778d", "#668d1c", "#bea413", "#0c5922",
                        "#66aa00", "#b82e2e", "#316395", "#3366cc", "#994499", "#22aa99", "#aaaa11", "#6633cc",
                        "#e67300", "#8b0707", "#651067", "#329262", "#5574a6", "#3b3eac", "#b77322", "#16d620", "#743411",
                        "#3366cc", "#dc3912", "#ff9900", "#109618", "#990099", "#0099c6", "#dd4477",
                        "#b91383", "#f4359e", "#9c5935", "#a9c413", "#2a778d", "#668d1c", "#bea413", "#0c5922",
                        "#66aa00", "#b82e2e", "#316395", "#3366cc", "#994499", "#22aa99", "#aaaa11", "#6633cc",
                        "#e67300", "#8b0707", "#651067", "#329262", "#5574a6", "#3b3eac", "#b77322", "#16d620", "#743411"];

                    var labelArr = [];
                    var valArr = [];
                    var colorArr = [];
                    var dataset = [];

                    for (var cnt = 0; cnt < comparisionData.length; cnt++) {
                        labelArr.push(comparisionData[cnt].Name);

                        valArr.push(comparisionData[cnt].AsIs);
                        colorArr.push(color[cnt]);

                    }
                    dataset.push({
                        label: i18n.t('asIsValue'),
                        backgroundColor: colorArr,
                        data: valArr
                    });

                    this.setState({ datasetNames: labelArr, chartData: dataset, chartType: 1 });
                    this.BarChart();
                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log(error);
                    $("#loader-div").hide();
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    WithInDealTrendReport() {
        try {
            if (this.state.DealValue == [] || this.state.DealValue.length == [0]) {
                alert(i18n.t("pleaseselectDeal"));
                return false;
            }
            if (this.state.BOILIValue == [] || this.state.BOILIValue.length == [0]) {
                alert(i18n.t("pleaseSelectBOILI"));
                return false;
            }
            this.setState({ chartType: 2 });
            var DealID = this.state.DealValue[0].value == undefined ? 0 : this.state.DealValue[0].value;
            var RegionID = this.state.RegionValue[0].value == undefined ? 0 : this.state.RegionValue[0].value;
            var SCID = this.state.ServiceCompValue[0].value == undefined ? 0 : this.state.ServiceCompValue[0].value;
            var DeliveryLocationID = this.state.DlvryLocValue[0].value == undefined ? 0 : this.state.DlvryLocValue[0].value;
            var BOILIId = this.state.BOILIValue[0].value;
            let metricData = {
                "DealId": DealID,
                "RegionID": RegionID,
                "SCId": SCID,
                "DeliveryLocationId": DeliveryLocationID,
                "BOILIId": BOILIId,
                "Type": 2,
                "UserName": this.props.enterpriseId,
			    "GroupID": this.props.groupId,
			    "LanguageID":this.props.languageId
            }
            // if(!this.state.DealValue[0].label.toLowerCase().includes("test")){
            // window.tfo_dataevent("PMM-VSA-24", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1,this.state.DealValue[0].label)
            // }
            request("/Common/MetricComparisionforBOILI", 'POST', metricData)
                .then(response => {
                    var trendData = response.Table;
                    var graphData = [];
                    var color = ["#3366cc", "#dc3912", "#ff9900", "#109618", "#990099", "#0099c6", "#dd4477",
                        "#b91383", "#f4359e", "#9c5935", "#a9c413", "#2a778d", "#668d1c", "#bea413", "#0c5922",
                        "#66aa00", "#b82e2e", "#316395", "#3366cc", "#994499", "#22aa99", "#aaaa11", "#6633cc",
                        "#e67300", "#8b0707", "#651067", "#329262", "#5574a6", "#3b3eac", "#b77322", "#16d620", "#743411"];

                    var labelArr = [];

                    var uniqueLabelArr = [...new Set(trendData.map(item => item.SubmittedDate))];
                    var uniqueLegendArr = [...new Set(trendData.map(item => item.Name))];

                    var newArr = [];
                    for (var cntleg = 0; cntleg < uniqueLegendArr.length; cntleg++) {
                        var valArr = [];
                        for (var cntlbl = 0; cntlbl < uniqueLabelArr.length; cntlbl++) {

                            const found = trendData.some(el => el.Name === uniqueLegendArr[cntleg] &&
                                el.SubmittedDate === uniqueLabelArr[cntlbl]);

                            var found1 = trendData.find(function (element) {
                                return element.Name === uniqueLegendArr[cntleg] &&
                                    element.SubmittedDate === uniqueLabelArr[cntlbl];
                            });

                            if (found == true) {
                                valArr.push(found1.AsIs);
                            }
                            else {
                                valArr.push(NaN);
                            }
                        }
                        newArr.push({
                            label: uniqueLegendArr[cntleg],
                            backgroundColor: "transparent",
                            borderColor: color[cntleg],
                            pointBackgroundColor: color[cntleg],
                            data: valArr,
                            showLine: true,
                            spanGaps: true,
                            pointRadius: 5,
                            pointHoverRadius: 5
                        });
                    }

                    this.setState({ datasetNames: uniqueLabelArr, chartData: newArr, chartType: 2 });
                    this.LineChart();
                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log(error);
                    $("#loader-div").hide();
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    LineChart() {
        try {
            $("#bar").css("display", "none");
            $("#line").css("display", "block");
            Chart.defaults.global.plugins.datalabels.display = false;
            var line_ctx = document.getElementById('line').getContext('2d');

            var lineChartData = {

                labels: this.state.datasetNames,
                datasets: this.state.chartData,

            }

            window.line_chart = new Chart(line_ctx, {
                type: 'line',
                data: lineChartData,
                options: {
                    elements: {
                        line: {
                            tension: 0,
                        }
                    },
                    tooltips: {
                        mode: 'point',
                    }, legend: {
                        display: true,
                        labels: {
                            fontFamily: "Comic Sans MS"
                        }
                    }, scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: true,
                            }
                        }]
                    }
                }
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    downloadPPT() {
        //For whole page export
        try {
            //window.tfo_event("PMM-D-26", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            window.scrollTo(0, 0);
            //For whole page export
            html2canvas(document.getElementById("graph-section")).then(canvas_whole => {
                let wholePag = canvas_whole.toDataURL();
                let wholePagURI = wholePag.split(':');
                var ppt = new PptxGenJS();
                var slides = ppt.addNewSlide();
                slides.addImage({ data: `${wholePagURI[1]}`, x: 0.5, y: 0.0, w: 9.0, h: 5.25 });
                ppt.save(i18n.t('bOILITrendReport'));
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    filterCallbackTypeahead(option, props) {
        try {
            if (props.selected.length) {
                return true;
            }
            return option[props.labelKey].toLowerCase().indexOf(props.text.toLowerCase()) !== -1;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    render() {

        return (
            <section className="content">
                <div className="innerpage-hd">
                    <ul className="pagecounter"  >
                        <li><span title={i18n.t("vIEWPreviousassesssment")} className="current" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Landing Page">...</span></li>
                        <li><span title={i18n.t("BOIKeyBusinessMetric")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="BOI">1</span></li>
                        <li><span title={i18n.t("lEADINGINDICATOR")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Leading Indicator ">2</span></li>
                        <li><span title={i18n.t("pMMASSESSMENT")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="PMM">3</span></li>
                        <li><span title={i18n.t("sTORYBOARDGAPASSESSMENT")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Storyboard">4</span></li>
                        <li><span title={i18n.t("pOTENTIALPROJECTS")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Potential Projects">5</span></li>
                        <li><span title={i18n.t("pROJECTPRIORITIZATION")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Project Prioritization">6</span></li>
                    </ul>

                    <Link className="menu2" to="/Home"><a type="button" className="btn btn-info btn-back">
                        <img src={backArrowIcon} />{i18n.t('bACKTOHOME')} </a>
                    </Link>
                    <Link className="menu2 pull-left" to="/PMMReports">
                        <a type="button" className="btn btn-info btn-back">
                            <img src={backArrowIcon} /> {i18n.t('BackToViewDealWiseComparision')}</a>
                    </Link>
                </div>
                <section className="bodydata">
                    <div className="bodydata-full">
                        <div className="box-plain">
                            <section className="multiple-tabs-section no-padding-lr">
                                <ul className="nav nav-tabs">
                                    <li className="nav-item">
                                        <Link className="" to="/ViewPreviousAssessmentHome">{i18n.t('submittedAssessments')}</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="" to="/ViewPreviousAssessmentDraft">{i18n.t('SavedDraftAssessments')}</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="active" to="/PMMReports">{i18n.t('reports')}</Link>
                                    </li>
                                </ul>
                            </section>
                            <h4>{i18n.t('FILTERS')}</h4>
                            <div className="box">
                                <div className="box-in viewPrj-filter">
                                    <span>
                                        <div className="form-group col-md-2">
                                            <label id="lbl_dealName"><span style={{ color: "red" }}>*</span>{i18n.t('deal_Name')}</label>
                                            <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="ddlDeal" name="form-field-name" labelKey="label" onChange={this.SelectDeal.bind(this)} options={this.state.lstDeal} selected={this.state.DealValue} filterBy={this.filterCallbackTypeahead.bind(this)} placeholder={i18n.t("selectPlace")} />
                                        </div>
                                    </span>
                                    <span>
                                        <div className="form-group col-md-2">
                                            <label id="lbl_clientBusinessRegion"><span style={{ color: "red" }}>*</span>{i18n.t('clientBusinessRegion')}</label>
                                            <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="ddlRegion" name="form-field-name" labelKey="label" onChange={this.SelectRegion.bind(this)} options={this.state.lstRegion} selected={this.state.RegionValue} filterBy={this.filterCallbackTypeahead.bind(this)} placeholder={i18n.t("selectPlace")} />
                                        </div>
                                    </span>
                                    <span>
                                        <div className="form-group col-md-2">
                                            <label id="lbl_accentureDeliveryLocation"><span style={{ color: "red" }}>*</span>{i18n.t('accentureDeliveryLocation')}</label>
                                            <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="ddlDlvryLoc" name="form-field-name" labelKey="label" onChange={this.SelectDlvryLoc.bind(this)} options={this.state.lstDlvryLoc} selected={this.state.DlvryLocValue} filterBy={this.filterCallbackTypeahead.bind(this)} placeholder={i18n.t("selectPlace")} />
                                        </div>
                                    </span>
                                    <span>
                                        <div className="form-group col-md-2">
                                            <label id="lbl_serviceComponent"><span style={{ color: "red" }}>*</span>{i18n.t('serviceComponent')}</label>
                                            <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="ddlServiceComp" name="form-field-name" labelKey="label" onChange={this.SelectServiceComponent.bind(this)} options={this.state.lstServiceComp} selected={this.state.ServiceCompValue} filterBy={this.filterCallbackTypeahead.bind(this)} placeholder={i18n.t("selectPlace")} />
                                        </div>
                                    </span>
                                    <span>
                                        <div className="form-group col-md-4">
                                            <label id="lbl_BOI/LI"><span style={{ color: "red" }}>*</span>BOI/LI</label>
                                            <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="ddlBOILI" name="form-field-name" labelKey="label" onChange={this.SelectBOILI.bind(this)} options={this.state.lstBOILI} selected={this.state.BOILIValue} filterBy={this.filterCallbackTypeahead.bind(this)} placeholder={i18n.t("selectPlace")} />
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <h4 className="btn-group-md">{i18n.t('BOILITrendR')}
                                <a type="button" className="btn btn-info pull-right" onClick={this.ClearFilter.bind(this)}>{i18n.t('clear')}</a>
                                <input id="txt_acrossDealComparision" type="button" className="btn btn-danger pull-right" value={i18n.t("acrossDealComparision")} title={i18n.t("PleaseselectatleastDealName")} onClick={this.AcrossDealComparision.bind(this)} />
                                <a type="button" className="btn btn-success pull-right" onClick={this.WithInDealTrendReport.bind(this)} >{i18n.t('withinDealtrend')}</a>
                                <a type="button" title={i18n.t("downloadgraphsinppt")} className="btn btn-info pull-right" onClick={this.downloadPPT}><img src={powerpointIcon} /> {i18n.t('downloadasPPT')}</a>
                            </h4>
                            <div className="AsmtData viewprevassmt">

                                <div id="graph-section" className="box">
                                    <div className="row" >
                                        <div className="col-sm-12" style={{ "margin": "auto" }}>
                                            <div id="customLegendDiv"></div>
                                            {this.state.chartType = 1 ? <div id="dvBar"><canvas id="bar" width="600" height="200"> </canvas></div> : null}
                                            {this.state.chartType = 2 ? <div id="dvLine"><canvas id="line" width="600" height="200"> </canvas></div> : null}
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        );
    }
}

function mapStateToProps(state){
    return{
		dropDownsValue:state.dropDownsValue,
        languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
        roleName:state.roleName,
        pageName:state.pageName,
        groupName:state.groupName,  
    }
}

BOILITrendReport = connect(mapStateToProps)(withRouter(BOILITrendReport))
export default BOILITrendReport;