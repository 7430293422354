/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import ViewDraftAssessmentGrid from './ViewDraftAssessmentGrid';
import $ from 'jquery';
import backArrowIcon from '../../images/icon-back-arrow.png';
import downloadIcon from '../../images/icon-download.png';
import request from '../../services/Service';
import loderIcon from '../../images/loader-strokes.gif';
import { Typeahead } from 'react-bootstrap-typeahead';
import i18n from 'i18next';
import langKeys from '../../Utils/LangKeys'
import { withTranslation } from 'react-i18next';
import { AgGridLocale } from '../../Utils/AgGridLocale';

var recordCount = 0;

class ViewPreviousAssessmentDraft extends React.Component {
    constructor(props) {

        super(props);
        try {
            recordCount = 0;
            this.state = {
                prevAssessmentGridData: [],
                disableCompare: true,
                showComparePopup: false,
                headerValue: "",
                lstDeal: [],
                lstIndustry: [],
                lstRegion: [],
                lstCapability: [],
                lstCountry: [],
                lstTower: [],
                lstServiceComp: [],
                lstDlvryLoc: [],
                DealValue: [],
                IndustryValue: "",
                RegionValue: [],
                OGIndustry: "",
                OG: "",
                Industry: "",
                CapabilityValue: [],
                CountryValue: { "label": i18n.t('all'), "value": "0" },
                TowerValue: [],
                ServiceCompValue: [],
                DlvryLocValue: { "label": i18n.t('all'), "value": "0" },
                eblNxtBTn: false,
                Confirm: false,
                message: "",
                DraftID: 0,
                isReport: false,
                showLoaderFlag: false
            };
            this.filterCallbackTypeahead = this.filterCallbackTypeahead.bind(this);
        }
        catch (error) {
            console.log(error);
        }
    }

    componentDidMount() {
        try {            
            //window.tfo_event("PMM-VSA-8", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            $("#loader-div").show();

            var objThis = this;
            let dealData = {
                "UserName": this.props.enterpriseId,
                "DealId": 0,
                "PermissionLevel": "3,2,1",
                "GroupID": this.props.groupId,
                "LanguageID": this.props.languageId
            }
            request("/Common/GetDealBasedOnUserAccess", 'POST', dealData)
                .then(response => {
                    response.length > 0 ? this.setState({ lstDeal: response }) : this.setState({ lstDeal: [] });
                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log(error);
                    $("#loader-div").hide();
                });

            let userData = {
                "UserName": this.props.enterpriseId,
                "GroupID": this.props.groupId,
                "LanguageID": this.props.languageId
            }
            request("/Common/GetReportAccessByUser", 'POST', userData)
                .then(response => {

                    response.Table.length > 0 ? this.setState({ isReport: response.Table[0].IsReport }) : this.setState({ isReport: "" });
                    $("#loader-div").hide();

                })
                .catch(error => {
                    console.log(error);
                    $("#loader-div").hide();
                });
        }
        catch (error) {
            console.log(error);
        }
    }

    GetAssessmentDetails() {
        try {
            if (this.state.DealValue == [] || this.state.DealValue.length == [0]) {
                alert(i18n.t('pleaseselectDeal'));
                return false;
            }

            var objThis = this;
            this.setState({ showComparePopup: false, showLoaderFlag: true });
            var DealID = this.state.DealValue.length == '0' ? 0 : this.state.DealValue[0].value;
            var RegionID = this.state.RegionValue.length == '0' ? 0 : this.state.RegionValue[0].value;
            var CountryID = this.state.CountryValue.value == [] ? 0 : this.state.CountryValue.value;
            var CapabilityID = this.state.CapabilityValue.length == '0' ? 0 : this.state.CapabilityValue[0].value;
            var TowerID = this.state.TowerValue.length == '0' ? 0 : this.state.TowerValue[0].value;
            var IndustryID = this.state.IndustryValue.value == undefined ? 0 : this.state.IndustryValue.value;
            var SCID = this.state.ServiceCompValue.length == '0' ? 0 : this.state.ServiceCompValue[0].value;
            var SearchedString = "";
            let viewAssessmentDraftData ={
                UserName:this.props.enterpriseId,
                PMMProcessDraftID:0,
                DealID:DealID ,
                RegionID:RegionID, 
                CountryID:CountryID,
                CapabilityID:CapabilityID,
                TowerID:TowerID,
                IndustryID:IndustryID,
                SCID:SCID,
                SearchedString:SearchedString,
                PermissionLevel:"3,2",
                GroupID:this.props.groupId,
                LanguageID:this.props.languageId
            }
            request("/BOI/ViewAssessmentDraftDetailsBasedOnUserAccess", 'POST', viewAssessmentDraftData)
                .then(response => {

                    response.Table == null ? recordCount = 0 : recordCount = response.Table.length;
                    objThis.setState({ prevAssessmentGridData: response.Table, showLoaderFlag: false });

                })
                .catch(error => {
                    console.log(error);
                    objThis.setState({ showLoaderFlag: false })

                });
        }
        catch (error) {
            console.log(error);
        }
    }

    SelectDeal(val) {

        try {
            $("#loader-div").show();

            this.setState({
                showComparePopup: false, lstServiceComp: [], ServiceCompValue: [], lstCapability: [],
                CapabilityValue: [], lstTower: [], TowerValue: []

            });
            if (val.length != 0) {
                this.setState({ DealValue: val,
                    prevAssessmentGridData:[] });

                let ogData = {
                    "UserName": this.props.enterpriseId,
                    "DealId": val[0].value,
                    "GroupID": this.props.groupId,
                    "LanguageID": this.props.languageId
                }
                request("/Common/GetOGBasedOnDeal", 'POST', ogData)
                    .then(response => {

                        this.setState({ OGIndustry: response[0] })
                        $("#loader-div").hide();
                    })
                    .catch(error => {
                        console.log(error);
                        $("#loader-div").hide();
                    });

                let capabilityData = {
                    "UserName": this.props.enterpriseId,
                    "ID": 0,
                    "DealId": val[0].value,
                    "DLId": 0,
                    "PermissionLevel": "3,2",
                    "GroupID": this.props.groupId,
                    "LanguageID": this.props.languageId
                }
                request("/Common/GetCapabilityBasedOnUserAccess", 'POST', capabilityData)
                    .then(response => {

                        response.length > 0 ? this.setState({ lstCapability: response }) : this.setState({ lstCapability: [] });

                    })
                    .catch(error => {
                        console.log(error);
                    });


                this.setState({
                    lstRegion: [], RegionValue: []
                });

                request("/MasterScreens/GetRegionBasedOnDeal?DealId=" + val[0].value + "&Flag=1&GroupID=" + this.props.groupId + "&LanguageID=" + this.props.languageId, 'GET')
                    .then(response => {

                        if (response.length > 0) {
                            this.setState({ RegionValue: [{ label: i18n.t('all'), value: "0" }] });
                            this.setState({ disableCompare: false });
                        }


                        this.setState({ lstRegion: response });
                    })
                    .catch(error => {
                        console.log(error);
                    });

                let dealData = {
                    "UserName": this.props.enterpriseId,
                    "DeliveryLocationId": 0,
                    "DealId": val[0].value,
                    "PermissionLevel": "3,2",
                    "GroupID": this.props.groupId,
                    "LanguageID": this.props.languageId
                }
                request("/Common/DLDropDownBasedOnUserAccess", 'POST', dealData)
                    .then(response => {

                        response.length > 0 ? this.setState({ lstDlvryLoc: response }) : this.setState({ lstDlvryLoc: [] });
                        $("#loader-div").hide();
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
            else {
                this.ClearFilter();
                $("#loader-div").hide();

            }
        }
        catch (error) {
            console.log(error);
        }
    }

    SelectIndustry(val) {

        try {
            this.setState({ IndustryValue: val });
            this.setState({ showComparePopup: false });
        }
        catch (error) {
            console.log(error);
        }
    }

    SelectRegion(val) {

        try {
            this.setState({ RegionValue: val,
                prevAssessmentGridData:[] });
            this.setState({ showComparePopup: false });
            val[0].label == i18n.t('all') ? this.setState({ disableCompare: false }) : this.setState({ disableCompare: true });

            var dealId = this.state.DealValue;
            if (val != null) {
                request("/MasterScreens/GetCountryBasedOnRegion?DealId=" + dealId.value + "&RegionId=" + val[0].value + "&GroupID=" + this.props.groupId + "&LanguageID=" + this.props.languageId, 'GET')
                    .then(response => {

                        this.setState({ lstCountry: response });
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
            else {
                this.setState({ RegionValue: [], CapabilityValue: [], TowerValue: [], ServiceCompValue: [] });
                $("#loader-div").hide();
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    SelectCapability(val) {

        try {
            $("#loader-div").show();
            this.setState({ CapabilityValue: val,
                prevAssessmentGridData:[] });
            this.setState({ showComparePopup: false });
            if (val.length != 0) {
                let towerData = {
                    "UserName": this.props.enterpriseId,
                    "TowerID": 0,
                    "DealId": this.state.DealValue[0].value,
                    "CapabilityId": val[0].value,
                    "DeliveryLocationId": 0,
                    "DLId": 0,
                    "PermissionLevel": "3,2",
                    "GroupID": this.props.groupId,
                    "LanguageID": this.props.languageId
                }
                request("/Common/GetTowerBasedOnUserAccess", 'POST', towerData)
                    .then(response => {

                        this.setState({ TowerValue: [], lstTower: response, lstServiceComp: [], ServiceCompValue: [], eblNxtBTn: false });
                        $("#loader-div").hide();
                    })
                    .catch(error => {
                        console.log(error);
                        $("#loader-div").hide();
                    });
            }
            else {
                this.setState({ CapabilityValue: [], TowerValue: [], ServiceCompValue: [] });
                $("#loader-div").hide();
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    SelectCountry(val) {

        try {

            this.setState({ CountryValue: val });
            val[0].label == i18n.t('all') && this.state.RegionValue[0].label == i18n.t('all') ? this.setState({ disableCompare: false }) : this.setState({ disableCompare: true });
            this.setState({ showComparePopup: false });
        }
        catch (error) {
            console.log(error);
        }
    }

    SelectTower(val) {

        try {

            $("#loader-div").show();
            this.setState({ TowerValue: val,
                prevAssessmentGridData:[] });
            this.setState({ showComparePopup: false });
            if (val.length != 0) {
                let scData = {
                    "UserName": this.props.enterpriseId,
                    "TowerID": val[0].value,
                    "SCId": 0,
                    "GroupID": this.props.groupId,
                    "LanguageID": this.props.languageId
                }
                request("/Common/GetSCBasedOnTower", 'POST', scData)
                    .then(response => {
                        let SCList = response != "" ? response : [];
                        this.setState({ ServiceCompValue: [], lstServiceComp: SCList });
                        $("#loader-div").hide();
                    })
                    .catch(error => {
                        console.log(error);
                        $("#loader-div").hide();
                    });
            }
            else {
                this.setState({ TowerValue: [], ServiceCompValue: [] });
                $("#loader-div").hide();
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    SelectServiceComponent(val) {

        try {
            this.setState({ ServiceCompValue: val,
            prevAssessmentGridData:[] });
            this.setState({ showComparePopup: false });
        }
        catch (error) {
            console.log(error);
        }
    }

    SelectDlvryLoc(val) {

        try {
            this.setState({ DlvryLocValue: val });
            this.setState({
                showComparePopup: false,
                lstServiceComp: [], ServiceCompValue: [], lstCapability: [],
                CapabilityValue: [], lstTower: [], TowerValue: []
            });
            let capabilityData = {
                "UserName": this.props.enterpriseId,
                "ID": 0,
                "DealId": this.state.DealValue[0].value,
                "DeliveryLocationId": val[0].value,
                "GroupID": this.props.groupId,
                "LanguageID": this.props.languageId
            }
            request("/Common/GetCapabilityBasedOnDeal", 'POST', capabilityData)
                .then(response => {

                    this.setState({ lstCapability: response })

                })
                .catch(error => {
                    console.log(error);
                });
        }
        catch (error) {
            console.log(error);
        }
    }

    ClearFilter() {
        try {
            this.setState({
                disableCompare: true, lstIndustry: [], lstRegion: [], lstCapability: []
                , lstTower: [], lstServiceComp: [], lstDlvryLoc: [], DealValue: [], RegionValue: []
                , CountryValue: { "label": i18n.t('all'), "value": "0" }, CapabilityValue: []
                , TowerValue: [], ServiceCompValue: [], prevAssessmentGridData: "", showComparePopup: false

            });

            recordCount = 0;
        }
        catch (error) {
            console.log(error);
        }
    }

    ShowComparisonPopup() {

        try {
            if (this.state.RegionValue != [] && this.state.RegionValue[0].label == i18n.t('all')) {
                if (this.state.DealValue == [] || this.state.DealValue.length == [0]) {
                    alert(i18n.t("pleaseselectDeal"));
                    return false;
                }
                if (this.state.CapabilityValue == [] || this.state.CapabilityValue.length == [0]) {
                    alert(i18n.t("pleaseSelectCapability"));
                    return false;
                }
                if (this.state.TowerValue == [] || this.state.TowerValue.length == [0]) {
                    alert(i18n.t("pleaseSelectTower"));
                    return false;
                }
                if (this.state.ServiceCompValue == [] || this.state.ServiceCompValue.length == [0]) {
                    alert(i18n.t("pleaseSelectServiceComponent"));
                    return false;
                }
            }
            var header = {
                Deal: this.state.DealValue,
                Region: this.state.RegionValue,
                DeliveryLocation: this.state.DlvryLocValue,
                Country: this.state.CountryValue,
                Capability: this.state.CapabilityValue,
                Tower: this.state.TowerValue,
                ServiceComponent: this.state.ServiceCompValue
            };
            this.setState({ headerValue: header });
            this.setState({ showComparePopup: true });
            this.props.propsSelectedAssessmentDetails(header);
            this.props.history.push("/CompareRegionGrid");
        }
        catch (error) {
            console.log(error);
        }
    }

    async DownloadExcel() {

        try {            
            //window.tfo_event("PMM-D-19", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            var DealID = this.state.DealValue.length == '0' ? 0 : this.state.DealValue[0].value;
            var RegionID = this.state.RegionValue.length == '0' ? 0 : this.state.RegionValue[0].value;
            var CountryID = this.state.CountryValue.value == [] ? 0 : this.state.CountryValue.value;
            var CapabilityID = this.state.CapabilityValue.length == '0' ? 0 : this.state.CapabilityValue[0].value;
            var TowerID = this.state.TowerValue.length == '0' ? 0 : this.state.TowerValue[0].value;
            var IndustryID = this.state.IndustryValue.value == undefined ? 0 : this.state.IndustryValue.value;
            var SCID = this.state.ServiceCompValue.length == '0' ? 0 : this.state.ServiceCompValue[0].value;
            var SearchedString = "";
            let excelData = {
                "UserName": this.props.enterpriseId,
                "PMMProcessDraftID": 0,
                "DealId": DealID,
                "RegionID": RegionID,
                "CountryID": CountryID,
                "CapabilityId": CapabilityID,
                "TowerID": TowerID,
                "IndustryID": IndustryID,
                "SCId": SCID,
                "IsLocked": 0,
                "SearchedString": SearchedString,
                "PermissionLevel": "2,3",
                "GroupID": this.props.groupId,
                "LanguageID": this.props.languageId
            }
            await request("/Common/DownloadtoExcelAssessmentByUserAccess", 'POST', excelData)
                .then(response => {
                    if (response.Table1.length == 0) {
                        alert(i18n.t('norecordsfound'));
                    }
                    else {
                        var filePath = response.Table1[0].Value;
                        window.location.href = filePath;
                    }
                })
                .catch(error => {
                    console.log(error);
                });
        }
        catch (error) {
            console.log(error);
        }
    }
    filterCallbackTypeahead(option, props) {
        try {
            if (props.selected.length) {
                return true;
            }
            return option[props.labelKey].toLowerCase().indexOf(props.text.toLowerCase()) !== -1;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    render() {


        return (
            <section className="content">
                <div className="innerpage-hd">
                    <ul className="pagecounter"  >
                        <li><span title={i18n.t("vIEWPreviousassesssment")} className="current" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Landing Page">...</span></li>
                        <li><span title={i18n.t("BOIKeyBusinessMetricVIEW")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="BOI">1</span></li>
                        <li><span title={i18n.t("LeadingIndicatorView")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Leading Indicator ">2</span></li>
                        <li><span title={i18n.t("pMMASSESSMENT")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="PMM">3</span></li>
                        <li><span title={i18n.t("sTORYBOARDGAPASSESSMENT")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Storyboard">4</span></li>
                        <li><span title={i18n.t("pOTENTIALPROJECTS")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Potential Projects">5</span></li>
                        <li><span title={i18n.t("pROJECTPRIORITIZATION")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Project Prioritization">6</span></li>
                    </ul>

                    <Link className="menu2" to="/Home"><a type="button" className="btn btn-info btn-back">
                        <img src={backArrowIcon} />{i18n.t('bACKTOHOME')}</a></Link>
                </div>
                <section className="bodydata">
                    <div className="bodydata-full">
                        <div className="box-plain">
                            <section className="multiple-tabs-section no-padding-lr">
                                <ul className="nav nav-tabs">
                                    <li className="nav-item">
                                        <Link className="" to="/ViewPreviousAssessmentHome">{i18n.t('submittedAssessments')}</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="active" to="/ViewPreviousAssessmentDraft">{i18n.t('SavedDraftAssessments')}</Link>
                                    </li>
                                    {this.state.isReport != false ? <li className="nav-item">
                                        <Link className="" to="/PMMReports">{i18n.t('reports')}</Link>
                                    </li> : null}
                                </ul>
                            </section>
                            <h4>{i18n.t('FILTERS')}</h4>
                            <div className="box">
                                <div className="box-in viewPrj-filter">
                                    <span>
                                        <div className="form-group col-md-2">
                                            <label id="lbl_dealName"><span style={{ color: "red" }}>*</span>{i18n.t('deal_Name')}</label>
                                            <Typeahead emptyLabel={i18n.t('noMatchesFound')} name="form-field-name" labelKey="label" onChange={this.SelectDeal.bind(this)} options={this.state.lstDeal} selected={this.state.DealValue} filterBy={this.filterCallbackTypeahead.bind(this)} placeholder={i18n.t("selectPlace")} />
                                        </div>
                                    </span>
                                    <span>
                                        <div className="form-group col-md-2">
                                            <label id="lbl_clientBusinessRegion">{i18n.t('clientBusinessRegion')}</label>
                                            <Typeahead emptyLabel={i18n.t('noMatchesFound')} name="form-field-name" labelKey="label" onChange={this.SelectRegion.bind(this)} options={this.state.lstRegion} selected={this.state.RegionValue} filterBy={this.filterCallbackTypeahead.bind(this)} placeholder={i18n.t("selectPlace")} />
                                        </div>
                                    </span>
                                    <span>
                                        <div className="form-group col-md-2">
                                            <label id="lbl_capability">{i18n.t('capability')}</label>
                                            <Typeahead emptyLabel={i18n.t('noMatchesFound')} name="form-field-name" labelKey="label" onChange={this.SelectCapability.bind(this)} options={this.state.lstCapability} selected={this.state.CapabilityValue} filterBy={this.filterCallbackTypeahead.bind(this)} placeholder={i18n.t("selectPlace")} />
                                        </div>
                                    </span>
                                    <span>
                                        <div className="form-group col-md-2">
                                            <label id="lbl_tower">{i18n.t('tower')}</label>
                                            <Typeahead emptyLabel={i18n.t('noMatchesFound')} name="form-field-name" labelKey="label" onChange={this.SelectTower.bind(this)} options={this.state.lstTower} selected={this.state.TowerValue} filterBy={this.filterCallbackTypeahead.bind(this)} placeholder={i18n.t("selectPlace")} />
                                        </div>
                                    </span>
                                    <span>
                                        <div className="form-group col-md-2">
                                            <label id="lbl_serviceComponent">{i18n.t('serviceComponent')}</label>
                                            <Typeahead emptyLabel={i18n.t('noMatchesFound')} name="form-field-name" labelKey="label" onChange={this.SelectServiceComponent.bind(this)} options={this.state.lstServiceComp} selected={this.state.ServiceCompValue} filterBy={this.filterCallbackTypeahead.bind(this)} placeholder={i18n.t("selectPlace")} />
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <h4 className="btn-group-md">{i18n.t('viewSavedDRAFTAssessment')}
                                <span className="badge">{recordCount}</span>
                                <div className="btn-group btn-group-pmmasmt">
                                    <button id="btn_downloadListOfAllSavedAssessmentsBasedOnYourAccessRights" type="button" className="btn btn-default btn-downld" onClick={this.DownloadExcel.bind(this)} title={i18n.t("DownloadlistofallsavedassessmentsPlease")}>
                                        <img src={downloadIcon} />
                                    </button>
                                </div>
                                <a type="button" className="btn btn-info pull-right" onClick={this.ClearFilter.bind(this)}>{i18n.t('clear')}</a>
                                <input id="txt_search" type="button" className="btn btn-danger pull-right" value={i18n.t("search")} title={i18n.t("PleaseselectatleastDealName")} onClick={this.GetAssessmentDetails.bind(this)} />
                            </h4>
                            <div className="AsmtData viewprevassmt">
                                {
                                    this.state.showLoaderFlag == true ?
                                        <div>
                                            <center><img src={loderIcon} /></center>
                                        </div>
                                        :
                                        <ViewDraftAssessmentGrid propsprevAssessmentGridData={this.state.prevAssessmentGridData} propsSelectedAssessmentDetails={this.props.propsSelectedAssessmentDetails} />
                                }
                            </div>
                        </div>
                    </div>
                </section>
            </section>
        );
    }
}
function mapStateToProps(state){
    return{
        languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
        groupName: state.groupName
    }
}

ViewPreviousAssessmentDraft = connect(mapStateToProps)(withRouter(ViewPreviousAssessmentDraft))
export default ViewPreviousAssessmentDraft;