/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React, { Component } from 'react';
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import { Modal } from 'react-bootstrap';
import { withRouter } from 'react-router';
import $ from 'jquery';
import downloadIcon from '../src/images/icon-download.png';
import request from './services/Service';
import { Typeahead } from 'react-bootstrap-typeahead';
import i18n from 'i18next';
import langKeys from './Utils/LangKeys'
import { withTranslation } from 'react-i18next';
import { AgGridLocale } from './Utils/AgGridLocale';
import {connect} from 'react-redux';

class ActiveServiceComponent extends Component {
    constructor(props) {
        super(props);
        try {
            this.state = {
                show: this.props.show, headerName: "", innerContent: "",
                ServiceComponentGridData: [], lstCapability: [], CapabilityValue: [],
                lstTower: [], TowerValue: []
            }
            this.closePopUp = this.closePopUp.bind(this);
            this.SelectCapability = this.SelectCapability.bind(this);
            this.serviceComponentDetails = this.serviceComponentDetails.bind(this);
            this.SelectTower = this.SelectTower.bind(this);
            this.clear = this.clear.bind(this);
            this.filterCallbackTypeahead = this.filterCallbackTypeahead.bind(this);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillReceiveProps(props) {

        try {
            this.setState({
                show: this.props.show
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {
        try {
            //window.tfo_event("PMM-Home-3", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            $("#loader-div").show();
            //$("#loader-div").show();
            this.setState({
                show: this.props.show
            });
            let dropdownData = {
               "UserName":this.props.enterpriseId,
               "GroupID":this.props.groupId,
               "LanguageID":this.props.languageId,
                "ID": 0,
                "DealId": 0,
            }
            request("/Common/DropDownsValue", 'POST', dropdownData)
                .then(response => {

                    this.setState({ lstCapability: response.Capability, lstTower: response.Tower });

                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
            let scData = {
                "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
            }
            request("/Common/GetActiveServiceComponentDetails", 'POST', scData)
                .then(response => {
                    this.setState({ ServiceComponentGridData: response });
                })
                .catch(error => {
                    console.log("Error Encountered");
                });

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectCapability(val) {

        try {
            $("#loader-div").show();;
            this.setState({
                CapabilityValue: val,
                TowerValue: []
            });
            let towerData = {
                "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                "TowerID": 0,
                "DealId": 0,
                "CapabilityId": val[0].value,
                "DeliveryLocationId": 0,
            }
            request("/Common/GetTowerBasedOnCapability", 'POST', towerData)
                .then(response => {
                    let towerList = response != null ? response : [];
                    this.setState({ TowerValue: [], lstTower: towerList });
                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log("Error Encountered");
                });

        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectTower(val) {

        try {
            this.setState({ TowerValue: val });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    closePopUp(eve) {
        try {
            this.setState({
                show: false
            });
            let targetText = eve.target.value;
            this.setState({
                show: false
            });
            this.props.closeCallback();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    clear() {
        try {
            this.setState({ CapabilityValue: [], TowerValue: [] });
            let scData = {
                "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                "CapabilityId": 0,
                "TowerID": 0,
            }
            request("/Common/GetActiveServiceComponentDetails", 'POST', scData)
                .then(res => {
                    this.setState({ ServiceComponentGridData: res });
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
            let dropdownData = {
                "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                "ID": 0,
                "DealId": 0,
            }
            request("/Common/DropDownsValue", 'POST', dropdownData)
                .then(response => {

                    this.setState({ lstTower: response.Tower });

                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onGridReady(params) {
        try {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
            params.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    async serviceComponentDetails() {

        try {
            var CapabilityID = this.state.CapabilityValue != [] ? this.state.CapabilityValue[0].value : 0;
            var TowerId = this.state.TowerValue != [] ? this.state.TowerValue[0].value : 0;
            let scData = {
                "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                "CapabilityId": CapabilityID,
                "TowerID": TowerId,
            }
            await request("/Common/GetActiveServiceComponentDetails", 'POST', scData)
                .then(res => {
                    this.setState({ ServiceComponentGridData: res });
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    async DownloadExcel() {

        try {
            //window.tfo_event("PMM-D-27", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            var CapabilityID = this.state.CapabilityValue != [] ? this.state.CapabilityValue.value : 0;
            var TowerId = this.state.TowerValue != [] ? this.state.TowerValue.value : 0;
            let serviceData = {
                "UserName":this.props.enterpriseId,
                "GroupID":this.props.groupId,
                "LanguageID":this.props.languageId,
                "CapabilityId": CapabilityID,
                "TowerID": TowerId,
            }
            await request("/Common/DownloadtoExcelActiveServiceComponent", 'POST', serviceData)
                .then(response => {
                    var filePath = response.Table1[0].Value;
                    window.location.href = filePath;
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    filterCallbackTypeahead(option, props) {
        try {
            if (props.selected.length) {
                return true;
            }
            return option[props.labelKey].toLowerCase().indexOf(props.text.toLowerCase()) !== -1;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    render() {

        var columnDefs = [];
        columnDefs = [
            { headerName: 'SCID', field: 'ServiceComponentId', hide: true, suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('capabilityName'), field: 'CapabilityName', tooltipField: 'TowerName', suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('towerName'), field: 'TowerName', tooltipField: 'TowerName', suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('serviceComponentName'), field: 'ServiceComponentName', tooltipField: 'ServiceComponentName', suppressMovable: true, suppressResize: true, },

            { headerName: i18n.t('towerId'), field: 'TowerId', hide: true, suppressMovable: true, suppressResize: true, },
            { headerName: i18n.t('capabilityId'), field: 'CapabilityID', hide: true, suppressMovable: true, suppressResize: true, },


        ];

        var ServiceComponentGridData = this.state.ServiceComponentGridData;

        var ServiceComponentDataGrid = (
            <div style={{ height: '350px' }} className="ag-theme-material">
                <AgGridReact ref="sc_grid"

                    paginationPageSize="10"
                    enableColResize="true"
                    columnDefs={columnDefs}
                    rowData={this.state.ServiceComponentGridData}
                    onGridReady={this.onGridReady.bind(this)}
                    rowmodeltype="pagination"
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate='<span class="ag-overlay-loading-center">No Data To Display</span>' />
            </div>
        )
        return (
            <div>
                <Modal className="modal-dialog-sc" show={this.state.show} onHide={this.closePopUp} backdrop="static" keyboard={false}>
                    <Modal.Header closeButton>
                        <h5 id="activeServiceComponents"><b>{i18n.t('activeServiceComponents')}</b></h5>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="row">
                            <div className="col-lg-4 form-horizontal">
                                <div className="form-group">
                                    <label className="control-label col-sm-3" >{i18n.t('capability')}:</label>
                                    <div className="col-sm-8">
                                        <Typeahead emptyLabel={i18n.t('noMatchesFound')} name="form-field-name" labelKey="label" onChange={this.SelectCapability.bind(this)} options={this.state.lstCapability} selected={this.state.CapabilityValue} placeholder={i18n.t('selectPlace')} filterBy={this.filterCallbackTypeahead.bind(this)} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 form-horizontal">
                                <div className="form-group">
                                    <label className="control-label col-sm-3" >{i18n.t('tower')}:</label>
                                    <div className="col-sm-8">
                                        <Typeahead emptyLabel={i18n.t('noMatchesFound')} name="form-field-name" labelKey="label" onChange={this.SelectTower.bind(this)} options={this.state.lstTower} selected={this.state.TowerValue} placeholder={i18n.t('selectPlace')} filterBy={this.filterCallbackTypeahead.bind(this)} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-3 form-horizontal">
                                <div className="form-group">
                                    <a type="button" className="btn btn-info common-btn pull-right" onClick={this.clear} >{i18n.t('clear')}</a>
                                    <input type="button" className="btn btn-danger common-btn pull-right" value={i18n.t("search")} onClick={this.serviceComponentDetails} />

                                </div>
                            </div>
                        </div>
                        <h4>
                            <div className="btn-group btn-group-pmmasmt">
                                <button type="button" className="btn btn-default btn-downld" onClick={this.DownloadExcel.bind(this)} title={i18n.t("downloadlistofallactiveSC")}>
                                    <img src={downloadIcon} />
                                </button>
                            </div>
                        </h4>
                        <div className="box-plain pmm-asmt-tbl BOI-div">
                            {ServiceComponentDataGrid}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <input type="button" className="btn btn-info common-btn pull-right" value={i18n.t("close")} onClick={this.closePopUp} />
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}
function mapStateToProps(state){
    
    return{
        languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
        roleName:state.roleName,
        pageName:state.pageName,
        groupName:state.groupName
    }
}
ActiveServiceComponent =connect(mapStateToProps)( withTranslation()(ActiveServiceComponent));
export default withRouter(ActiveServiceComponent)
