/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import ConfirmationBox from './Confirmation';
import UpdateOMIDAndFTEs from './UpdateOMIDAndFTEs';
import UpdateOMIDAndFTEsConfirm from './UpdateOMIDAndFTEsConfirm';
import $ from 'jquery';
import backArrowIcon from '../../images/icon-back-arrow.png';
import downloadIcon from '../../images/icon-download.png';
import viewIcon from '../../images/icon-view.png';
import request from '../../services/Service';
import { Typeahead } from 'react-bootstrap-typeahead';
import i18n from 'i18next';
import langKeys from '../../Utils/LangKeys'
import { withTranslation } from 'react-i18next';
import { AgGridLocale } from '../../Utils/AgGridLocale';

var recordCount = 0;

class CreateNewAssessment extends React.Component {
    constructor(props) {
        super(props);
        try {
            this.state = {
                selectedDetails: [], AssessmentGridData: [],
                lstDeal: [], lstIndustry: [], lstRegion: [], lstCapability: [],
                lstCountry: [], lstTower: [], lstServiceComp: [], lstDlvryLoc: [],
                DealValue: [], IndustryValue: "", RegionValue: [], OGIndustry: "", OG: "", Industry: "",
                CapabilityValue: [], CountryValue: { "label": i18n.t('all'), "value": "0" }, TowerValue: [], ServiceCompValue: [], DlvryLocValue: [],
                eblNxtBTn: false, Confirm: false, message: "", DraftID: 0, quickFilterText: null,
                IsLockedStatus: "", lstOMID: [], OMIDValue: [], OMIDFtes: false, OMIDFtesConfirm: false, selectedRowDetails: [], lstMMSID: [],
                MMSID: []
            };
            this.filterCallbackTypeahead = this.filterCallbackTypeahead.bind(this);
            this.DownloadExcel = this.DownloadExcel.bind(this);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    CreateEditDetails() {
        try {
            let eDiv = document.createElement("div");
            eDiv.innerHTML = `<span><img src=${viewIcon} /></span>`;
            eDiv.className = "col-md-12";
            eDiv.width = "100%";
            return eDiv;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {
        try {
            var objThis = this;
            $("#loader-div").show();            
            //window.tfo_event("PMM-TPA-1", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            let dealData = {
                "UserName":this.props.enterpriseId,
                "DealId": 0,
                "PermissionLevel": 3,
                "GroupID":this.props.groupId,
                "LanguageID": this.props.languageId
            }
            request("/Common/GetDealBasedOnUserAccess", 'POST', dealData)
                .then(response => {
                    response.length >= 0 ? this.setState({ lstDeal: response }) : this.setState({ lstDeal: [] });
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });

            sessionStorage.setItem("PMMProcessDraftID", "");
            var SearchedString = "";
     
        }
        catch (error) {
            console.log("Error Encountered");
        }
        finally {
            $("#loader-div").hide();
        }
    }

    componentWillUnmount() {

        try {
            window.removeEventListener("beforeunload", this.unloadEvent);
            window.removeEventListener('resize', this.reSizeGrid, true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectDeal(val) {
        try {
            this.setState({
                Confirm: false, OMIDFtes: false, OMIDFtesConfirm: false, lstServiceComp: [], ServiceCompValue: [], lstCapability: [],
                CapabilityValue: [], lstTower: [], TowerValue: [], CountryValue: "", DlvryLocValue: [],
                lstOMID: [], OMIDValue: [], MMSID: [], lstMMSID: []
            });
            if (val.length == 0) {
                this.setState({ DealValue: val, eblNxtBTn: false, });
            }
            else {

                this.setState(prevState => { return { eblNxtBTn: this.checkAllSelected(prevState.DealValue == "" || prevState.DealValue == null ? 1 : 0) } });
                this.setState({ DealValue: val });
            }


            let ogData = {
                "UserName": this.props.enterpriseId,
                "DealId": val[0].value,
                "GroupID":this.props.groupId,
                "LanguageID": this.props.languageId
            }
            request("/Common/GetOGBasedOnDeal", 'POST', ogData)
                .then(response => {
                    this.setState({ OGIndustry: response[0] })
                    if (response[0].OGName == null) {
                        this.setState({ eblNxtBTn: false })
                    }
                    else {
                        this.setState(prevState => { return { eblNxtBTn: this.checkAllSelected(prevState.DealValue == "" || prevState.DealValue == null ? 1 : 0) } });
                    }
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
            let dealData = {
                "UserName": this.props.enterpriseId,
                "DealID": val[0].value,
                "GroupID": this.props.groupId,
                "LanguageID":this.props.languageId
            }
            request("/Common/GetMMSIDBasedOnDeal", 'POST', dealData)
                .then(response => {
                    response != null ? this.setState({ lstMMSID: response }) : this.setState({ lstMMSID: [] });
                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log("Error Encountered");
                });

            this.setState({ lstRegion: [], lstCountry: [], RegionValue: [] });

            request("/MasterScreens/GetRegionBasedOnDeal?DealID=" + val[0].value + "&Flag=2&GroupID=" + this.props.groupId + "&LanguageID=" + this.props.languageId, 'GET')
                .then(response => {
                    this.setState({ lstRegion: response });
                })
                .catch(error => {
                    console.log("Error Encountered");
                });


            let dropdownData = {
                "UserName": this.props.enterpriseId,
                "DeliveryLocationId": 0,
                "DealId": val[0].value,
                "PermissionLevel": "3",
                "GroupID": this.props.groupId,
                "LanguageID":this.props.languageId
            }
            request("/Common/DLDropDownBasedOnUserAccess", 'POST', dropdownData)
                .then(response => {

                    response.length > 0 ? this.setState({ lstDlvryLoc: response }) : this.setState({ lstDlvryLoc: [] });
                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log("Error Encountered");
                });

            let omidData = {
                "UserName": this.props.enterpriseId,
                "ID": 0,
                "DealID": val[0].value,
                "GroupID": this.props.groupId,
                "LanguageID":this.props.languageId
            }
            request("/Common/GetOMIDBasedOnDeal", 'POST', omidData)
                .then(response => {

                    response != null ? this.setState({ lstOMID: response }) : this.setState({ lstOMID: [] });
                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectIndustry(val) {
        try {
            this.setState({ Confirm: false, OMIDFtes: false, OMIDFtesConfirm: false });
            if (val == null) {
                this.setState({ IndustryValue: val, eblNxtBTn: false, });
            } else {
                this.setState(prevState => { return { eblNxtBTn: this.checkAllSelected(prevState.IndustryValue == "" || prevState.IndustryValue == null ? 1 : 0) } });
                this.setState({ IndustryValue: val });
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectRegion(val) {
        try {

            this.setState({ Confirm: false, OMIDFtes: false, OMIDFtesConfirm: false });
            if (val == null) {
                this.setState({ RegionValue: val, eblNxtBTn: false, });
            } else {
                this.setState(prevState => { return { eblNxtBTn: this.checkAllSelected(prevState.RegionValue == "" || prevState.RegionValue == null ? 1 : 0) } });
                this.setState({ RegionValue: val });
            }
            var dealId = this.state.DealValue;

            request("/MasterScreens/GetCountryBasedOnRegion?DealId=" + dealId.value + "&RegionId=" + val[0].value + "&GroupID=" + this.props.groupId + "&LanguageID=" + this.props.languageId, 'GET')
            .then(response => {

                    this.setState({ lstCountry: response });
                    response.length > 0 ? this.setState({ CountryValue: { "label": i18n.t('all'), "value": "0" } }) : this.setState({ CountryValue: "" })
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectCapability(val) {
        try {
            this.setState({ Confirm: false, OMIDFtes: false, OMIDFtesConfirm: false });
            if (val == null) {
                this.setState({ CapabilityValue: val, eblNxtBTn: false, });
            } else {
                this.setState(prevState => { return { eblNxtBTn: this.checkAllSelected(prevState.CapabilityValue == "" || prevState.CapabilityValue == null ? 1 : 0) } });
                this.setState({ CapabilityValue: val });
            }


            let towerData = {
                "UserName": this.props.enterpriseId,
                "TowerID": 0,
                "DealId": this.state.DealValue[0].value,
                "CapabilityId": val[0].value,
                "DeliveryLocationId": 0,
                "DLId": this.state.DlvryLocValue[0].value,
                "PermissionLevel": "3",
                "GroupID": this.props.groupId,
                "LanguageID":this.props.languageId
            }
            request("/Common/GetTowerBasedOnUserAccess", 'POST', towerData)
                .then(response => {
                    this.setState({ TowerValue: [], lstTower: response, lstServiceComp: [], ServiceCompValue: [], eblNxtBTn: false });
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectCountry(val) {
        try {
            this.setState({ Confirm: false, OMIDFtes: false, OMIDFtesConfirm: false });
            if (val == null) {
                this.setState({ CountryValue: val, eblNxtBTn: false, });
            } else {
                this.setState(prevState => { return { eblNxtBTn: this.checkAllSelected(prevState.CountryValue == "" || prevState.CountryValue == null ? 1 : 0) } });
                this.setState({ CountryValue: val });
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectTower(val) {
        try {

            this.setState({ Confirm: false, OMIDFtes: false, OMIDFtesConfirm: false });

            if (val == null) {
                this.setState({ TowerValue: val, eblNxtBTn: false, });
            } else {
                this.setState(prevState => { return { eblNxtBTn: this.checkAllSelected(prevState.TowerValue == "" || prevState.TowerValue == null ? 1 : 0) } });
                this.setState({ TowerValue: val });
            }
            let scData = {
                "UserName": this.props.enterpriseId,
                "TowerID": val[0].value,
                "SCId": 0,
                "GroupID": this.props.groupId,
                "LanguageID":this.props.languageId
            }
            request("/Common/GetSCBasedOnTower", 'POST', scData)
                .then(response => {
                    let SCList = response != "" ? response : [];
                    this.setState({ ServiceCompValue: [], lstServiceComp: SCList, eblNxtBTn: false });
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectServiceComponent(val) {
        try {
            this.setState({ Confirm: false, OMIDFtes: false, OMIDFtesConfirm: false });
            if (val == null) {
                this.setState({ ServiceCompValue: val, eblNxtBTn: false, });
            } else {
                this.setState(prevState => { return { eblNxtBTn: this.checkAllSelected(prevState.ServiceCompValue == "" || prevState.ServiceCompValue == null ? 1 : 0) } });
                this.setState({ ServiceCompValue: val });
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }


    SelectDlvryLoc(val) {
        try {
            this.setState({
                Confirm: false, OMIDFtes: false, OMIDFtesConfirm: false, lstServiceComp: [], ServiceCompValue: [], lstCapability: [],
                CapabilityValue: [], lstTower: [], TowerValue: []
            });
            if (val == null) {
                this.setState({ DlvryLocValue: val, eblNxtBTn: false, });
            } else {
                this.setState(prevState => { return { eblNxtBTn: this.checkAllSelected(prevState.DlvryLocValue == "" || prevState.DlvryLocValue == null ? 1 : 0) } });
                this.setState({ DlvryLocValue: val });
            }

            let capabilityData = {
                "UserName": this.props.enterpriseId,
                "ID": 0,
                "DealId": this.state.DealValue[0].value,
                "DLId": val[0].value,
                "PermissionLevel": 3,
                "GroupID": this.props.groupId,
                "LanguageID":this.props.languageId
            }
            request("/Common/GetCapabilityBasedOnUserAccess", 'POST', capabilityData)
                .then(response => {
                    response.length > 0 ? this.setState({ lstCapability: response }) : this.setState({ lstCapability: [] });
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectOMID(val) {
        try {
            if (val == null || val == '') {
                this.setState({ OMIDValue: val, eblNxtBTn: false, Confirm: false, OMIDFtes: false, OMIDFtesConfirm: false });
            } else {
                this.setState(prevState => { return { eblNxtBTn: this.checkAllSelected(prevState.OMIDValue == [] || prevState.OMIDValue == null ? 1 : 0) } });
                this.setState({
                    OMIDValue: val,
                    Confirm: false, OMIDFtes: false, OMIDFtesConfirm: false
                });
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectMMSID(val) {
        try {
            if (val == null) {
                this.setState({ MMSID: val, eblNxtBTn: false, Confirm: false, OMIDFtes: false, OMIDFtesConfirm: false });
            } else {
                this.setState(prevState => { return { eblNxtBTn: this.checkAllSelected(prevState.MMSID == [] || prevState.MMSID == null ? 1 : 0) } });
                this.setState({
                    MMSID: val,
                    Confirm: false, OMIDFtes: false, OMIDFtesConfirm: false
                });
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    handleFTEsChange(val) {
        try {
            this.setState({ FTEs: val.currentTarget.value, Confirm: false, OMIDFtes: false, OMIDFtesConfirm: false });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    checkAllSelected(prevValue) {
        try {
            this.setState({ Confirm: false, OMIDFtes: false, OMIDFtesConfirm: false });
            let stateCount = 0;
            let mandateValues = prevValue;
            for (var ctrls in this.refs) {
                if (ctrls != "Grd_ViewAssess") {
                    if (this.refs[ctrls].props != undefined) {
                        if (this.refs[ctrls].props.selected[0] != undefined && this.refs[ctrls].props.selected[0].value != "" && this.refs[ctrls].props.selected[0].value != undefined) {
                            mandateValues += 1
                        }
                    }
                    else {
                        if (this.refs[ctrls].value != "" && this.refs[ctrls].value != undefined || ctrls == "idIndustry" || ctrls == "idFTEs" || ctrls == "idOG") {
                            mandateValues += 1
                        }
                    }

                    stateCount += 1
                }
            }
            let result = mandateValues == stateCount ? true : false;
            return result;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    ValidateMandateFields(reqFrm, DraftID) {
        try {
            let AssesmentMasterDetails = {
                DealId: this.state.DealValue[0].value,
                RegionId: this.state.RegionValue[0].value,
                CountryId: this.state.CountryValue.value,
                OGName: this.state.OGIndustry.OGName,
                IndustryName: this.state.OGIndustry.IndustryName,
                ServiceComponentId: this.state.ServiceCompValue[0].value,
                DeliveryLocationId: this.state.DlvryLocValue[0].value,
                LoggedInUserId:this.props.enterpriseId,
                PMMProcessDraftID: DraftID,
                FlagId: 1,
                OMID: this.state.OMIDValue.length != 0 ? this.state.OMIDValue[0].value : 0,
                FTECount: this.state.FTEs,
                MMSID: this.state.MMSID.length != 0 ? this.state.MMSID[0].label : "0",
                GroupID: this.props.groupId,
                LanguageID:this.props.languageId
            };
            let selectedDetails = {
                DealName: this.state.DealValue[0],
                Region: this.state.RegionValue[0],
                Country: this.state.CountryValue,
                OGIndustry: this.state.OGIndustry,
                Capability: this.state.CapabilityValue[0],
                Tower: this.state.TowerValue[0],
                ServiceComponent: this.state.ServiceCompValue[0],
                DeliveryLocationId: this.state.DlvryLocValue[0],
                PMMProcessDraftID: DraftID,
                AsIs4: 0,
                NoIndProj: 0,
                OMID: this.state.OMIDValue.length != 0 ? this.state.OMIDValue[0].value : 0,
                FTECount: this.state.FTEs,
                MMSID: this.state.MMSID.length != 0 ? this.state.MMSID[0].label : "0",
                GroupID: this.props.groupId,
                LanguageID:this.props.languageId
            };

            let reqSrc = reqFrm.target.innerHTML;
            var objThis = this;
            request("/Common/SaveNewAssessment", 'POST', AssesmentMasterDetails)
                .then(res => {
                    if (res.outputmessage == i18n.t('success')) {
                        selectedDetails.PMMProcessDraftID = res.ScopeIDGenrated;

                        this.setState({ message: res.outputmessage, DraftID: res.ScopeIDGenrated, reqSrc: reqSrc, data: AssesmentMasterDetails, selectedDetails: selectedDetails, IsLockedStatus: res.Outputcode });

                        this.props.propsPMMConfigDetails(selectedDetails);
                        objThis.props.history.push("/BOI");

                        $("#loader-div").hide();


                    }
                    else {
                        selectedDetails.PMMProcessDraftID = res.ScopeIDGenrated;
                        this.setState({ IsLockedStatus: res.Outputcode });
                        this.setState({ Confirm: true, message: res.outputmessage, DraftID: res.ScopeIDGenrated, reqSrc, data: AssesmentMasterDetails, selectedDetails: selectedDetails });
                    }
                    this.props.propsPMMConfigDetails(selectedDetails);
                    $("#loader-div").hide();
                },
                )

                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });
            $("#loader-div").show();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SaveNewAssessment = (event) => {
        try {
            if (this.refs.idFTEs.value == "" || this.refs.idFTEs.value == "0") {
                alert(i18n.t("pleaseenterFTEsvalue"));
                return false;
            }
            else if (parseFloat(this.refs.idFTEs.value) < 0) {
                alert(i18n.t("pleaseenterFTEsvaluegreater"));
                return false;
            }
            else if (this.refs.idFTEs.value != "") {
                let ftesNumStatus = true;
                if(!isNaN(parseFloat(this.refs.idFTEs.value)) && isFinite(this.refs.idFTEs.value)){
                    ftesNumStatus = null;
                }
                else{
                    ftesNumStatus = false;
                }
                if (ftesNumStatus == false) {
                    alert(i18n.t("pleaseenternumericvalue"));
                    return false;
                }
            }


            if ((this.state.DealValue != [] && this.state.DealValue[0].label != [] && this.state.DealValue[0].label != i18n.t('select')) &&
                (this.state.RegionValue != [] && this.state.RegionValue[0].label != [] && this.state.RegionValue[0].label != i18n.t('select')) &&
                (this.state.CountryValue != "" && this.state.CountryValue.label != "" && this.state.CountryValue.label != i18n.t('select')) &&
                (this.state.CapabilityValue != [] && this.state.CapabilityValue[0].label != [] && this.state.CapabilityValue[0].label != i18n.t('select')) &&
                (this.state.TowerValue != [] && this.state.TowerValue[0].label != [] && this.state.TowerValue[0].label != i18n.t('select')) &&
                (this.state.ServiceCompValue != [] && this.state.ServiceCompValue[0].label != [] && this.state.ServiceCompValue[0].label != i18n.t('select')) &&
                (this.state.DlvryLocValue != [] && this.state.DlvryLocValue[0].label != [] && this.state.DlvryLocValue[0].label != i18n.t('select'))
                && (this.state.OGIndustry.OGName != "")) {
                this.ValidateMandateFields(event, 0)
            }
            else {
                alert(i18n.t("pleasefillallthemandatefields"))
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    };

    reSizeGrid() {
        this.refs.Grd_ViewAssess.gridOptions.api.sizeColumnsToFit();
    }

    gridReadyFun=(eve) => {

        $("#loader-div").hide();
        
        let objThis = this;
        var SearchedString = "";
            let data = {
                PMMProcessDraftID: 0,
                DealID: 0,
                RegionID: 0,
                CountryID: 0,
                CapabilityID: 0,
                TowerID: 0,
                IndustryID: 0,
                SCID: 0,
                SearchedString: SearchedString,
                PermissionLevel: 3,
                GroupID: this.props.groupId,
                LanguageID: this.props.languageId
            }

            eve.api.showLoadingOverlay();

            request("/BOI/ViewAssessmentDraftDetailsBasedOnUserAccess", 'POST', data)
                .then(response => {

                    if (response.Table.length != 0) {

                        eve.api.hideOverlay();
                        response.Table == null ? recordCount = 0 : recordCount = response.Table.length;
                        objThis.setState({ AssessmentGridData: response.Table });
                        
                    }
                    else{
                        eve.api.showNoRowsOverlay();
                    }

                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });

        eve.api.sizeColumnsToFit();
    }

    async showUpdatedRecord() {
        try {
            var SearchedString = "";
            let data ={
                UserName:this.props.enterpriseId,
                PMMProcessDraftID:0,
                DealID:0,
                RegionID:0,
                CountryID:0,
                CapabilityID:0,
                TowerID:0,
                IndustryID:0,
                SCID:0,
                SearchedString:SearchedString ,
                PermissionLevel:3,
                GroupID: this.props.groupId,
                LanguageID:this.props.languageId
            }
            await request("/BOI/ViewAssessmentDraftDetailsBasedOnUserAccess", 'POST', data)
                .then(response => {
                    response.Table == null ? recordCount = 0 : recordCount = response.Table.length;
                    this.setState({ AssessmentGridData: response.Table, OMIDFtes: false, OMIDFtesConfirm: false });
                })
                .catch(error => {
                    console.log("Error Encountered");
                    $("#loader-div").hide();
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onCellClicked(e) {
        debugger;
        try {
            if (e.colDef.headerName == i18n.t("startAssessment")) {
                this.setState({ OMIDFtes: false, OMIDFtesConfirm: false });
                var objThis = this;

                if ((this.props.groupId == "1") && (e.data.OMID == null || e.data.FTECount == null || e.data.OMID == 0)) {
                    this.setState({ Confirm: false, OMIDFtesConfirm: true });
                    return false;
                }
                if ((this.props.groupId != "1") && (e.data.MMSID == null || e.data.FTECount == null || e.data.MMSID == 0)) {
                    this.setState({ Confirm: false, OMIDFtesConfirm: true });
                    return false;
                }
                let AssesmentMasterDetails = {
                    DealId: e.data.DealID,
                    RegionId: e.data.RegionID,
                    CountryId: e.data.CountryID,
                    OGName: e.data.OGName,
                    IndustryName: e.data.IndustryName,
                    ServiceComponentId: e.data.ServiceComponentID,
                    DeliveryLocationId: e.data.DeliveryLocationId,
                    LoggedInUserId:this.props.enterpriseId,
                    PMMProcessDraftID: 0,
                    FlagId: 1,
                    OMID: e.data.OMID ? e.data.OMID : 0,
                    FTECount: e.data.FTECount,
                    MMSID: e.data.MMSID ? e.data.MMSID : "0",
                    GroupID: this.props.groupId,
                    LanguageID:this.props.languageId
                };

                let selectedDetails = {
                    DealName: { "label": e.data.DealName, "value": e.data.DealID },
                    Region: { "label": e.data.RegionName, "value": e.data.RegionID },
                    Country: { "label": e.data.CountryName, "value": e.data.CountryID },
                    OGIndustry: { "OGID": e.data.OGID, "OGName": e.data.OGName, "IndustryId": e.data.IndustryId, "IndustryName": e.data.IndustryName },
                    Capability: { "label": e.data.CapabilityName, "value": e.data.CapabilityID },
                    Tower: { "label": e.data.TowerName, "value": e.data.TowerID },
                    ServiceComponent: { "label": e.data.SCName, "value": e.data.ServiceComponentID },
                    DeliveryLocationId: e.data.DeliveryLocationId,
                    PMMProcessDraftID: e.data.PMMProcessDraftID,
                    OMID: e.data.OMID ? e.data.OMID : 0,
                    FTECount: e.data.FTECount,
                    MMSID: e.data.MMSID ? e.data.MMSID : "0",
                    GroupID: this.props.groupId,
                    LanguageID:this.props.languageId
                };
                request("/Common/SaveNewAssessment", 'POST', AssesmentMasterDetails)
                    .then(res => {
                        $("#loader-div").show();
                        if (res.outputmessage == i18n.t('success')) {
                            selectedDetails.PMMProcessDraftID = res.ScopeIDGenrated;
                            this.setState({ message: res.outputmessage, reqSrc: i18n.t('startAssessment'), DraftID: res.ScopeIDGenrated, data: AssesmentMasterDetails, selectedDetails: selectedDetails, IsLockedStatus: res.Outputcode });
                            request("/Common/SaveNewAssessment", 'POST', AssesmentMasterDetails)
                                .then(res => {

                                    selectedDetails.PMMProcessDraftID = res.ScopeIDGenrated;
                                    this.props.propsPMMConfigDetails(selectedDetails);
                                    objThis.props.history.push("/BOI");
                                    $("#loader-div").hide();;
                                })
                                .catch(error => {

                                    $("#loader-div").hide();
                                });
                        }
                        else {
                            selectedDetails.PMMProcessDraftID = res.ScopeIDGenrated;
                            this.setState({ Confirm: true, reqSrc: i18n.t('startAssessment'), message: res.outputmessage, DraftID: res.ScopeIDGenrated, data: AssesmentMasterDetails, selectedDetails: selectedDetails, IsLockedStatus: res.Outputcode });

                        }
                        this.props.propsPMMConfigDetails(selectedDetails);
                        $("#loader-div").hide();
                    })
                    .catch(error => {
                        alert(error.statusText);
                        $("#loader-div").hide();
                    });

                $("#loader-div").show();
            }
            else if (e.colDef.headerName == i18n.t('OMIDFTEs') || e.colDef.headerName == i18n.t('MMSIDFTEs')) {
                this.setState({ OMIDFtes: false, Confirm: false, OMIDFtesConfirm: false });
                let AssesmentMasterDetails = {
                    DealId: e.data.DealID,
                    RegionId: e.data.RegionID,
                    CountryId: e.data.CountryID,
                    OGName: e.data.OGName,
                    IndustryName: e.data.IndustryName,
                    ServiceComponentId: e.data.ServiceComponentID,
                    DeliveryLocationId: e.data.DeliveryLocationId,
                    LoggedInUserId: this.props.enterpriseId,
                    PMMProcessDraftID: 0,
                    FlagId: 1,
                    OMID: e.data.OMID ? e.data.OMID : 0,
                    FTECount: e.data.FTECount,
                    MMSID: e.data.MMSID ? e.data.MMSID : "0",
                    GroupID: this.props.groupId,
                    LanguageID:this.props.languageId
                };

                let selectedDetails = {
                    DealName: { "label": e.data.DealName, "value": e.data.DealID },
                    Region: { "label": e.data.RegionName, "value": e.data.RegionID },
                    Country: { "label": e.data.CountryName, "value": e.data.CountryID },
                    OGIndustry: { "OGID": e.data.OGID, "OGName": e.data.OGName, "IndustryId": e.data.IndustryId, "IndustryName": e.data.IndustryName },
                    Capability: { "label": e.data.CapabilityName, "value": e.data.CapabilityID },
                    Tower: { "label": e.data.TowerName, "value": e.data.TowerID },
                    ServiceComponent: { "label": e.data.SCName, "value": e.data.ServiceComponentID },
                    DeliveryLocation: { "label": e.data.DeliveryLocationName, "value": e.data.DeliveryLocationId },
                    PMMProcessDraftID: e.data.PMMProcessDraftID,
                    OMID: e.data.OMID ? e.data.OMID : 0,
                    OMIDValue: e.data.OMIDValue ? e.data.OMIDValue : 0,
                    FTECount: e.data.FTECount,
                    MMSID: e.data.MMSID ? e.data.MMSID : "0",
                    GroupID: this.props.groupId,
                    LanguageID:this.props.languageId
                };
                this.setState({ selectedRowDetails: selectedDetails });
                this.setState({ OMIDFtes: true, OMIDFtesConfirm: false });
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    onQuickFilterText(event) {
        try {
            this.setState({ quickFilterText: event.target.value, Confirm: false, OMIDFtes: false, OMIDFtesConfirm: false });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    async DownloadExcel() {

        try {            
            //window.tfo_event("PMM-D-1", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            var SearchedString = "";
            let excelData = {
                "UserName": this.props.enterpriseId,
                "PMMProcessDraftID": 0,
                "DealId": 0,
                "RegionID": 0,
                "CountryID": 0,
                "CapabilityId": 0,
                "TowerID": 0,
                "IndustryID": 0,
                "SCId": 0,
                "IsLocked": 0,
                "SearchedString": SearchedString,
                "PermissionLevel": 3,
                "GroupID": this.props.groupId,
                "LanguageID":this.props.languageId
            }
            await request("/Common/DownloadtoExcelAssessmentByUserAccess", 'POST', excelData)
                .then(response => {
                    if (response.Table1.length == 0) {
                        alert(i18n.t("norecordsfound"));
                    }
                    else {
                        var filePath = response.Table1[0].Value;
                        window.location.href = filePath;
                    }
                })
                .catch(error => {
                    console.log("Error Encountered");
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    filterCallbackTypeahead(option, props) {
        try {
            if (props.selected.length) {
                return true;
            }
            return option[props.labelKey].toLowerCase().indexOf(props.text.toLowerCase()) !== -1;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    render() {
        var columnDefs = [];
        let isHidden = (this.props.groupId == "1") ? true : false;
        columnDefs = [
            { headerName: i18n.t('startAssessment'), cellRenderer: this.CreateEditDetails.bind(this), suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('PMMProcessDraftID'), field: 'PMMProcessDraftID', suppressMovable: true, suppressResize: true, hide: true },
            { headerName: i18n.t('PMMTransactionID'), field: 'PMMTransactionID', suppressMovable: true, suppressResize: true, hide: true },
            { headerName: i18n.t('dealName'), field: 'DealName', suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('clientBusinessRegion'), field: 'RegionName', suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('accentureDeliveryLocation'), field: 'DeliveryLocationName', suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('capability'), field: 'CapabilityName', suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('tower'), field: 'TowerName', suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('serviceComponent'), field: 'SCName', suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('omid'), field: 'OMIDValue', suppressMovable: true, suppressResize: true, hide: !isHidden },
            { headerName: i18n.t('mMSID'), field: 'MMSID', suppressMovable: true, suppressResize: true, hide: isHidden },
            { headerName: i18n.t('FTEs'), field: 'FTECount', suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('LastSavedBy'), field: 'updatedBy', suppressMovable: true, suppressResize: true },
            { headerName: i18n.t('LastSavedOn'), field: 'updatedDate', suppressMovable: true, suppressResize: true },
            {
                headerName: i18n.t('OMIDFTEs'), cellRenderer: function (params) {
                    if (params.node.data.PMMProcessDraftID != 0) {
                        return '<a style="cursor:pointer;color:#e69d3a">Update</a>'
                    }

                }, suppressMovable: true, suppressResize: true, hide: !isHidden
            },
            {
                headerName: i18n.t('MMSIDFTEs'), cellRenderer: function (params) {
                    if (params.node.data.PMMProcessDraftID != 0) {
                        return '<a style="cursor:pointer;color:#e69d3a">Update</a>'
                    }

                }, suppressMovable: true, suppressResize: true, hide: isHidden
            },
        ];

        var assessmentGridData = this.state.AssessmentGridData;

        var AssessmentDataGrid = (
            <div style={{ height: '380px' }} className="ag-theme-material padding5">
                <AgGridReact ref="Grd_ViewAssess" id="grd_ViewAssess"
                    enableSorting="true"
                    pagination="true"
                    paginationPageSize="10"
                    enableColResize={false}
                    columnDefs={columnDefs}
                    rowData={assessmentGridData}
                    quickFilterText={this.state.quickFilterText}
                    rowmodeltype="pagination"
                    onCellClicked={this.onCellClicked.bind(this)}
                    onGridReady={this.gridReadyFun}
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate={`<span class="ag-overlay-loading-center">${i18n.t('dataLoading')}</span>`} />
            </div>
        )

        return (
            <section className="content">
                <div className="innerpage-hd"> <Link className="menu2 btn btn-info btn-back" to="/Home"><img src={backArrowIcon} /> {i18n.t('bACKTOHOME')}</Link>
                    <ul className="pagecounter"  >
                        <li> <span className="current" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Landing Page" title={i18n.t("takeNewAssessment")}>...</span></li>
                        <li><span data-toggle="popover" data-trigger="hover" data-placement="top" data-content="BOI" title={i18n.t("BOIKeyBusinessMetric")}>1</span></li>
                        <li><span data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Leading Indicator " title={i18n.t("lEADINGINDICATOR")}>2</span></li>
                        <li><span data-toggle="popover" data-trigger="hover" data-placement="top" data-content="PMM" title={i18n.t("pMMASSESSMENT")}>3</span></li>
                        <li><span data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Storyboard" title={i18n.t("sTORYBOARDGAPASSESSMENT")}>4</span></li>
                        <li><span data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Potential Projects" title={i18n.t("pOTENTIALPROJECTS")}>5</span></li>
                        <li><span data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Project Prioritization" title={i18n.t("pROJECTPRIORITIZATION")}>6</span></li>
                    </ul>
                    {this.state.Confirm ? <ConfirmationBox show={this.state.Confirm} bodyMessage={this.state.message} DraftId={this.state.DraftID} reqSrc={this.state.reqSrc} data={this.state.data} propsPMMConfigDetails={this.props.propsPMMConfigDetails} selectedDetails={this.state.selectedDetails} propsIsLocked={this.state.IsLockedStatus} /> : null}
                    {this.state.OMIDFtes ? <UpdateOMIDAndFTEs show={this.state.OMIDFtes} selectedDetails={this.state.selectedRowDetails} propsIsLocked={this.state.IsLockedStatus} propshowUpdatedRecord={this.showUpdatedRecord.bind(this)} /> : null}
                    {this.state.OMIDFtesConfirm ? <UpdateOMIDAndFTEsConfirm show={this.state.OMIDFtesConfirm} selectedDetails={this.state.selectedRowDetails} /> : null}
                </div>
                <section className="bodydata">
                    <div className="bodydata-full">
                        <div className="box-plain">
                            <h4>{i18n.t('takeNEWSAVEDAssessment')} </h4>
                            <div className="row">
                                <div className="box">
                                    <div className="box-in">
                                        <div className="col-lg-4 form-horizontal">
                                            <div className="form-group">
                                                <label className="control-label col-sm-4" id="lbl_dealName"><span style={{ color: "red" }}>*</span>{i18n.t('deal_Name')}: </label>
                                                <div className="col-sm-8">
                                                    <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="ddlDeal" id="ddl_deal" name="form-field-name" labelKey="label" onChange={this.SelectDeal.bind(this)} options={this.state.lstDeal} selected={this.state.DealValue} filterBy={this.filterCallbackTypeahead.bind(this)} />
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className="control-label col-sm-4" id="lbl_industry" ><span style={{ color: "red" }}>*</span>{i18n.t('industry')}:</label>
                                                <div className="col-sm-8">
                                                    <input ref="idIndustry" id="txt_industryName" className="form-control" type="text" maxLength="100" disabled value={this.state.OGIndustry.IndustryName == null ? "" : this.state.OGIndustry.IndustryName} />
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className="control-label col-sm-4" id="lbl_og" ><span style={{ color: "red" }}>*</span>{i18n.t('oG')}:</label>
                                                <div className="col-sm-8">
                                                    <input ref="idOG" id="txt_ogName" className="form-control" type="text" maxLength="100" disabled value={this.state.OGIndustry.OGName == null ? "" : this.state.OGIndustry.OGName} />
                                                </div>
                                            </div>
                                            {(this.props.groupId == "1") ?
                                                <div className="form-group">
                                                    <label className="control-label col-sm-4" id="lbl_selectOMID" title={i18n.t('pleaseselectOMIDforchosenTower')} ><span style={{ color: "red" }}>*</span>{i18n.t('omid')}:</label>
                                                    <div className="col-sm-8" title={i18n.t('pleaseselectOMIDforchosenTower')}>
                                                        <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="ddlOMId" id="ddl_omid" name="form-field-name" labelKey="label" onChange={this.SelectOMID.bind(this)} options={this.state.lstOMID} selected={this.state.OMIDValue} filterBy={this.filterCallbackTypeahead.bind(this)} />
                                                    </div>
                                                </div>
                                                :
                                                <div className="form-group">
                                                    <label className="control-label col-sm-4" id="lbl_MMSID" ><span style={{ color: "red" }}>*</span>{i18n.t('mMSID')}:</label>
                                                    <div className="col-sm-8">
                                                        <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="ddlMMSID" id="ddl_mmsid" name="form-field-name" labelKey="label" onChange={this.SelectMMSID.bind(this)} options={this.state.lstMMSID} selected={this.state.MMSID} filterBy={this.filterCallbackTypeahead.bind(this)} />

                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className="col-lg-4 form-horizontal">
                                            <div className="form-group">
                                                <label className="control-label col-sm-4" id="lbl_clientBusinessRegion"><span style={{ color: "red" }}>*</span>{i18n.t('clientBusinessRegion')}:</label>
                                                <div className="col-sm-8">
                                                    <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="ddlRegion" id="ddl_region" name="form-field-name" labelKey="label" onChange={this.SelectRegion.bind(this)} options={this.state.lstRegion} selected={this.state.RegionValue} filterBy={this.filterCallbackTypeahead.bind(this)} />
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className="control-label col-sm-4" id="lbl_accDeliveryLocation" ><span style={{ color: "red" }}>*</span>{i18n.t('accentureDeliveryLocation')} : </label>
                                                <div className="col-sm-8">
                                                    <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="ddlDlvryLoc" id="ddl_deliveryLoc" name="form-field-name" labelKey="label" onChange={this.SelectDlvryLoc.bind(this)} options={this.state.lstDlvryLoc} selected={this.state.DlvryLocValue} filterBy={this.filterCallbackTypeahead.bind(this)} />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="control-label col-sm-4" id="lbl_fillFTEs" title={i18n.t('PleasefillinFTEsforchosenTowerServiceComponent')} ><span style={{ color: "red" }}>*</span>{i18n.t('FTEs')} : </label>
                                                <div className="col-sm-8">
                                                    <input ref="idFTEs" id="txt_fte" className="form-control" value={this.state.FTEs} onChange={(e) => { this.handleFTEsChange(e) }} type="text"
                                                        onInput={(e) => {
                                                            return e.target.value = e.target.value.replace(/[^\w\s\@\.-]/gi, "")
                                                        }}
                                                        maxLength="100" title={i18n.t('PleasefillinFTEsforchosenTowerServiceComponent')} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-4 form-horizontal">
                                            <div className="form-group">
                                                <label className="control-label col-sm-4" id="lbl_capability"><span style={{ color: "red" }}>*</span>{i18n.t('capability')} :</label>
                                                <div className="col-sm-8">
                                                    <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="ddlCapability" id="ddl_capability" name="form-field-name" labelKey="label" onChange={this.SelectCapability.bind(this)} options={this.state.lstCapability} selected={this.state.CapabilityValue} filterBy={this.filterCallbackTypeahead.bind(this)} />
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className="control-label col-sm-4" id="lbl_tower" ><span style={{ color: "red" }}>*</span>{i18n.t('tower')} :</label>
                                                <div className="col-sm-8">
                                                    <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="ddlTower" id="ddl_tower" name="form-field-name" labelKey="label" onChange={this.SelectTower.bind(this)} options={this.state.lstTower} selected={this.state.TowerValue} filterBy={this.filterCallbackTypeahead.bind(this)} />
                                                </div>
                                            </div>

                                            <div className="form-group">
                                                <label className="control-label col-sm-4" id="lbl_serviceComponent" ><span style={{ color: "red" }}>*</span>{i18n.t('serviceComponent')} : </label>
                                                <div className="col-sm-8">
                                                    <Typeahead emptyLabel={i18n.t('noMatchesFound')} ref="ddlServiceComp" id="ddl_serviceComponent" name="form-field-name" labelKey="label" onChange={this.SelectServiceComponent.bind(this)} options={this.state.lstServiceComp} selected={this.state.ServiceCompValue} filterBy={this.filterCallbackTypeahead.bind(this)} />
                                                </div>
                                            </div>

                                            <div className="btn-group-md startAssessmentbtn pull-right">
                                                {this.state.eblNxtBTn ? <a type="button" className="btn btn-success" onClick={this.SaveNewAssessment} disabled={false}>{i18n.t('startAssessment')}</a> :
                                                    <a type="button" className="btn btn-success" disabled={true}>{i18n.t('startAssessment')}</a>}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            <h4 className="btn-group-md">{i18n.t('takeSavedAssessment')}
                                <span className="badge">{recordCount}</span>
                                <input type="text" placeholder={i18n.t("search")} id="txt_searchAssessment" className="fright searchBox" data-field='searchOG' onChange={this.onQuickFilterText.bind(this)} />
                                <div className="btn-group btn-group-pmmasmt">
                                    <button type="button" id="btn_downloadAssesment" title={i18n.t("Downloadbelowlistofsavedassessments")} className="btn btn-default btn-downld" onClick={this.DownloadExcel}>
                                        <img src={downloadIcon} />
                                    </button>
                                </div>
                            </h4>

                            <div className="AsmtData viewprevassmt">
                                {AssessmentDataGrid}
                            </div>

                        </div>
                    </div>
                </section>
            </section>
        );
    }
}

function mapStateToProps(state){
    return{
        languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
        roleName:state.roleName,
        pageName:state.pageName,
        groupName:state.groupName,  
    }
}

CreateNewAssessment = connect(mapStateToProps)(withRouter(CreateNewAssessment))
export default CreateNewAssessment;
