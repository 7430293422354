/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import ReactSelect from 'react-select';
import { withRouter } from 'react-router-dom';
import NavigationConfirmation from '../NavigationConfirmation';
import PMMEffort from './PMMEffort';
import BenchmarkPopup from '../BenchmarkPopup';
import $ from 'jquery';
import backArrowIcon from '../../images/icon-back-arrow.png';
import downloadIcon from '../../images/icon-download.png';
import downloadBrownIcon from '../../images/icon-download-brown.png';
import addIcon from '../../images/icon-add-btn.png';
import request from '../../services/Service';
import { Typeahead } from 'react-bootstrap-typeahead';
import i18n from 'i18next';
import langKeys from '../../Utils/LangKeys'
import { withTranslation } from 'react-i18next';
import { AgGridLocale } from '../../Utils/AgGridLocale';
import { inputValidator } from '../../Utils/InputElementValidation';


var leadingIndicatorData = [];
var newCount = 1;
var numericStatus = true;
var BOIList = [];
var editActive = false;

function CreateDelete(params) {
    if (params.data.BOILIName == i18n.t('others')) {
        let eDiv = document.createElement("div");
        eDiv.innerHTML = '<span class="glyphicon glyphicon-trash"></span>';
        eDiv.className = "col-md-12";
        eDiv.width = "100%";
        return eDiv;
    }
}

function CreateCheckbox() {
    this.eGui = document.createElement("div");
    this.eGui.width = "100%";
}

CreateCheckbox.prototype.init = function (params) {
    var checkboxElement = document.createElement("input");
    checkboxElement.type = "checkbox";
    checkboxElement.id = params.data.LIDraftId;
    checkboxElement.className = "findColumns";
    checkboxElement.checked = params.data.IsSelected;


    checkboxElement.addEventListener('change', function (event) {
        editActive = true;
        if (params.data.Formula != "" && params.data.Formula != null) {
            var Formula = params.data.Formula;
            var formulaFields = Formula.match(/[^>=<+-/*()]+/g);
            var j = 0;
            var rowData = params.node.gridOptionsWrapper.gridOptions.rowData;
            var formulaDependentMetric = [];
            var numStatus = false;

            for (j = 0; j < formulaFields.length; j++) {
                !isNaN(parseFloat(formulaFields[j])) && isFinite(formulaFields[j]) ? numStatus = true : numStatus = false;
                if (numStatus == false) {
                    formulaDependentMetric.push(formulaFields[j]);
                }
            }
            var filteredArray = rowData.filter(function (itm) {
                return formulaDependentMetric.indexOf(itm.UniqueFormulaID) > -1;
            });


            var result = filteredArray.filter(function (el) {
                return (el.IsSelected == false);
            });

            if (result.length == 0) {
            //     params.data.IsSelected == false

            // }
            // else {
                params.data.IsSelected == true ? params.data.IsSelected = false : params.data.IsSelected = true;

            }
        }
        else {
            params.api.gridOptionsWrapper.gridOptions.rowData.map((eachRow, index) => {
                if (index == params.rowIndex) {
                    eachRow["IsSelected"] = event.currentTarget.checked
                }

                var rowData = params.api.gridOptionsWrapper.gridOptions.rowData;
                if (eachRow.Formula != "" && eachRow.Formula != null) {
                    var Formula = eachRow.Formula;
                    var formulaFields = Formula.match(/[^>=<+-/*()]+/g);
                    var j = 0;

                    var formulaDependentMetric = [];
                    var numStatus = false;

                    for (j = 0; j < formulaFields.length; j++) {
                        !isNaN(parseFloat(formulaFields[j])) && isFinite(formulaFields[j]) ? numStatus = true : numStatus = false;
                        if (numStatus == false) {
                            formulaDependentMetric.push(formulaFields[j]);
                        }
                    }

                    var filteredArray = rowData.filter(function (itm) {
                        return formulaDependentMetric.indexOf(itm.UniqueFormulaID) > -1;
                    });


                    var currentMetricIsPresentArr = filteredArray.filter(function (itm) {
                        return (itm.UniqueFormulaID == params.data.UniqueFormulaID);
                    });

                    var result = filteredArray.filter(function (el) {
                        return (el.IsSelected == false);
                    });

                    if (currentMetricIsPresentArr.length > 0) {
                        if (result.length > 0) {
                            eachRow["IsSelected"] = false;
                        }
                        else {
                            eachRow["IsSelected"] = params.data.IsSelected;
                        }
                    }
                }

            });
        }
        params.api.gridOptionsWrapper.gridOptions.api.setRowData(params.api.gridOptionsWrapper.gridOptions.rowData);

    });

    this.eGui.appendChild(checkboxElement);
}

CreateCheckbox.prototype.getGui = function getGui() {
    return this.eGui;
}

CreateCheckbox.prototype.refresh = function (params) {
    this.eGui.textContent = '';
    this.init(params)
}

CreateCheckbox.prototype.onChange = function (params) {

};

function CreateTextbox() {
    this.eGui = document.createElement("div");
    this.eGui.className = "col-md-12";
    this.eGui.width = "100%";
}

CreateTextbox.prototype.init = function (params) {
    var textboxElement = document.createElement("input");
    textboxElement.type = "textbox";
    textboxElement.checked = "textbox";

    if(params.colDef.headerName == i18n.t('displayName')){
        textboxElement.value = params.data.DisplayName;
    }
    else{
        textboxElement.value = "";
    }    
    if(params.colDef.headerName == i18n.t("As-Is")){
        textboxElement.value = params.data.AsIs;
    }
    else{
        textboxElement.value = "";
    }
    if(params.colDef.headerName == i18n.t("To-Be")){
        textboxElement.value = params.data.ToBe;
    }
    else{
        textboxElement.value = "";
    }
    if(params.colDef.headerName == i18n.t('BOIKeyBusinessMetricImpacted')){
        textboxElement.value = params.data.BOIImpacted;
    }
    else{
        textboxElement.value = "";
    }
    textboxElement.addEventListener('change', function (event) {
        params.data[params.colDef.field] = event.target.value;
    });
    this.eGui.appendChild(textboxElement);
}

CreateTextbox.prototype.getGui = function getGui() {
    return this.eGui;
}

CreateTextbox.prototype.refresh = function (params) {
    this.eGui.textContent = '';
    this.init(params)
}

CreateTextbox.prototype.onChange = function (params) {

};

function CreateDropdown() {
    this.eGui = document.createElement("div");
    this.eGui.className = "row";
    this.eGui.width = "100%";
}

CreateDropdown.prototype.init = function (params) {

    if (params.data.MetricName == "") {
        var dropDownElement = document.createElement("select");
        dropDownElement.id = params.rowIndex;
        dropDownElement.options[0] = new Option(i18n.t('select'), 0)
        let metricValues = params.agGridReact.gridOptions.rowData[0].OtherMetricsTypes
        metricValues.map((ddValues, index) => { return dropDownElement.options[index + 1] = new Option(ddValues.MetricName, ddValues.MetricID) });
        dropDownElement.addEventListener('change', function (event) {
            params.agGridReact.gridOptions.rowData.map((eachRow, index) => { if (index == params.rowIndex) { eachRow[params.colDef.field] = event.currentTarget.selectedOptions[0].text; eachRow["MetricId"] = event.currentTarget.selectedOptions[0].value } })
        });
    }
    else {
        dropDownElement = document.createElement("label");
        dropDownElement.textContent = params.value;
    }
    this.eGui.appendChild(dropDownElement);
}

CreateDropdown.prototype.getGui = function getGui() {
    return this.eGui;
}

CreateDropdown.prototype.refresh = function (params) {
    this.eGui.textContent = '';
    this.init(params)
}

class BOISelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            BOIValue: [],
            BOIOptions: this.props.data.BOIImpactedTypes,
            selectedMetric: ""
        };
        this.onBOIChange = this.onBOIChange.bind(this);
        this.populateBOIs = this.populateBOIs.bind(this);
        this.loadBOIImpacted = this.loadBOIImpacted.bind(this);
    }

    componentDidMount() {

        this.loadBOIImpacted();
    }

    componentWillReceiveProps(nextProps) {
        this.populateBOIs();
    }

    componentDidUpdate() {

    }

    unloadEvent(e) {
        let confirmationMessage = i18n.t("leavingthispagewillresultinlossunsaveddata");
        (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        return confirmationMessage; //Webkit, Safari, Chrome etc.
    }

    populateBOIs() {
        if (this.props.data.BOIImpacted && this.props.data.BOIImpacted != null && this.props.data.BOIImpacted.length > 0 && this.props.data.BOILIName == i18n.t('others')) {
            this.onBOIChange(this.props.data.BOIImpacted);
        }
    }

    loadBOIImpacted() {
        if (this.props.data.ConfigureBOIs && this.props.data.ConfigureBOIs != null && this.props.data.ConfigureBOIs.length > 0 && this.props.data.BOILIName == i18n.t('others') && this.props.data.LIDraftId == "0") {
            this.onBOIChange(this.props.data.ConfigureBOIs);
        }
    }

    onBOIChange(value) {
        let selectedBOIs = "", BOINames = "";
        this.setState({
            BOIValue: value
        });
        value.map((BOI, index) => {
            if (index == 0) {
                selectedBOIs = BOI.value;
                BOINames = BOI.label;
            } else {
                selectedBOIs = selectedBOIs + "," + BOI.value;
                BOINames = BOINames + "," + BOI.label;
            }
        });
        this.props.data["ConfigureBOIs"] = value;
        this.props.data["BOIImpacted"] = BOINames;
        this.setState({
            BOIValue: value
        });
    }

    render() {
        return (
            <div>
                {this.props.data.BOILIName == i18n.t('others') && this.props.data.LIDraftId == "0" ?
                    <Typeahead emptyLabel={i18n.t('noMatchesFound')} id="ddl_boiSelect" labelKey="label" onChange={this.onBOIChange} options={this.state.BOIOptions} selected={this.state.BOIValue} menuPlacement="auto" menuContainerStyle={{ top: 'auto', bottom: '100%', borderTop: '1px solid black !important' }} multiple clearButton />
                    : this.props.data.BOIImpacted
                }
            </div>
        );
    }
}

class LeadingIndicatorGrid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            quickFilterText: null,
            isEditAcitve: false,
            popUpDisplay: false,
            toURL: '',
            leadingIndicatorGridData: [],
            navigationPage: "",
            deleteStatus: false,
            BOIList: [],
            EffortGridData: [],
            popUpBenchmark: false
        };
        this.onOverallEdit = this.onOverallEdit.bind(this);
        this.onNavigationClick = this.onNavigationClick.bind(this);
        this.closeNavigationPopUp = this.closeNavigationPopUp.bind(this);
        this.reSizeGrid = this.reSizeGrid.bind(this);
        this.valueChange = this.valueChange.bind(this);
        this.checkCells = this.checkCells.bind(this);
        this.DownloadExcel = this.DownloadExcel.bind(this);
        this.DownloadReferenceContentExcel = this.DownloadReferenceContentExcel.bind(this);
        this.unloadEvent = this.unloadEvent.bind(this);
        this.ShowEffortPopup = this.ShowEffortPopup.bind(this);
        this.closeBenchmarkPopUp = this.closeBenchmarkPopUp.bind(this);
    }

    componentWillMount() {
    }

    componentDidMount() {        
        // if(!this.props.configDetails.DealName.label.toLowerCase().includes("test")){
        // window.tfo_dataevent("PMM-TPA-3", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1, this.props.configDetails.DealName.label)
        // }
        window.addEventListener("beforeunload", this.unloadEvent);
        window.addEventListener('resize', this.reSizeGrid, true);
        $("#loader-div").show();



        var SearchedString = "";
        let effortDraftData = {
            EffortDraftID: 0,
            PMMProcessDraftID: this.props.configDetails.PMMProcessDraftID,
            SCID: this.props.configDetails.ServiceComponent.value,
            SearchedString: SearchedString,
            UserName: this.props.enterpriseId,
            GroupID: this.props.groupId,
            LanguageID: this.props.languageId
        }
        request("/PMM/GetEffortDraft", 'POST', effortDraftData)
            .then(response => {
                let effortList = response != null ? response : [];
                if (response !== "") {
                    this.effortListTemp = effortList.map(e => ({ ...e }));
                    this.setState({ EffortGridData: effortList });
                }
                $("#loader-div").hide();
            })
            .catch(error => {
                console.log("Error Encountered");
            });

    }

    componentWillUnmount() {
        window.removeEventListener("beforeunload", this.unloadEvent);
        window.removeEventListener('resize', this.reSizeGrid, true);
        this.refs.Grd_LeadInd.gridOptions.api.sizeColumnsToFit();
    }

    componentDidUpdate() {
        this.refs.Grd_LeadInd.gridOptions.api.sizeColumnsToFit();
    }

    unloadEvent(e) {
        let confirmationMessage = i18n.t("leavingthispagewillresultinlossunsaveddata");
        (e || window.event).returnValue = confirmationMessage; //Gecko + IE
        return confirmationMessage; //Webkit, Safari, Chrome etc.
    }

    reSizeGrid() {
        this.refs.Grd_LeadInd.gridOptions.api.sizeColumnsToFit();
    }

    AddNewLeadingIndicator() {
        leadingIndicatorData.push({ MetricName: '', DisplayName: '', AsIs: '', ToBe: '', BOIImpacted: '' });
        this.setState({ leadingIndicatorGridData: leadingIndicatorData });
    }

    onAddRow() {
        var newItem = this.CreateNewRowData();
        let currentGridData = this.refs.Grd_LeadInd.gridOptions.rowData
        newItem.PMMProcessDraftID = this.props.configDetails.PMMProcessDraftID;
        currentGridData.push(newItem);
        this.refs.Grd_LeadInd.gridOptions.api.setRowData(currentGridData);

        this.setState({ deleteStatus: true });
    }

    valueChange(params) {


        let props = {
            force: true,
            rowNode: params.node
        }
        this.checkCells();
    }

    checkCells() {
        let objGridDetails = this.refs.Grd_LeadInd.gridOptions.rowData;
        var isErrorThere = false;
        var numStatus = false;


        objGridDetails.map((row, i) => {
            let AsIs = parseFloat(row.AsIs);
            let ToBe = parseFloat(row.ToBe);
            let isAsIsErr = false, isToBeErr = false, isDsplayErr = false;
            //Max Min Validation without Formula
            if (objGridDetails[i].Formula == "" || objGridDetails[i].Formula == null) {
                if (objGridDetails[i].Maximum != null && objGridDetails[i].Minimum != null && objGridDetails[i].Maximum.toString() != "" && objGridDetails[i].Minimum.toString() != "") {
                    var max = parseFloat(objGridDetails[i].Maximum);
                    var min = parseFloat(objGridDetails[i].Minimum);

                    if (AsIs < min) {
                        row["AsIsError"] = true;
                        row["AsIsMsg"] = i18n.t("MinimumAsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldnotbelessthan") + min.toString();
                        isAsIsErr = true;
                        isErrorThere = true;

                    }
                    if (AsIs > max) {
                        row["AsIsError"] = true;
                        row["AsIsMsg"] = i18n.t("MaximumAsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldnotbemorethan") + max.toString();
                        isAsIsErr = true;
                        isErrorThere = true;

                    }
                    if (ToBe < min) {
                        row["ToBeError"] = true;
                        row["ToBeMsg"] = i18n.t("MinimumToBevalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldnotbelessthan") + min.toString();
                        isToBeErr = true;
                        isErrorThere = true;

                    }
                    if (ToBe > max) {
                        row["ToBeError"] = true;
                        row["ToBeMsg"] = i18n.t("MaximumToBevalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldnotbemorethan") + max.toString();
                        isToBeErr = true;
                        isErrorThere = true;

                    }
                }

                //Less than Equal to validation
                if (objGridDetails[i].LessThanEqulaTo != null) {
                    if (objGridDetails[i].LessThanEqulaTo == true) {
                        if (AsIs < ToBe) {
                            row["AsIsError"] = true;
                            row["AsIsMsg"] = i18n.t("AsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldbegreaterthanequaltoToBevalue")
                            isAsIsErr = true;
                            isErrorThere = true;

                        }
                    }
                    else if (objGridDetails[i].LessThanEqulaTo == false) {
                        if (AsIs > ToBe) {
                            row["AsIsError"] = true;
                            row["AsIsMsg"] = i18n.t("AsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldbegreaterthanequaltoToBevalue")
                            isAsIsErr = true;
                            isErrorThere = true;

                        }
                    }
                }
            }

            //Zero Validation
            var k = 0;
            if (objGridDetails[i].IsZero != "" && objGridDetails[i].IsZero != null) {
                for (k = 0; k < objGridDetails.length; k++) {
                    if (objGridDetails[k].UniqueFormulaID == objGridDetails[i].IsZero) {
                        if (objGridDetails[k].AsIs != null && objGridDetails[k].AsIs.toString() == "0") {
                            objGridDetails[i].AsIs = 0;
                        }
                        if (objGridDetails[k].ToBe != null && objGridDetails[k].ToBe.toString() == "0") {
                            objGridDetails[i].ToBe = 0;
                        }
                    }
                }
            }

            // Formula Validation
            if (objGridDetails[i].Formula != "" && objGridDetails[i].Formula != null && objGridDetails[i].IsAutoCalclulate == true) {
                var FinalAsIS = "";
                var FinalToBe = "";
                var FormulaAsIs = objGridDetails[i].Formula;
                var FormulaToBe = objGridDetails[i].Formula;
                var Formula = objGridDetails[i].Formula;
                var formulaFields = Formula.match(/[^>=<+-/*()]+/g);
                var j = 0;

                for (j = 0; j < formulaFields.length; j++) {
                    // Checking For Formula Field Type (Number or Metric Unique ID) 
                    !isNaN(parseFloat(formulaFields[j])) && isFinite(formulaFields[j]) ? numStatus = true : numStatus = false;
                    if (numStatus == false) {
                        var result = objGridDetails.filter(function (el) {
                            return (el.UniqueFormulaID === formulaFields[j]);
                        });

                        if (result.length > 0) {
                            // Blank Value Check for Formula Fields.
                            if (result[0].AsIs == null || result[0].AsIs === "") {
                                FormulaAsIs = FormulaAsIs.replace(formulaFields[j], "");
                                // Assign Blank value for the formula contained Metric if any field of the formula will be blank.
                                objGridDetails[i].AsIs = "";
                            }
                            else {
                                FormulaAsIs = FormulaAsIs.replace(formulaFields[j], result[0].AsIs.toString());

                                // Formula >= and <= for AsIs
                                if (FormulaAsIs.includes(">=")) {
                                    if (objGridDetails[i].AsIs != null && objGridDetails[i].AsIs != "") {
                                        FormulaAsIs = objGridDetails[i].AsIs.toString() + FormulaAsIs;
                                        try {
                                            FinalAsIS = eval(FormulaAsIs);
                                            if (FinalAsIS == false) {
                                                row["AsIsError"] = true;
                                                row["AsIsMsg"] = i18n.t("AsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldbetoAsIsvalueofmetric") + result[0].BOILIName + ".";
                                                isAsIsErr = true;
                                                isErrorThere = true;

                                            }
                                        }
                                        catch (err) {
                                            console.log("Error Encountered");
                                        }
                                    }
                                }
                                else if (FormulaAsIs.includes("<=")) {
                                    if (objGridDetails[i].AsIs != null && objGridDetails[i].AsIs != "") {
                                        FormulaAsIs = objGridDetails[i].AsIs.toString() + FormulaAsIs;
                                        try {
                                            FinalAsIS = eval(FormulaAsIs);
                                            if (FinalAsIS == false) {
                                                row["AsIsError"] = true;
                                                row["AsIsMsg"] = i18n.t("AsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t('ShouldbetoAsisvalofmatric') + result[0].BOILIName + ".";
                                                isAsIsErr = true;
                                                isErrorThere = true;

                                            }
                                        }
                                        catch (err) {
                                            console.log("Error Encountered");
                                        }
                                    }
                                }
                            }

                            if (result[0].ToBe == null || result[0].ToBe === "") {
                                FormulaToBe = FormulaToBe.replace(formulaFields[j], "");
                                objGridDetails[i].ToBe = "";
                            }
                            else {
                                FormulaToBe = FormulaToBe.replace(formulaFields[j], result[0].ToBe.toString());

                                // Formula >= and <= for ToBe: Start
                                if (FormulaToBe.includes(">=")) {
                                    if (objGridDetails[i].ToBe != null && objGridDetails[i].ToBe != "") {
                                        FormulaToBe = objGridDetails[i].ToBe.toString() + FormulaToBe;
                                        try {
                                            FinalToBe = eval(FormulaToBe);
                                            if (FinalToBe == false) {
                                                row["ToBeError"] = true;
                                                row["ToBeMsg"] = i18n.t("ToBevalueofmetric") + objGridDetails[i].BOILIName + i18n.t('shouldbetoToBevalueofmetric') + result[0].BOILIName + ".";
                                                isToBeErr = true;
                                                isErrorThere = true;

                                            }
                                        }
                                        catch (err) {
                                            console.log("Error Encountered");
                                        }
                                    }
                                }
                                else if (FormulaToBe.includes("<=")) {
                                    if (objGridDetails[i].ToBe != null && objGridDetails[i].ToBe != "") {
                                        FormulaToBe = objGridDetails[i].ToBe.toString() + FormulaToBe;
                                        try {
                                            FinalToBe = eval(FormulaToBe);
                                            if (FinalToBe == false) {
                                                row["ToBeError"] = true;
                                                row["ToBeMsg"] = i18n.t("ToBevalueofmetric") + objGridDetails[i].BOILIName + i18n.t('shouldbetoToBevalueofetric') + result[0].BOILIName + ".";
                                                isToBeErr = true;
                                                isErrorThere = true;

                                            }
                                        }
                                        catch (err) {
                                            console.log("Error Encountered");
                                        }
                                    }
                                }
                                // Formula >= and <= for ToBe: End
                            }
                        }
                    }
                }

                try {
                    FinalAsIS = eval(FormulaAsIs);
                    FinalToBe = eval(FormulaToBe);
                }
                catch (err) {
                    console.log("Error Encountered");
                }
                //Max Min Validation after Formula calculation
                var num = false;
                !isNaN(parseFloat(FinalAsIS)) && isFinite(FinalAsIS) ? num = true : num = false;

                if (num == true) {
                    if (objGridDetails[i].Maximum.toString() != "" && objGridDetails[i].Minimum.toString() != "" && objGridDetails[i].Maximum != null && objGridDetails[i].Minimum != null) {
                        var max = parseFloat(objGridDetails[i].Maximum);
                        var min = parseFloat(objGridDetails[i].Minimum);

                        if (FinalAsIS < min) {
                            row["AsIsError"] = true;
                            row["AsIsMsg"] = i18n.t("MinimumAsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldnotbelessthan") + min.toString();
                            isAsIsErr = true;
                            isErrorThere = true;

                        }
                        if (FinalAsIS > max) {
                            row["AsIsError"] = true;
                            row["AsIsMsg"] = i18n.t("MaximumAsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldnotbemorethan") + max.toString();
                            isAsIsErr = true;
                            isErrorThere = true;

                        }
                        if (FinalToBe < min) {
                            row["ToBeError"] = true;
                            row["ToBeMsg"] = i18n.t("MinimumToBevalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldnotbelessthan") + min.toString();
                            isToBeErr = true;
                            isErrorThere = true;

                        }
                        if (FinalToBe > max) {
                            row["ToBeError"] = true;
                            row["ToBeMsg"] = i18n.t("MaximumToBevalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldnotbemorethan") + max.toString();
                            isToBeErr = true;
                            isErrorThere = true;

                        }
                    }
                    objGridDetails[i].AsIs = FinalAsIS;
                    objGridDetails[i].ToBe = FinalToBe;

                    //Less than Equal to validation after Formula calculation

                    if (objGridDetails[i].LessThanEqulaTo != null) {
                        if (objGridDetails[i].LessThanEqulaTo == true) {
                            if ((FinalAsIS != "" && FinalToBe != "") && (FinalAsIS < FinalToBe)) {
                                row["AsIsError"] = true;
                                row["AsIsMsg"] = i18n.t("AsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t('shouldbegreaterthanequaltoToBevalue')
                                isAsIsErr = true;
                                isErrorThere = true;

                            }
                        }
                        else if (objGridDetails[i].LessThanEqulaTo == false) {
                            if ((FinalAsIS != "" && FinalToBe != "") && (FinalAsIS > FinalToBe)) {
                                row["AsIsError"] = true;
                                row["AsIsMsg"] = i18n.t("AsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t('shouldbelessthanequaltoToBevalue')
                                isAsIsErr = true;
                                isErrorThere = true;

                            }
                        }
                    }
                }
            }

            // Formula Validation For >=Metric and <=Metric
            if (objGridDetails[i].Formula != "" && objGridDetails[i].Formula != null && objGridDetails[i].IsAutoCalclulate == false) {
                var FinalAsIS = "";
                var FinalToBe = "";
                var FormulaAsIs = objGridDetails[i].Formula;
                var FormulaToBe = objGridDetails[i].Formula;
                var Formula = objGridDetails[i].Formula;
                var formulaFields = Formula.match(/[^>=<+-/*()]+/g);
                var j = 0;
                for (j = 0; j < formulaFields.length; j++) {
                    !isNaN(parseFloat(formulaFields[j])) && isFinite(formulaFields[j]) ? numStatus = true : numStatus = false;
                    if (numStatus == false) {
                        var result = objGridDetails.filter(function (el) {
                            return (el.UniqueFormulaID === formulaFields[j]);
                        });

                        if (result.length > 0) {

                            // Blank Value Check for Formula Fields.
                            if (result[0].AsIs == null || result[0].AsIs === "") {
                                FormulaAsIs = FormulaAsIs.replace(formulaFields[j], "");
                                // Assign Blank value for the formula contained Metric if any field of the formula will be blank.
                                objGridDetails[i].AsIs = "";
                            }
                            else {
                                FormulaAsIs = FormulaAsIs.replace(formulaFields[j], result[0].AsIs.toString());

                                // Formula >= and <= for AsIs
                                if (FormulaAsIs.includes(">=")) {
                                    if (objGridDetails[i].AsIs != null && objGridDetails[i].AsIs != "") {
                                        FormulaAsIs = objGridDetails[i].AsIs.toString() + FormulaAsIs;
                                        try {
                                            FinalAsIS = eval(FormulaAsIs);
                                            if (FinalAsIS == false) {
                                                row["AsIsError"] = true;
                                                row["AsIsMsg"] = i18n.t("AsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t('shouldbetoAsIsvalueofmetric') + result[0].BOILIName + ".";
                                                isAsIsErr = true;
                                                isErrorThere = true;

                                            }
                                        }
                                        catch (err) {
                                            console.log("Error Encountered");
                                        }
                                    }
                                }
                                else if (FormulaAsIs.includes("<=")) {
                                    if (objGridDetails[i].AsIs != null && objGridDetails[i].AsIs != "") {
                                        FormulaAsIs = objGridDetails[i].AsIs.toString() + FormulaAsIs;
                                        try {
                                            FinalAsIS = eval(FormulaAsIs);
                                            if (FinalAsIS == false) {
                                                row["AsIsError"] = true;
                                                row["AsIsMsg"] = i18n.t("AsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t('ShouldbetoAsisvalofmatric') + result[0].BOILIName + ".";
                                                isAsIsErr = true;
                                                isErrorThere = true;

                                            }
                                        }
                                        catch (err) {
                                            console.log("Error Encountered");
                                        }
                                    }
                                }
                            }

                            if (result[0].ToBe == null || result[0].ToBe === "") {
                                FormulaToBe = FormulaToBe.replace(formulaFields[j], "");
                                objGridDetails[i].ToBe = "";
                            }
                            else {
                                FormulaToBe = FormulaToBe.replace(formulaFields[j], result[0].ToBe.toString());

                                // Formula >= and <= for ToBe: Start
                                if (FormulaToBe.includes(">=")) {
                                    if (objGridDetails[i].ToBe != null && objGridDetails[i].ToBe != "") {
                                        FormulaToBe = objGridDetails[i].ToBe.toString() + FormulaToBe;
                                        try {
                                            FinalToBe = eval(FormulaToBe);
                                            if (FinalToBe == false) {
                                                row["ToBeError"] = true;
                                                row["ToBeMsg"] = i18n.t("AsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t('shouldbetoToBevalueofmetric') + result[0].BOILIName + ".";
                                                isToBeErr = true;
                                                isErrorThere = true;

                                            }
                                        }
                                        catch (err) {
                                            console.log("Error Encountered");
                                        }
                                    }
                                }
                                else if (FormulaToBe.includes("<=")) {
                                    if (objGridDetails[i].ToBe != null && objGridDetails[i].ToBe != "") {
                                        FormulaToBe = objGridDetails[i].ToBe.toString() + FormulaToBe;
                                        try {
                                            FinalToBe = eval(FormulaToBe);
                                            if (FinalToBe == false) {
                                                row["ToBeError"] = true;
                                                row["ToBeMsg"] = i18n.t("AsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t('shouldbetoToBevalueofetric') + result[0].BOILIName + ".";
                                                isToBeErr = true;
                                                isErrorThere = true;

                                            }
                                        }
                                        catch (err) {
                                            console.log("Error Encountered");
                                        }
                                    }
                                }
                                // Formula >= and <= for ToBe: End
                            }
                        }
                    }
                }

                //Less than Equal to validation
                if (objGridDetails[i].LessThanEqulaTo != null) {
                    if (objGridDetails[i].LessThanEqulaTo == true) {
                        if (AsIs < ToBe) {
                            row["AsIsError"] = true;
                            row["AsIsMsg"] = i18n.t("AsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t('shouldbegreaterthanequaltoToBevalue')
                            isAsIsErr = true;
                            isErrorThere = true;

                        }
                    }
                    else if (objGridDetails[i].LessThanEqulaTo == false) {
                        if (AsIs > ToBe) {
                            row["AsIsError"] = true;
                            row["AsIsMsg"] = i18n.t("AsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t('shouldbelessthanequaltoToBevalue')
                            isAsIsErr = true;
                            isErrorThere = true;

                        }
                    }
                }
            }

            // Check Display Name for Others Metric
            if (objGridDetails[i].BOILIName == i18n.t('others') && (objGridDetails[i].DisplayName == null || objGridDetails[i].DisplayName == "")) {
                row["DisplayNameError"] = true;
                row["DisplayNameMsg"] = i18n.t("DisplayNameformetric") + objGridDetails[i].BOILIName + i18n.t("shouldnotbeblank")
                isDsplayErr = true;
                isErrorThere = true;
            }

            if (!isAsIsErr) {
                row["AsIsError"] = false;
                row["AsIsMsg"] = i18n.t("AsIsvalueisvalid");
            }
            if (!isToBeErr) {
                row["ToBeError"] = false;
                row["ToBeMsg"] = i18n.t("ToBevalueisvalid");
            }
            if (!isDsplayErr) {
                row["DisplayNameError"] = false;
                row["DisplayNameMsg"] = "";
            }
        })
        this.refs.Grd_LeadInd.gridOptions.api.setRowData(objGridDetails);

        this.setState({
            leadingIndicatorGridData: objGridDetails
        });
        let params = {
            force: true
        }
        this.refs.Grd_LeadInd.gridOptions.api.refreshCells(params);


        return !isErrorThere;
    }

    CheckFormula(objGridDetails, editRow) {
        var i = 0;
        var updatedGridDetails = [];
        var numStatus = false;
        for (i = 0; i < objGridDetails.length; i++) {

            var AsIs = parseFloat(objGridDetails[i].AsIs);
            var ToBe = parseFloat(objGridDetails[i].ToBe);



            if (objGridDetails[i].Maximum != null && objGridDetails[i].Minimum != null && objGridDetails[i].Maximum.toString() != "" && objGridDetails[i].Minimum.toString() != "") {
                var max = parseFloat(objGridDetails[i].Maximum);
                var min = parseFloat(objGridDetails[i].Minimum);

                if (AsIs < min) {
                    alert(i18n.t("MinimumAsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t('shouldnotbelessthan') + min.toString());
                    return false
                }
                if (AsIs > max) {
                    alert(i18n.t("MaximumAsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t('shouldnotbemorethan') + max.toString());
                    return false
                }
                if (ToBe < min) {
                    alert(i18n.t("MinimumToBevalueformetric") + objGridDetails[i].BOILIName + i18n.t('shouldnotbelessthan') + min.toString());
                    return false
                }
                if (ToBe > max) {
                    alert(i18n.t("MaximumToBevalueformetric") + objGridDetails[i].BOILIName + i18n.t('shouldnotbemorethan') + max.toString());
                    return false
                }
            }

            //Less than Equal to validation

            if (objGridDetails[i].LessThanEqulaTo != null) {
                if (objGridDetails[i].LessThanEqulaTo == true) {
                    if (AsIs < ToBe) {
                        alert(i18n.t("AsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t('shouldbegreaterthanequaltoToBevalue'));
                        return false
                    }
                }
                else if (objGridDetails[i].LessThanEqulaTo == false) {
                    if (AsIs > ToBe) {
                        alert(i18n.t("AsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t('shouldbelessthanequaltoToBevalue'));
                        return false
                    }
                }
            }

            //Zero Validation
            var k = 0;
            if (objGridDetails[i].IsZero != "" && objGridDetails[i].IsZero != null) {
                for (k = 0; k < objGridDetails.length; k++) {
                    if (objGridDetails[k].UniqueFormulaID == objGridDetails[i].IsZero) {
                        if (objGridDetails[k].AsIs != null && objGridDetails[k].AsIs.toString() == "0") {
                            objGridDetails[i].AsIs = 0;
                        }
                        if (objGridDetails[k].ToBe != null && objGridDetails[k].ToBe.toString() == "0") {
                            objGridDetails[i].ToBe = 0;
                        }
                    }
                }
            }


            // Formula Validation
            if (objGridDetails[i].Formula != "" && objGridDetails[i].Formula != null && objGridDetails[i].IsAutoCalclulate == true) {
                var FinalAsIS = "";
                var FinalToBe = "";
                var FormulaAsIs = objGridDetails[i].Formula;
                var FormulaToBe = objGridDetails[i].Formula;
                var Formula = objGridDetails[i].Formula;
                var formulaFields = Formula.match(/[^>=<+-/*()]+/g);
                var j = 0;

                for (j = 0; j < formulaFields.length; j++) {
                    // Checking For Formula Field Type (Number or Metric Unique ID) 
                    !isNaN(parseFloat(formulaFields[j])) && isFinite(formulaFields[j]) ? numStatus = true : numStatus = false;
                    if (numStatus == false) {
                        var result = objGridDetails.filter(function (el) {
                            return (el.UniqueFormulaID === formulaFields[j]);
                        });

                        if (result.length > 0) {
                            // Blank Value Check for Formula Fields.
                            if (result[0].AsIs == null || result[0].AsIs === "") {
                                FormulaAsIs = FormulaAsIs.replace(formulaFields[j], "");
                                // Assign Blank value for the formula contained Metric if any field of the formula will be blank.
                                objGridDetails[i].AsIs = "";
                            }
                            else {
                                FormulaAsIs = FormulaAsIs.replace(formulaFields[j], result[0].AsIs.toString());

                                // Formula >= and <= for AsIs

                                if (FormulaAsIs.includes(">=")) {
                                    if (objGridDetails[i].AsIs != null && objGridDetails[i].AsIs != "") {
                                        FormulaAsIs = objGridDetails[i].AsIs.toString() + FormulaAsIs;
                                        try {
                                            FinalAsIS = eval(FormulaAsIs);
                                            if (FinalAsIS == false) {
                                                alert(i18n.t("AsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t('shouldbetoAsIsvalueofmetric') + result[0].BOILIName + ".");
                                                return false;
                                            }
                                        }
                                        catch (err) {
                                            console.log("Error Encountered");
                                        }
                                    }
                                }
                                else if (FormulaAsIs.includes("<=")) {
                                    if (objGridDetails[i].AsIs != null && objGridDetails[i].AsIs != "") {
                                        FormulaAsIs = objGridDetails[i].AsIs.toString() + FormulaAsIs;
                                        try {
                                            FinalAsIS = eval(FormulaAsIs);
                                            if (FinalAsIS == false) {
                                                alert(i18n.t("AsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t('ShouldbetoAsisvalofmatric') + result[0].BOILIName + ".");
                                                return false;
                                            }
                                        }
                                        catch (err) {
                                            console.log("Error Encountered");
                                        }
                                    }
                                }
                            }

                            if (result[0].ToBe == null || result[0].ToBe === "") {
                                FormulaToBe = FormulaToBe.replace(formulaFields[j], "");
                                objGridDetails[i].ToBe = "";
                            }
                            else {
                                FormulaToBe = FormulaToBe.replace(formulaFields[j], result[0].ToBe.toString());

                                // Formula >= and <= for ToBe: Start

                                if (FormulaToBe.includes(">=")) {
                                    if (objGridDetails[i].ToBe != null && objGridDetails[i].ToBe != "") {
                                        FormulaToBe = objGridDetails[i].ToBe.toString() + FormulaToBe;
                                        try {
                                            FinalToBe = eval(FormulaToBe);
                                            if (FinalToBe == false) {
                                                alert(i18n.t("ToBevalueofmetric") + objGridDetails[i].BOILIName + i18n.t('shouldbetoToBevalueofmetric') + result[0].BOILIName + ".");
                                                return false;
                                            }
                                        }
                                        catch (err) {
                                            console.log("Error Encountered");
                                        }
                                    }
                                }
                                else if (FormulaToBe.includes("<=")) {
                                    if (objGridDetails[i].ToBe != null && objGridDetails[i].ToBe != "") {
                                        FormulaToBe = objGridDetails[i].ToBe.toString() + FormulaToBe;
                                        try {
                                            FinalToBe = eval(FormulaToBe);
                                            if (FinalToBe == false) {
                                                alert(i18n.t("ToBevalueofmetric") + objGridDetails[i].BOILIName + i18n.t('shouldbetoToBevalueofetric') + result[0].BOILIName + ".");
                                                return false;
                                            }
                                        }
                                        catch (err) {
                                            console.log("Error Encountered");
                                        }
                                    }
                                }
                                // Formula >= and <= for ToBe: End
                            }
                        }
                    }
                }

                try {
                    FinalAsIS = eval(FormulaAsIs);
                    FinalToBe = eval(FormulaToBe);
                }
                catch (err) {
                    console.log("Error Encountered");
                }

                //Max Min Validation after Formula calculation
                var num = false;
                !isNaN(parseFloat(FinalAsIS)) && isFinite(FinalAsIS) ? num = true : num = false;

                if (num == true) {
                    if (objGridDetails[i].Maximum.toString() != "" && objGridDetails[i].Minimum.toString() != "" && objGridDetails[i].Maximum != null && objGridDetails[i].Minimum != null) {
                        var max = parseFloat(objGridDetails[i].Maximum);
                        var min = parseFloat(objGridDetails[i].Minimum);

                        if (FinalAsIS < min) {
                            alert(i18n.t("MinimumAsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldnotbelessthan") + min.toString());
                            return false
                        }
                        if (FinalAsIS > max) {
                            alert(i18n.t("MaximumAsIsvalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldnotbemorethan") + max.toString());
                            return false
                        }
                        if (FinalToBe < min) {
                            alert(i18n.t("MinimumToBevalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldnotbelessthan") + min.toString());
                            return false
                        }
                        if (FinalToBe > max) {
                            alert(i18n.t("MaximumToBevalueformetric") + objGridDetails[i].BOILIName + i18n.t("shouldnotbemorethan") + max.toString());
                            return false
                        }
                    }
                    objGridDetails[i].AsIs = FinalAsIS;
                    objGridDetails[i].ToBe = FinalToBe;
                }
            }

            updatedGridDetails.push(objGridDetails[i]);
        }
        this.refs.Grd_LeadInd.gridOptions.api.setRowData(updatedGridDetails);
    }

    SaveLeadingIndicator(e) {
        var ValidDisplayName = true;
        $("#loader-div").show();
        this.setState({ showEffortPopup: false });
        this.refs.Grd_LeadInd.gridOptions.api.stopEditing();
        let evtSrc = e.target.text.trim();
        let metricNameBlankStatus = false;
        let grdData = this.refs.Grd_LeadInd.gridOptions.rowData;

        numericStatus = true;
        let numStatus = true;

        grdData.filter(item => item.hasOwnProperty('MetricName'))
            .map((item, i) => {
                var metricName = item.MetricName;
                if(metricName != ""){
                    metricNameBlankStatus = null;
                }
                else{
                    metricNameBlankStatus = true;
                }
            });

        if (metricNameBlankStatus == true) {
            alert(i18n.t("PleaseselectMetricName"));
            $("#loader-div").hide();
            return false;
        }

        grdData.filter(item => item.hasOwnProperty('AsIs'))
            .map((item, i) => {
                var valAsIs = item.AsIs;
                var valToBe = item.ToBe;
                var DisplayName = item.DisplayName;
                let DisplayNameValidation = inputValidator(DisplayName);
                if (DisplayNameValidation) {
                    ValidDisplayName = false;
                }
                if (evtSrc == i18n.t('save') && valAsIs != "" && valAsIs != null) {
                    if(!isNaN(parseFloat(valAsIs)) && isFinite(valAsIs)){
                        numStatus = null;
                    }
                    else{
                        numStatus = false;
                    }
                }
                if (evtSrc == i18n.t('save') && valToBe != "" && valToBe != null) {
                    if(!isNaN(parseFloat(valToBe)) && isFinite(valToBe)){
                        numStatus = null;
                    }
                    else{
                        numStatus = false;
                    }
                }
                if(!isNaN(parseFloat(valAsIs)) && isFinite(valAsIs)){
                    numericStatus = null;
                }
                else{
                    numericStatus = false;
                }
                if(!isNaN(parseFloat(valToBe)) && isFinite(valToBe)){
                    numericStatus = null;
                }
                else{
                    numericStatus = false;
                }
            });

        if (evtSrc == i18n.t('save') && numStatus == false) {
            alert(i18n.t("pleaseenternumericvalues"));
            $("#loader-div").hide();
            return false;
        }

        if (evtSrc != i18n.t('save') && numericStatus == false) {

            grdData.filter(item => item.hasOwnProperty('AsIs'))
                .map((item, i) => {
                    var valAsIs = item.AsIs;
                    var valToBe = item.ToBe;

                    if(!isNaN(parseFloat(valAsIs)) && isFinite(valAsIs)){
                        numStatus = null;
                    }
                    else{
                        numStatus = false;
                    }
                    if(!isNaN(parseFloat(valToBe)) && isFinite(valToBe)){
                        numStatus = null;
                    }
                    else{
                        numStatus = false;
                    }
                });

            if (numStatus == false) {
                alert(i18n.t("PleaseenternumericvaluesAsIsToBeselectedmetrics"));
                $("#loader-div").hide();
                return false;
            }
        }

        var LeadingIndicatorDetails = {
            "liDetails": grdData
        };
        this.setState({
            isEditAcitve: false
        })
        var ValidStatusFormula = true;
        if (evtSrc != i18n.t('save')) {
            ValidStatusFormula = this.checkCells();
        }
        if (ValidStatusFormula != false) {
            if (ValidDisplayName) {
                request("/LeadingIndicator/SaveLIDraftDetails?LanguageID=" + this.props.languageId, 'POST', LeadingIndicatorDetails)
                    .then(res => {
                        if (res.outputmessage == i18n.t('success')) {
                            alert(i18n.t("LeadingIndicatorSavedSuccessfully"));
                            if (evtSrc != i18n.t('save')) {
                                this.props.history.push("/PMMAssessment")
                            }
                        }
                        else {
                            alert(i18n.t("LeadingIndicatorNotSaved"));
                        }
                        $("#loader-div").hide();
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                        $("#loader-div").hide();
                    });
            }
            else {
                alert(i18n.t("PleaseEntervalidValuesintheFields"));
                $("#loader-div").hide();
            }

        }
        else {
            alert(i18n.t("Pleaseentvalidvalehighlightedfieldsmovingnext"));
            $("#loader-div").hide();
        }


    }

    CreateNewRowData() {
        let LIDetails = this.refs.Grd_LeadInd.gridOptions.rowData;

        var newData = {
            MetricId: LIDetails[0].OthersTypes[0].BOILIId,
            BOILIId: LIDetails[0].OthersTypes[0].BOILIId,
            BOILIName: LIDetails[0].OthersTypes[0].BOILIName,
            BOILIDefinition: '',
            MetricName: LIDetails[0].OthersTypes[0].BOILIName,
            PMMProcessDraftID: '',
            LIDraftId: "0",
            Benchmark: '',
            Median: '',
            DisplayName: '',
            AsIs: '',
            ToBe: '',
            BOIImpacted: '',
            BOIImpactedTypes: BOIList,
            CreatedBy: this.props.enterpriseId,
            loggedInUserId: this.props.enterpriseId
        };

        newCount++;
        return newData;
    }

    onGridReady = (params) => {

        var SearchedString = "";
        let data = {
            UserName: this.props.enterpriseId,
            BOILIId: 0,
            PMMProcessDraftID: this.props.configDetails.PMMProcessDraftID,
            IndustryID: this.props.configDetails.OGIndustry.IndustryId,
            SCID: this.props.configDetails.ServiceComponent.value,
            GroupID: this.props.groupID,
            SearchedString: SearchedString,
            LanguageID: this.props.languageID
        }

        params.api.showLoadingOverlay();

        request("/LeadingIndicator/GetLeadingIndicatorDetails", 'POST', data)
            .then(response => {

                if (response.length > 0) {

                    params.api.hideOverlay();

                    this.setState({ leadingIndicatorGridData: response });
                    this.checkCells();

                    var result = response.filter(function (obj) {
                        return (obj.BOILIName === i18n.t('others'));
                    });
                    if (result.length > 0) {
                        this.setState({ deleteStatus: true });
                    }

                    if (response[0].BOIImpactedTypes.length > 0) {
                        this.setState({ BOIList: response[0].BOIImpactedTypes });
                        BOIList = response[0].BOIImpactedTypes;
                    }
                }

                else {
                    params.api.showNoRowsOverlay();
                }

            })
            .catch(error => {
                console.log("Error Encountered");
                $("#loader-div").hide();
            });

        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        params.api.sizeColumnsToFit();
    }

    onCellClicked(e) {
        if (e.colDef.headerName == i18n.t("delete") && e.data.BOILIName == i18n.t('others')) {
            if (confirm(i18n.t("deleteConfirmation"))) {
                let LIDetails = this.refs.Grd_LeadInd.gridOptions.rowData;
                LIDetails.splice(e.rowIndex, 1);
                var result = LIDetails.filter(function (obj) {
                    return (obj.BOILIName == i18n.t('others'));
                });
                this.refs.Grd_LeadInd.gridOptions.api.setRowData(LIDetails);

                if (result.length == 0) {
                    this.setState({ deleteStatus: false });
                }
                this.refs.Grd_LeadInd.gridOptions.api.sizeColumnsToFit();
            }
        }
        else if (e.colDef.field == "BOILIName") {
            this.setState({
                popUpBenchmark: true,
                selectedMetric: e.data.BOILIName
            })
        }
    }

    onCellEdit(params) {

        if (params.colDef.headerName == i18n.t("As-Is") || params.colDef.headerName == i18n.t("To-Be") || params.colDef.headerName == i18n.t('displayName')) {


            let LIDetails = this.refs.Grd_LeadInd.gridOptions.rowData;
            this.checkCells();

        }
    }

    onOverallEdit(params) {

        this.setState({
            isEditAcitve: true,
            popUpDisplay: false,
            showEffortPopup: false
        });
    }

    onNavigationClick(eve) {
        let navigationPage = eve.target.getAttribute('data-content');
        let url = eve.target.getAttribute('data-url');
        if (this.state.isEditAcitve == true) {
            this.setState({
                popUpDisplay: true,
                toURL: url,
                navigationPage: navigationPage,
                showEffortPopup: false
            })
        }
        else {
            if (navigationPage == "HOME") {
                this.setState({
                    popUpDisplay: true,
                    toURL: url,
                    navigationPage: navigationPage,
                    showEffortPopup: false
                })
            }
            else if (navigationPage == "Take New Assessment") {
                var data = {
                    LoggedInUserId: this.props.enterpriseId,
                    PMMProcessDraftID: this.props.configDetails.PMMProcessDraftID,
                    FlagId: 2
                };
                request("/Common/UpsertLockAndUnlockPMMProcessDraft?LanguageID=" + this.props.languageId, 'POST', data)
                    .then(res => {
                        $("#loader-div").hide();
                    })
                    .catch(error => {
                        console.log("Error Encountered");
                        $("#loader-div").hide();
                    });

                this.props.history.push(url);
            }
            else {
                this.props.history.push(url);
            }
        }

    }

    closeNavigationPopUp() {
        this.setState({
            popUpDisplay: false,
            showEffortPopup: false
        })
    }

    onQuickFilterText(event) {
        this.setState({ quickFilterText: event.target.value });
    }

    DownloadExcel() {
        // to remove 'leave this page?' popup on download button
        var objThis = this;        
        // if(!this.props.configDetails.DealName.label.toLowerCase().includes("test")){
        // window.tfo_dataevent("PMM-D-2", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1,this.props.configDetails.DealName.label)
        // }
        window.removeEventListener("beforeunload", this.unloadEvent);
        let excelData = {
            "PMMProcessDraftID": this.props.configDetails.PMMProcessDraftID,
            "DealName": this.props.configDetails.DealName.label,
            "SCName": this.props.configDetails.ServiceComponent.label,
            "UserName": this.props.enterpriseId,
            "GroupID": this.props.groupId,
            "LanguageID": this.props.languageId
        }
        request("/Common/DownloadtoExcelDraft", 'POST', excelData)
            .then(response => {
                var filePath = response.Table1[0].Value;
                window.location.href = filePath;
            })
            .catch(error => {
                console.log("Error Encountered");
                window.addEventListener("beforeunload", objThis.unloadEvent);
            });

    }

    DownloadReferenceContentExcel() {
        var objThis = this;        
        // if(!this.props.configDetails.DealName.label.toLowerCase().includes("test")){
        // window.tfo_dataevent("PMM-D-3", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1, this.props.configDetails.DealName.label)
        // }
        window.removeEventListener("beforeunload", this.unloadEvent);
        let excelData = {
            "PMMProcessDraftID": this.props.configDetails.PMMProcessDraftID,
            "DealName": this.props.configDetails.DealName.label,
            "SCName": this.props.configDetails.ServiceComponent.label,
            "UserName": this.props.enterpriseId,
            "GroupID": this.props.groupId,
            "LanguageID": this.props.languageId
        }
        request("/Common/DownloadtoExcelConfig", 'POST', excelData)
            .then(response => {

                var filePath = response.Table1[0].Value;
                window.location.href = filePath;
            })
            .catch(error => {
                window.addEventListener("beforeunload", objThis.unloadEvent);
                console.log("Error Encountered");
            });

    }

    ShowEffortPopup() {
        // if(!this.props.configDetails.DealName.label.toLowerCase().includes("test")){
        // window.tfo_dataevent("PMM-Ass-1", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"], 1, this.props.configDetails.DealName.label)
        // }
        this.setState({
            showEffortPopup: true
        });
    }

    CloseEffortPopup() {
        this.setState({
            showEffortPopup: false
        });
    }

    closeBenchmarkPopUp() {
        this.setState({
            popUpBenchmark: false
        });
    }

    render() {
        var columnDefs = [];
        let enterpriseId = this.props.enterpriseId
        if (this.state.deleteStatus == false) {
            columnDefs = [

                {
                    headerName: i18n.t('metrics'), field: 'BOILIName', cellRenderer: CreateDropdown, suppressMovable: true, suppressResize: true, tooltipField: 'BOILIDefinition', cellStyle: function (params) {
                        params.node.data.loggedInUserId = enterpriseId;
                        var background = "";
                        if (params.node.data.Formula != "" && params.node.data.IsAutoCalclulate == true) {
                            background = "#ffc200b8";
                        }
                        return {
                            background: background
                        };
                    }, autoHeight: true
                },
                { headerName: i18n.t('InternalBenchmark'), field: 'Benchmark', suppressMovable: true, suppressResize: true, tooltipField: 'Benchmark', autoHeight: true },
                { headerName: i18n.t('ExternalBenchmark'), field: 'Median', suppressMovable: true, suppressResize: true, hide: true, tooltipField: 'Median', autoHeight: true },
                { headerName: i18n.t('BOIKeyBusinessMetricImpacted'), field: 'BOIImpacted', cellRendererFramework: BOISelect, suppressMovable: true, suppressResize: true, width: 300, autoHeight: true }, // cellRenderer: CreateTextbox,                
                {
                    headerName: i18n.t('As-Is'), field: 'AsIs', suppressMovable: true, suppressResize: true, cellStyle: function (params) {

                        let background = params.data.AsIsError ? "#E57373" : "#4990a052"
                        return {
                            background: background
                        };
                    }, editable: function (params) {
                        let IsEditable = true;
                        if (params.node.data.Formula != "" && params.node.data.IsAutoCalclulate == true) {
                            IsEditable = false;
                        }
                        return IsEditable
                    },
                    tooltipField: 'AsIsMsg', width: 100
                },
                {
                    headerName: i18n.t('To-Be'), field: 'ToBe', suppressMovable: true, suppressResize: true, cellStyle: function (params) {

                        let background = params.data.ToBeError ? "#E57373" : "#4990a052"
                        return {
                            background: background
                        };
                    }, editable: function (params) {
                        let IsEditable = true;
                        if (params.node.data.Formula != "" && params.node.data.IsAutoCalclulate == true) {
                            IsEditable = false;
                        }
                        return IsEditable
                    },
                    tooltipField: 'ToBeMsg', width: 100
                },
                {
                    headerName: i18n.t('Remark'), field: 'DisplayName', cellStyle: function (params) {

                        let background = params.data.DisplayNameError ? "#E57373" : "#4990a052"
                        return {
                            background: background
                        };
                    }, tooltipField: 'DisplayNameMsg', suppressMovable: true, suppressResize: true, editable: true, autoHeight: true
                },
            ];
        }
        else if (this.state.deleteStatus == true) {
            columnDefs = [
                {
                    headerName: i18n.t('metrics'), field: 'BOILIName', cellRenderer: CreateDropdown, suppressMovable: true, suppressResize: true, tooltipField: 'BOILIDefinition', cellStyle: function (params) {
                        params.node.data.loggedInUserId = enterpriseId;
                        var background = "";
                        if (params.node.data.Formula != "" && params.node.data.IsAutoCalclulate == true) {
                            background = "#ffc200b8";
                        }
                        return {
                            background: background
                        };
                    }
                },
                { headerName: i18n.t('InternalBenchmark'), field: 'Benchmark', suppressMovable: true, suppressResize: true, tooltipField: 'Benchmark', autoHeight: true },
                { headerName: i18n.t('Median'), field: 'Median', suppressMovable: true, suppressResize: true, hide: true, tooltipField: 'Median', autoHeight: true },            
                { headerName: i18n.t('BOIKeyBusinessMetricImpacted'), field: 'BOIImpacted', cellRendererFramework: BOISelect, suppressMovable: true, suppressResize: true, width: 300, autoHeight: true }, // cellRenderer: CreateTextbox,
                {
                    headerName: i18n.t('As-Is'), field: 'AsIs', suppressMovable: true, suppressResize: true, cellStyle: function (params) {

                        let background = params.data.AsIsError ? "#E57373" : "#4990a052"
                        return {
                            background: background
                        };
                    }, editable: function (params) {
                        let IsEditable = true;
                        if (params.node.data.Formula != "" && params.node.data.IsAutoCalclulate == true) {
                            IsEditable = false;
                        }
                        return IsEditable
                    },
                    tooltipField: 'AsIsMsg', width: 100
                },
                {
                    headerName: i18n.t('To-Be'), field: 'ToBe', suppressMovable: true, suppressResize: true, cellStyle: function (params) {

                        let background = params.data.ToBeError ? "#E57373" : "#4990a052"
                        return {
                            background: background
                        };
                    }, editable: function (params) {
                        let IsEditable = true;
                        if (params.node.data.Formula != "" && params.node.data.IsAutoCalclulate == true) {
                            IsEditable = false;
                        }
                        return IsEditable
                    },
                    tooltipField: 'ToBeMsg', width: 100
                },{
                    headerName: i18n.t('Remark'), field: 'DisplayName', cellStyle: function (params) {

                        let background = params.data.DisplayNameError ? "#E57373" : "#4990a052"
                        return {
                            background: background
                        };
                    }, tooltipField: 'DisplayNameMsg', editable: true
                },
                { headerName: i18n.t('delete'), suppressMovable: true, suppressResize: true, cellRenderer: CreateDelete, width: 100 }
            ];
        }


        var LeadingIndicatorDataGrid = (
            <div style={{ height: '425px' }} className="ag-theme-material custom-select padding5">
                <AgGridReact ref="Grd_LeadInd" id="grd_LeadIndicator"
                    paginationPageSize="10"
                    enableColResize={false}
                    columnDefs={columnDefs}
                    rowData={this.state.leadingIndicatorGridData}
                    rowSelection="single"
                    onGridReady={this.onGridReady.bind(this)}
                    enableSorting={false}
                    quickFilterText={this.state.quickFilterText}
                    rowmodeltype="pagination"
                    suppressScrollOnNewData={true}
                    onCellClicked={this.onCellClicked.bind(this)}
                    singleClickEdit={true}
                    onCellEditingStarted={this.onOverallEdit.bind(this)}
                    onCellEditingStopped={this.onCellEdit.bind(this)}
                    listOptions={this.state.BOIList}
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate={`<span class="ag-overlay-loading-center">${i18n.t('dataLoading')}</span>`} />
            </div>
        )

        return (
            <div>
                <section className="content">
                    <div className="innerpage-hd"><span className="menu2" onClick={this.onNavigationClick} data-content="HOME" data-url="/Home"><a type="button" className="btn btn-info btn-back" data-content="HOME" data-url="/Home"><img src={backArrowIcon} data-content="HOME" data-url="/Home" /> {i18n.t('bACKTOHOME')}</a></span>
                        <ul className="pagecounter"  >
                            <li onClick={this.onNavigationClick} data-url="/CreateNewAssessment"><span title={i18n.t("takeNewAssessment")} className="linkSpan" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Take New Assessment" data-url="/CreateNewAssessment"></span></li>
                            <li onClick={this.onNavigationClick} data-url="/BOI"><span title={i18n.t("BOIKeyBusinessMetric")} className="current linkSpan" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="BOI" data-url="/BOI"></span></li>
                            <li><span title={i18n.t("lEADINGINDICATOR")} className="current" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Leading Indicator" data-url="/LeadingIndicator">...</span></li>
                            <li><span title={i18n.t("pMMASSESSMENT")} className="" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="PMM" data-url="/PMMAssessment">3</span></li>
                            <li><span title={i18n.t("sTORYBOARDGAPASSESSMENT")} className="" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Storyboard" data-url="/Storyboard">4</span></li>
                            <li><span title={i18n.t("pOTENTIALPROJECTS")} className="" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Potential Projects">5</span></li>
                            <li><span title={i18n.t("pROJECTPRIORITIZATION")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Project Prioritization">6</span></li>


                        </ul>
                        {this.state.popUpDisplay && <NavigationConfirmation show={this.state.popUpDisplay} navigationPage={this.state.navigationPage} isEditAcitve={this.state.isEditAcitve} url={this.state.toURL} closeCallback={this.closeNavigationPopUp} propPMMProcessDraftID={this.props.configDetails.PMMProcessDraftID} />}
                    </div>
                    <section className="bodydata">
                        <div className="bodydata-full">
                            <div className="box-plain">
                                <h4><div className="pull-left" title={i18n.t("boiBusinessMetricLeaddIndiList")}>
                                    {i18n.t("lEADINGINDICATOR")}
                                </div>
                                    <div className="pull-right">
                                        <span class='statustxt-orng-italic' title={i18n.t('deal') + ': ' + this.props.configDetails.DealName.label}>{this.props.configDetails.DealName.label}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('region') + ': ' + this.props.configDetails.Region.label}>{this.props.configDetails.Region.label}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('country') + ': ' + this.props.configDetails.Country.label}>{this.props.configDetails.Country.label}
                                        {/* </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('oG') + ': ' + this.props.configDetails.OGIndustry.OGName}>{this.props.configDetails.OGIndustry.OGName} */}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('Industry') + ': ' + this.props.configDetails.OGIndustry.IndustryName}>{this.props.configDetails.OGIndustry.IndustryName}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('capability') + ': ' + this.props.configDetails.Capability.label}>{this.props.configDetails.Capability.label}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('tower') + ': ' + this.props.configDetails.Tower.label}>{this.props.configDetails.Tower.label}
                                        </span> &nbsp;&nbsp;|| <span class='statustxt-orng-italic' title={i18n.t('serviceComponent') + ': ' + this.props.configDetails.ServiceComponent.label}>{this.props.configDetails.ServiceComponent.label}</span>
                                    </div>
                                </h4>

                                <div className="row">
                                    <div className="col-md-3 infoblockfilter ">
                                        <label className="col-md-2" id="lbl_searchLI" style={{ marginTop: "10px" }}>{i18n.t('search')}:</label>
                                        <input type="text" id="txt_searchTextLI" className="col-md-9 searchText pull-right" onChange={this.onQuickFilterText.bind(this)} placeholder={i18n.t("Typetexttofilter")} />
                                    </div>
                                    <div className="col-md-7">
                                        <ul className="grid-legend ulStyle">
                                            <li className="liStyle">
                                                <span className="formula-span">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                <span className="formula-span-gap"> {i18n.t('Metricautocalculatedbytool')} </span>
                                                <span className="error-span">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                                <span> {i18n.t('ValidationErrorRecheckAsIs')} </span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-md-2 btn-group LIAdandDownload">
                                        <button type="button" id="btn_liadd" className="btn btn-default LIaddbutton" onClick={this.onAddRow.bind(this)} >
                                            <img src={addIcon} />{i18n.t('add')}
                                        </button>
                                        <button type="button" id="btn_downloadSavedScreensLI" title={i18n.t("DownloadSavedScreensforthisAssessment")} className="btn btn-default" onClick={this.DownloadExcel}>
                                            <img src={downloadIcon} />
                                        </button>
                                        <button type="button" id="btn_downloadReferencePMMTemplateLI" title={i18n.t("ReferencePMMTemplate")} className="btn btn-default pull-right" onClick={this.DownloadReferenceContentExcel}>
                                            <img src={downloadBrownIcon} />
                                        </button>
                                    </div>

                                </div>
                                <div className="box-plain pmm-asmt-tbl LI-div">
                                    {LeadingIndicatorDataGrid}

                                    <div className="btn-group-md">
                                        {this.state.EffortGridData.length > 0 ? <a type="button" className="btn btn-warning" onClick={this.ShowEffortPopup}>{i18n.t('updateEffort')}</a> : null}
                                        <a type="button" className="btn btn-danger" onClick={this.SaveLeadingIndicator.bind(this)}>{i18n.t('save')}</a>
                                        <a type="button" onClick={this.SaveLeadingIndicator.bind(this)} className="btn btn-success">{i18n.t('takePMMAssessment')}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
                {this.state.showEffortPopup == true ? <PMMEffort show={this.state.showEffortPopup} close={this.CloseEffortPopup.bind(this)} propsConfigDetails={this.props.configDetails} /> : null}
                {this.state.popUpBenchmark && <BenchmarkPopup show={this.state.popUpBenchmark} closeCallback={this.closeBenchmarkPopUp} selectedMetric={this.state.selectedMetric} dealId={this.props.configDetails.DealName.value} industry={this.props.configDetails.OGIndustry.IndustryName} />}
            </div >
        );
    }
}

function mapStateToProps(state) {
    return {
        languageId: state.languageId,
        enterpriseId: state.enterpriseId,
        groupId: state.groupId,
        roleName: state.roleName,
        pageName: state.pageName,
        groupName: state.groupName,
    }
}

LeadingIndicatorGrid = connect(mapStateToProps)(withRouter(LeadingIndicatorGrid))
export default LeadingIndicatorGrid