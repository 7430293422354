/* eslint no-restricted-globals: "off",no-unused-expressions: "off", curly: "error" */
import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import $ from 'jquery';
import backArrowIcon from '../../images/icon-back-arrow.png';
import LandscapeGraph from './LandscapeGraph';
import request from '../../services/Service';
import { Typeahead } from 'react-bootstrap-typeahead';
import i18n from 'i18next';
import langKeys from '../../Utils/LangKeys'
import { withTranslation } from 'react-i18next';
import { AgGridLocale } from '../../Utils/AgGridLocale';


var recordCount = 0;

function createCircelText() {
    try {
        this.eGui = document.createElement('div');
        this.eGui.className = "row"
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

createCircelText.prototype.init = function (params) {
    try {
        let spanValue = document.createElement('span');
        spanValue.className = "AsIsToBeLabel";
        let circle = document.createElement('div');
        circle.className = "circleDivCompare";
        spanValue.textContent = params.value;
        if (params.data.DealName.trim() != i18n.t('benchmark_CompareScreen')) {
            if (params.value != "" && params.value >= 0 && params.value < 2) {
                circle.style.backgroundColor = "#E57373"
            }
            else if (params.value != "" && params.value >= 2 && params.value < 3) {
                circle.style.backgroundColor = "#FFB74D"
            }
            else if (params.value != "" && params.value >= 3 && params.value < 3.5) {
                circle.style.backgroundColor = "#FFEB3B"
            }
            else if (params.value != "" && params.value >= 3.5 && params.value <= 4) {
                circle.style.backgroundColor = "#81C784"
            }
            if (params.value != null) {
                this.eGui.appendChild(circle);
                this.eGui.appendChild(spanValue);
            }
        }
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

createCircelText.prototype.getGui = function (params) {
    try {
        return this.eGui;
    }
    catch (error) {
        console.log("Error Encountered");
    }
}

class PMMReports extends React.Component {
    constructor(props) {
        super(props);
        try {
            recordCount = 0;
            this.state = {
                prevAssessmentGridData: [],
                disableCompare: true,
                showComparePopup: false,
                headerValue: "",
                lstDeal: [],
                lstIndustry: [],
                lstRegion: [],
                lstCapability: [],
                lstCountry: [],
                lstTower: [],
                lstServiceComp: [],
                lstDlvryLoc: [],
                lstLever: [],
                DealValue: [],
                IndustryValue: "",
                RegionValue: [],
                OGIndustry: "",
                OG: "",
                Industry: "",
                CapabilityValue: [],
                CountryValue: { "label": i18n.t('all'), "value": "0" },
                TowerValue: [],
                ServiceCompValue: [],
                DlvryLocValue: { "label": i18n.t('all'), "value": "0" },
                eblNxtBTn: false,
                Confirm: false,
                message: "",
                DraftID: 0,
                comparisionGridData: [],
                popUpLandscapeGraph: false,
                GraphReportArray: [],
                AllOverallScoreArray: [],
                AllStandardizationScoreArray: [],
                AllDealnameArray: [],
                AllRegionArray: [],
                nonEmptyLeverNames: []
            };
            this.reSizeGrid = this.reSizeGrid.bind(this);
            this.closepopUpLandscapeGraph = this.closepopUpLandscapeGraph.bind(this);
            this.filterCallbackTypeahead = this.filterCallbackTypeahead.bind(this);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentDidMount() {
        try {           
            //window.tfo_event("PMM-VSA-15", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            $("#loader-div").show();
            window.addEventListener('resize', this.reSizeGrid, true);


            var DealID = 0;
            var SCID = 0;
            var DeliveryLocationId = 0;
            let dealData = {
                "DealId": 0,
                "PermissionLevel": "3,2,1",
                "UserName": this.props.enterpriseId,
			    "GroupID": this.props.groupId,
			    "LanguageID":this.props.languageId
            }
            request("/Common/GetDealBasedOnUserAccess", 'POST', dealData)
                .then(response => {
                    response.length > 0 ? this.setState({ lstDeal: response }) : this.setState({ lstDeal: [] });
                    $("#loader-div").hide();
                })
                .catch(error => {
                    console.log(error);
                    $("#loader-div").hide();
                });

            let dropdownData = {
                "UserName": this.props.enterpriseId,
                "ID": 0,
                "DealId": 0,
                "GroupID": this.props.groupId,
                "LanguageID": this.props.languageId
            }
            request("/Common/DropDownsValue", 'POST', dropdownData)
                .then(response => {
                    this.setState({ lstLever: [...new Set(response.lever.map(x=>x.label))] });

                })
                .catch(error => {
                    console.log("Error Encountered");
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    componentWillUnmount() {
        try {
            window.removeEventListener('resize', this.reSizeGrid, true);
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    reSizeGrid() {
        try {
            this.refs.Grd_Comparision.gridOptions.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    gridReadyFun(eve) {
        try {
            eve.api.sizeColumnsToFit();
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectDeal(val) {
        try {
            $("#loader-div").show();
            this.setState({
                showComparePopup: false, lstServiceComp: [], ServiceCompValue: [], lstCapability: [],
                CapabilityValue: [], lstTower: [], TowerValue: []
            });
            if (val.length != 0) {
                this.setState({ DealValue: val });

                let ogData = {
                    "DealId": val[0].value,
                    "UserName": this.props.enterpriseId,
			        "GroupID": this.props.groupId,
			        "LanguageID":this.props.languageId
                }
                request("/Common/GetOGBasedOnDeal", 'POST', ogData)
                    .then(response => {
                        this.setState({ OGIndustry: response[0] })
                        $("#loader-div").hide();
                    })
                    .catch(error => {
                        console.log(error);
                        $("#loader-div").hide();
                    });

                let capabilityData = {
                    "ID": 0,
                    "DealId": val[0].value,
                    "DLId": 0,
                    "PermissionLevel": "3,2,1",
                    "UserName": this.props.enterpriseId,
			        "GroupID": this.props.groupId,
			        "LanguageID":this.props.languageId
                }
                request("/Common/GetCapabilityBasedOnUserAccess", 'POST', capabilityData)
                    .then(response => {
                        response.length > 0 ? this.setState({ lstCapability: response }) : this.setState({ lstCapability: [] });

                    })
                    .catch(error => {
                        console.log(error);
                    });

                this.setState({
                    lstRegion: [], RegionValue: []
                });
                request("/MasterScreens/GetRegionBasedOnDeal?DealId=" + val[0].value + "&Flag=1&GroupID=" + this.props.groupId + "&LanguageID=" + this.props.languageId, 'GET')
                .then(response => {
                        if (response.length > 0) {
                            this.setState({ RegionValue: [{ label: i18n.t('all'), value: 0 }] });
                            this.setState({ disableCompare: false });
                        }
                        this.setState({ lstRegion: response });
                    })
                    .catch(error => {
                        console.log(error);
                    });

                let dealData = {
                    "DeliveryLocationId": 0,
                    "DealId": val[0].value,
                    "PermissionLevel": "3,2,1",
                    "UserName": this.props.enterpriseId,
			        "GroupID": this.props.groupId,
			        "LanguageID":this.props.languageId
                }
                request("/Common/DLDropDownBasedOnUserAccess", 'POST', dealData)
                    .then(response => {
                        response.length > 0 ? this.setState({ lstDlvryLoc: response }) : this.setState({ lstDlvryLoc: [] });
                        $("#loader-div").hide();
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
            else {
                this.ClearFilter();
                $("#loader-div").hide();
            }
        }

        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectIndustry(val) {
        try {
            this.setState({ IndustryValue: val });
            this.setState({ showComparePopup: false });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectRegion(val) {
        try {
            this.setState({ RegionValue: val });
            this.setState({ showComparePopup: false });
            val[0].label == i18n.t('all') ? this.setState({ disableCompare: false }) : this.setState({ disableCompare: true });

            var dealId = this.state.DealValue;
            if (val.length != 0) {
                request("/MasterScreens/GetCountryBasedOnRegion?DealId=" + dealId.value + "&RegionId=" + val[0].value + "&GroupID=" + this.props.groupId + "&LanguageID=" + this.props.languageId, 'GET')
                .then(response => {
                        this.setState({ lstCountry: response });
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
            else {
                this.setState({ RegionValue: [], CapabilityValue: [], TowerValue: [], ServiceCompValue: [] });
                $("#loader-div").hide();
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectCapability(val) {
        try {
            $("#loader-div").show();
            this.setState({ CapabilityValue: val });
            this.setState({ showComparePopup: false });
            if (val.length != 0) {
                let towerData = {
                    "TowerID": 0,
                    "DealId": this.state.DealValue[0].value,
                    "CapabilityId": val[0].value,
                    "DeliveryLocationId": 0,
                    "DLId": 0,
                    "PermissionLevel": "3,2,1",
                    "UserName": this.props.enterpriseId,
			        "GroupID": this.props.groupId,
			        "LanguageID":this.props.languageId
                }
                request("/Common/GetTowerBasedOnUserAccess", 'POST', towerData)
                    .then(response => {
                        this.setState({ TowerValue: [], lstTower: response, lstServiceComp: [], ServiceCompValue: [], eblNxtBTn: false });
                        $("#loader-div").hide();
                    })
                    .catch(error => {
                        console.log(error);
                        $("#loader-div").hide();
                    });
            }
            else {
                this.setState({ CapabilityValue: [], TowerValue: [], ServiceCompValue: [] });
                $("#loader-div").hide();
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectCountry(val) {
        try {
            this.setState({ CountryValue: val });
            val[0].label == i18n.t('all') && this.state.RegionValue[0].label == i18n.t('all') ? this.setState({ disableCompare: false }) : this.setState({ disableCompare: true });
            this.setState({ showComparePopup: false });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectTower(val) {
        try {
            $("#loader-div").show();
            this.setState({ TowerValue: val });
            this.setState({ showComparePopup: false });
            if (val.length != 0) {
                let scData = {
                    "TowerID": val[0].value,
                    "SCId": 0,
                    "UserName": this.props.enterpriseId,
			        "GroupID": this.props.groupId,
			        "LanguageID":this.props.languageId
                }
                request("/Common/GetSCBasedOnTower", 'POST', scData)
                    .then(response => {
                        let SCList = response != "" ? response : [];
                        this.setState({ ServiceCompValue: [], lstServiceComp: SCList });
                        $("#loader-div").hide();
                    })
                    .catch(error => {
                        console.log(error);
                        $("#loader-div").hide();
                    });
            }
            else {
                this.setState({ TowerValue: [], ServiceCompValue: [] });
                $("#loader-div").hide();
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectServiceComponent(val) {
        try {
            this.setState({ ServiceCompValue: val });
            this.setState({ showComparePopup: false });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    SelectDlvryLoc(val) {
        try {
            this.setState({ DlvryLocValue: val });
            this.setState({
                showComparePopup: false,
                lstServiceComp: [], ServiceCompValue: [], lstCapability: [],
                CapabilityValue: [], lstTower: [], TowerValue: []
            });
            if (val != null) {
                let capabilityData = {
                    "ID": 0,
                    "DealId": this.state.DealValue[0].value,
                    "DeliveryLocationId": val[0].value,
                    "UserName": this.props.enterpriseId,
			        "GroupID": this.props.groupId,
			        "LanguageID":this.props.languageId
                }
                request("/Common/GetCapabilityBasedOnDeal", 'POST', capabilityData)
                    .then(response => {
                        this.setState({ lstCapability: response })
                    })
                    .catch(error => {
                        console.log(error);
                    });
            }
            else {
                $("#loader-div").hide();
            }
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    ClearFilter() {
        try {
            this.setState({
                disableCompare: true, lstIndustry: [], lstRegion: [], lstCapability: []
                , lstTower: [], lstServiceComp: [], lstDlvryLoc: [], DealValue: [], RegionValue: []
                , CountryValue: { "label": i18n.t('all'), "value": "0" }, CapabilityValue: []
                , TowerValue: [], ServiceCompValue: [], prevAssessmentGridData: "", showComparePopup: false
                , comparisionGridData: []
            });
            recordCount = 0;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    ShowComparisonPopup() {
        try {
            if (this.state.RegionValue != [] && this.state.RegionValue[0].label == i18n.t('all')) {

                if (this.state.DealValue == [] || this.state.DealValue.length == [0]) {
                    alert(i18n.t("pleaseselectDeal"));
                    return false;
                }

                if (this.state.CapabilityValue == [] || this.state.CapabilityValue.length == [0]) {
                    alert(i18n.t("pleaseSelectCapability"));
                    return false;
                }

                if (this.state.TowerValue == [] || this.state.TowerValue.length == [0]) {
                    alert(i18n.t("pleaseSelectTower"));
                    return false;
                }

                if (this.state.ServiceCompValue == [] || this.state.ServiceCompValue.length == [0]) {
                    alert(i18n.t("pleaseSelectServiceComponent"));
                    return false;
                }
            }
            $("#loader-div").show();
            // if(!this.state.DealValue[0].label.toLowerCase().includes("test")){
            // window.tfo_dataevent("PMM-VSA-22", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"],1,this.state.DealValue[0].label)
            // }
            var SearchedString = "";
            let viewDealWiseData = {
                DealID:this.state.DealValue[0].value,
                RegionID:0,
                DeliveryLocationId:0,
                SCID:this.state.ServiceCompValue[0].value,
                SearchedString:SearchedString,
                UserName:this.props.enterpriseId,				
			    GroupID: this.props.groupId,
			    LanguageID:this.props.languageId
            }
            Promise.all([
                request("/PMM/ViewDealWiseComparison", 'POST', viewDealWiseData),
                request("/MasterScreens/GetLever?LeverID=0&GroupID=" + this.props.groupId + "&SearchedString=" + SearchedString + "&LanguageID=" + this.props.languageId, 'GET')])
               
                .then((response) => {
                    if (response[0]) {
                        if (response[1]) {
                            var emptyLevers = [];
                            response[1].map((lever, leverindex) => {
                                var levername = lever.LeverName;
                                var empty = response[0].Table.filter(item => item[levername] !== undefined && item[levername] != null && item[levername] != "" && item.DealName.trim() != i18n.t('benchmark_CompareScreen'))
                                if (empty.length == 0) {
                                    emptyLevers.push(levername);
                                }
                            })
                            var nonEmptyLevers = response[1].filter(item => !(emptyLevers.includes(item.LeverName)));
                            if(response[0].Table[0].OutputCode == 1){
                                this.setState({ comparisionGridData: null, nonEmptyLeverNames: nonEmptyLevers });
                            }
                            else{
                            this.setState({ comparisionGridData: response[0].Table, nonEmptyLeverNames: nonEmptyLevers });
                            }
                        }
                        else {
                            this.setState({ comparisionGridData: response[0].Table, nonEmptyLeverNames: [] });
                        }
                    }
                    this.reSizeGrid();
                    $("#loader-div").hide();

                }).catch((error) => {
                    $("#loader-div").hide();
                    console.log("Error Encountered")
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    ShowLandscapegraph(e) {

        try {            
            if (this.state.RegionValue != [] && this.state.RegionValue[0].label == i18n.t('all')) {

                if (this.state.DealValue == [] || this.state.DealValue.length == [0]) {
                    alert(i18n.t("pleaseselectDeal"));
                    return false;
                }

                if (this.state.CapabilityValue == [] || this.state.CapabilityValue.length == [0]) {
                    alert(i18n.t("pleaseSelectCapability"));
                    return false;
                }

                if (this.state.TowerValue == [] || this.state.TowerValue.length == [0]) {
                    alert(i18n.t("pleaseSelectTower"));
                    return false;
                }

                if (this.state.ServiceCompValue == [] || this.state.ServiceCompValue.length == [0]) {
                    alert(i18n.t("pleaseSelectServiceComponent"));
                    return false;
                }
            }
            // if(!this.state.DealValue[0].label.toLowerCase().includes("test")){
            // window.tfo_dataevent("PMM-VSA-21", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"],1,this.state.DealValue[0].label)
            // }
            var SearchedString = "";
            let viewDealWiseData = {
                DealID:this.state.DealValue[0].value,
                RegionID:0,
                DeliveryLocationId:0,
                SCID:this.state.ServiceCompValue[0].value,
                SearchedString:SearchedString,
                UserName:this.props.enterpriseId,				
			    GroupID: this.props.groupId,
			    LanguageID:this.props.languageId
            }
            request("/PMM/ViewDealWiseComparison", 'POST', viewDealWiseData)
                .then(response => {
                    let GraphArrayvalues = []
                    if (response) {
                        if(response.Table[0].OutputCode==1){
                            GraphArrayvalues=[];
                        }
                        else{
                        GraphArrayvalues = response.Table.filter((i) => {
                            if (i.DealName.trim() != i18n.t('benchmark_CompareScreen') && i.Standardization != null) {
                                return i;
                            }
                        });
                    }
                    }
                    this.setState({
                        AllOverallScoreArray: GraphArrayvalues.map((i) => {
                            return i.OverAll;
                        }),
                        AllStandardizationScoreArray: GraphArrayvalues.map((i) => {
                            return i.Standardization;
                        }),
                        AllDealnameArray: GraphArrayvalues.map((i) => {
                            return i.DealName;
                        }),
                        AllRegionArray: GraphArrayvalues.map((i) => {
                            return i.RegionName;
                        }),
                        popUpLandscapeGraph: true,
                    })
                    $("#loader-div").hide();

                })
                .catch(error => {
                    console.log(error);
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    closepopUpLandscapeGraph() {
        try {
            this.setState({
                popUpLandscapeGraph: false
            });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    async DownloadtoExcelProjectPrioritization() {
        try {
            var DealID = this.state.DealValue[0].value == undefined ? 0 : this.state.DealValue[0].value;
            var RegionID = this.state.RegionValue[0].value == undefined ? 0 : this.state.RegionValue[0].value;
            var TowerID = this.state.TowerValue[0].value == undefined ? 0 : this.state.TowerValue[0].value;
            var SCID = this.state.ServiceCompValue[0].value == undefined ? 0 : this.state.ServiceCompValue[0].value;
            var DeliveryLocationID = this.state.DlvryLocValue[0].value == undefined ? 0 : this.state.DlvryLocValue[0].value;
            var SearchedString = "";
            let projData = {
                "DealId": DealID,
                "RegionID": RegionID,
                "TowerID": TowerID,
                "SCId": SCID,
                "DeliveryLocationId": DeliveryLocationID,
                "SearchedString": SearchedString,
                "UserName": this.props.enterpriseId,
			    "GroupID": this.props.groupId,
			    "LanguageID":this.props.languageId
            }
            await request("/Common/DownloadtoExcelProjectPrioritization", 'POST', projData)
                .then(response => {
                    var filePath = response.Table1[0].Value;
                    window.location.href = filePath;
                })
                .catch(error => {
                    console.log(error);
                });
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }

    ShowBOILITrendReport() {
        try {
            // if(this.state.DealValue.length !== 0){
            //     if(!this.state.DealValue[0].label.toLowerCase().includes("test")){
            //     window.tfo_dataevent("PMM-VSA-23", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"],1,this.state.DealValue[0].label)
            //     }
            // }
            // else{
            // window.tfo_event("PMM-VSA-23", window.webConfig.REACT_APP_USAGE_ANALYTICS_APP_ID, window, sessionStorage["adal.idtoken"])
            // }
            this.props.history.push("/BOILITrendReport");
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    filterCallbackTypeahead(option, props) {
        try {
            if (props.selected.length) {
                return true;
            }
            return option[props.labelKey].toLowerCase().indexOf(props.text.toLowerCase()) !== -1;
        }
        catch (error) {
            console.log("Error Encountered");
        }
    }
    render() {
        var columnDefs = [];

        var DealNameColDef = {
            headerName: i18n.t('dealName'), field: 'DealName', tooltipField: 'DealName', suppressMovable: true, suppressResize: true, autoHeight: true, cellStyle: function (params) {
                if (params.node.data.DealName.trim() == i18n.t('benchmark_CompareScreen')) {
                    let backgroundVal = "rgba(255, 183, 76, 1)"
                    return { background: backgroundVal, 'font-size': '16px', 'font-weight': 'bold' }
                }
            }
        }
        var OverAllColDef = { headerName: i18n.t('overall'), field: 'OverAll', tooltipField: 'OverAll', suppressMovable: true, suppressResize: true, autoHeight: true, cellRenderer: createCircelText }
        var PolicyColDef = { headerName: i18n.t('policy'), field: 'Policy', tooltipField: 'Policy', suppressMovable: true, suppressResize: true, autoHeight: true, cellRenderer: createCircelText }
        var PracticeColDef = { headerName: i18n.t('practice'), field: 'Practice', tooltipField: 'Practice', suppressMovable: true, suppressResize: true, autoHeight: true, cellRenderer: createCircelText }
        var PlatformColDef = { headerName: i18n.t('platform'), field: 'Platform', tooltipField: 'Platform', suppressMovable: true, suppressResize: true, autoHeight: true, cellRenderer: createCircelText }
        var StandardizationColDef = { headerName: i18n.t('standardization'), field: 'Standardization', tooltipField: 'Standardization', suppressMovable: true, suppressResize: true, autoHeight: true, cellRenderer: createCircelText }

       let dynamicLever = [];
       this.state.lstLever.forEach((obj,index)=>{
            return(
                dynamicLever[index] =  { headerName: obj, field: obj, tooltipField: obj, suppressMovable: true, suppressResize: true, autoHeight: true, cellRenderer: createCircelText }
            );
        })
       
        if (this.state.comparisionGridData) {
            if (this.state.nonEmptyLeverNames.length > 0) {
                var LeverColDefs = [];
                this.state.nonEmptyLeverNames.forEach((item, index) => {
                    var LeverColDef = { headerName: item.LeverName, field: item.LeverName, tooltipField: item.LeverName, suppressMovable: true, suppressResize: true, autoHeight: true, cellRenderer: createCircelText }
                    return(LeverColDefs.push(LeverColDef));
                })
                columnDefs.push(DealNameColDef, OverAllColDef)
                LeverColDefs.forEach((item, index) => { return(columnDefs.push(item)); })
                columnDefs.push(StandardizationColDef)
            }
            else {
                columnDefs = [
                    DealNameColDef,
                    OverAllColDef
                ];
                dynamicLever.forEach(obj => {
                    return(columnDefs.push(obj));
                })
                columnDefs.push(StandardizationColDef);

            }
        } else {
            columnDefs = [
                DealNameColDef,
                OverAllColDef
            ];
            dynamicLever.forEach(obj => {
                return(columnDefs.push(obj));
            })
            columnDefs.push(StandardizationColDef);

        }

        var comparisionGridData = this.state.comparisionGridData;

        var ComparisonDataGrid = (
            <div style={{ height: '450px' }} className="ag-theme-material padding5">
                <AgGridReact ref="Grd_Comparision" id="grd_Comparision"
                    paginationPageSize="10"
                    enableColResize={false}
                    columnDefs={columnDefs}
                    rowData={comparisionGridData}
                    headerHeight="48"
                    gridAutoHeight={true}
                    onGridReady={this.gridReadyFun}
                    rowmodeltype="pagination"
                    localeText={AgGridLocale()}
                    overlayLoadingTemplate='<span class="ag-overlay-loading-center">No Data To Display</span>' />
            </div>
        )
        return (
            <section className="content">
                <div className="innerpage-hd">
                    <ul className="pagecounter"  >
                        <li><span title={i18n.t("vIEWPreviousassesssment")} className="current" data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Landing Page">...</span></li>
                        <li><span title={i18n.t("BOIKeyBusinessMetric")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="BOI">1</span></li>
                        <li><span title={i18n.t("lEADINGINDICATOR")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Leading Indicator ">2</span></li>
                        <li><span title={i18n.t("pMMASSESSMENT")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="PMM">3</span></li>
                        <li><span title={i18n.t("sTORYBOARDGAPASSESSMENT")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Storyboard">4</span></li>
                        <li><span title={i18n.t("pOTENTIALPROJECTS")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Potential Projects">5</span></li>
                        <li><span title={i18n.t("pROJECTPRIORITIZATION")} data-toggle="popover" data-trigger="hover" data-placement="top" data-content="Project Prioritization">6</span></li>
                    </ul>

                    <Link className="menu2" to="/Home"><a type="button" className="btn btn-info btn-back">
                        <img src={backArrowIcon} /> {i18n.t('bACKTOHOME')}</a></Link>
                </div>
                <section className="bodydata">
                    <div className="bodydata-full">
                        <div className="box-plain">
                            <section className="multiple-tabs-section no-padding-lr">
                                <ul className="nav nav-tabs">
                                    <li className="nav-item">
                                        <Link className="" to="/ViewPreviousAssessmentHome">{i18n.t('submittedAssessments')}</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="" to="/ViewPreviousAssessmentDraft">{i18n.t('SavedDraftAssessments')}</Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="active" to="/PMMReports">{i18n.t('reports')}</Link>
                                    </li>
                                </ul>
                            </section>
                            <h4>{i18n.t('FILTERS')}</h4>
                            <div className="box">
                                <div className="box-in viewPrj-filter">
                                    <span>
                                        <div className="form-group col-md-2">
                                            <label id="lbl_dealName"><span style={{ color: "red" }}>*</span>{i18n.t('deal_Name')}</label>
                                            <Typeahead emptyLabel={i18n.t('noMatchesFound')} name="form-field-name" labelKey="label" onChange={this.SelectDeal.bind(this)} options={this.state.lstDeal} selected={this.state.DealValue} filterBy={this.filterCallbackTypeahead.bind(this)} placeholder={i18n.t("selectPlace")} />
                                        </div>
                                    </span>
                                    <span>
                                        <div className="form-group col-md-2">
                                            <label id="lbl_clientBusinessRegion"><span style={{ color: "red" }}>*</span>{i18n.t('ClientBusinessRegion')}</label>
                                            <Typeahead emptyLabel={i18n.t('noMatchesFound')} name="form-field-name" labelKey="label" onChange={this.SelectRegion.bind(this)} options={this.state.lstRegion} selected={this.state.RegionValue} filterBy={this.filterCallbackTypeahead.bind(this)} placeholder={i18n.t("selectPlace")} />
                                        </div>
                                    </span>
                                    <span>
                                        <div className="form-group col-md-2">
                                            <label id="lbl_capability"><span style={{ color: "red" }}>*</span>{i18n.t('capability')}</label>
                                            <Typeahead emptyLabel={i18n.t('noMatchesFound')} name="form-field-name" labelKey="label" onChange={this.SelectCapability.bind(this)} options={this.state.lstCapability} selected={this.state.CapabilityValue} filterBy={this.filterCallbackTypeahead.bind(this)} placeholder={i18n.t("selectPlace")} />
                                        </div>
                                    </span>
                                    <span>
                                        <div className="form-group col-md-2">
                                            <label id="lbl_tower"><span style={{ color: "red" }}>*</span>{i18n.t('tower')}</label>
                                            <Typeahead emptyLabel={i18n.t('noMatchesFound')} name="form-field-name" labelKey="label" onChange={this.SelectTower.bind(this)} options={this.state.lstTower} selected={this.state.TowerValue} filterBy={this.filterCallbackTypeahead.bind(this)} placeholder={i18n.t("selectPlace")} />
                                        </div>
                                    </span>
                                    <span>
                                        <div className="form-group col-md-2">
                                            <label id="lbl_serviceComponent"><span style={{ color: "red" }}>*</span>{i18n.t('serviceComponent')}</label>
                                            <Typeahead emptyLabel={i18n.t('noMatchesFound')} name="form-field-name" labelKey="label" onChange={this.SelectServiceComponent.bind(this)} options={this.state.lstServiceComp} selected={this.state.ServiceCompValue} filterBy={this.filterCallbackTypeahead.bind(this)} placeholder={i18n.t("selectPlace")} />
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <h4 className="btn-group-md">{i18n.t('viewDealWiseComparision')}
                                <a type="button" className="btn btn-info pull-right" onClick={this.ClearFilter.bind(this)}>{i18n.t('clear')}</a>
                                <input id="txt_BOI/LI_TrendReport" type="button" className="btn btn-danger pull-right" value={i18n.t("BOILITrendR")} title={i18n.t("PleaseselectatleastDealName")} onClick={this.ShowBOILITrendReport.bind(this)} />
                                <button id="btn_compareDealScores" type="button" className="btn btn-success pull-right" title={i18n.t("selectdealClientBusinessRegionasALL")} onClick={this.ShowComparisonPopup.bind(this)} disabled={this.state.disableCompare} >{i18n.t('compareDealScores')}</button>
                                <button id="btn_stdScoreGraph" type="button" className="btn btn-graph pull-right" onClick={this.ShowLandscapegraph.bind(this)} disabled={this.state.disableCompare} >{i18n.t('StdScoreGraph')}</button>
                            </h4>
                            <div className="AsmtData viewprevassmt">
                                {ComparisonDataGrid}
                            </div>
                            {this.state.popUpLandscapeGraph && <LandscapeGraph show={this.state.popUpLandscapeGraph} closeCallback={this.closepopUpLandscapeGraph} asToValX={this.state.AllOverallScoreArray} asToValY={this.state.AllStandardizationScoreArray} XLabel={this.state.AllDealnameArray} YLabel={this.state.AllRegionArray} DealName={this.state.DealValue[0].label} ServiceComponentName={this.state.ServiceCompValue[0].label} />}
                        </div>
                    </div>
                </section>
            </section>
        );
    }
}

function mapStateToProps(state){
    return{
		dropDownsValue:state.dropDownsValue,
        languageId:state.languageId,
        enterpriseId:state.enterpriseId,
        groupId:state.groupId,
        roleName:state.roleName,
        pageName:state.pageName,
        groupName:state.groupName,  
    }
}

PMMReports = connect(mapStateToProps)(withRouter(PMMReports))
export default PMMReports;